import { defineAsyncComponent } from 'vue'
export const AsyncComponents = {
  // 融合后
  BuyTogether: defineAsyncComponent(() => import(/* webpackChunkName: "checkoutv2-buy-together" */ './buy_together/Index.vue')),
  // 融合前
  BuyTogetherOld: defineAsyncComponent(() =>
    import(
      /* webpackChunkName: "checkoutv2-buy-together-old" */ '@/public/src/pages/checkout_new/pages/buy_together_old/Index.vue'
    )
  ),
  CheckoutDialog: defineAsyncComponent(() =>
    import(
      /* webpackChunkName: "checkoutv2-checkout-dialog" */ '@/public/src/pages/checkout_new/pages/common/dialog/Index.vue'
    )
  ),
  SwitchCardDrawer: defineAsyncComponent(() =>
    import(
      /* webpackChunkName: "checkoutv2-switch-card-drawer" */ '@/public/src/pages/checkout_new/pages/prime_saver/components/switch_card_drawer/Index.vue'
    )
  ),
  ShipMethodPopup: defineAsyncComponent(() =>
    import(
      /* webpackChunkName: "checkoutv2-ship-mether-popup" */ '@/public/src/pages/checkout_new/pages/shipping_method/popup/index.vue'
    )
  ),
  PaymentLoading: defineAsyncComponent(() =>
    import(
      /* webpackChunkName: "checkoutv2-payment-loading" */ '@/public/src/pages/checkout_new/pages/loading/payment_loading.vue'
    )
  ),
  // 支付挽留弹窗旧版, 后面需要干掉
  BackTips: defineAsyncComponent(() =>
    import(
      /* webpackChunkName: "checkoutv2-back-tips" */ '@/public/src/pages/common/pay_back_tips/index.vue'
    )
  )
  // coupon list 弹窗
  // MyCouponList: defineAsyncComponent(() =>
  //   import(
  //     /* webpackChunkName: "checkoutv2-my-coupon-list" */ '@/public/src/pages/checkout_new/components/MyCouponList.vue'
  //   )
  // )
}
