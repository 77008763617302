<template>
  <s-drawer
    class="new-user-drawer"
    v-model:visible="_visible"
    max-size="412px"
    append-to-body
    @close="$emit('closeDrawer')"
  >
    <template #title>
      <!-- 标题  -->
      <span class="new-user-drawer__title">
        {{ title }}
      </span>
      <!-- 副标题  -->
      <div 
        v-if="subtitle" 
        class="new-user-drawer__subtitle"
      >
        {{ subtitle }}
      </div> 
    </template>

    <!-- 内容 -->
    <slot name="content"></slot>

    <template #footer>
      <div></div>
    </template>
  </s-drawer>
</template>

<script setup>
import { ref, watch } from 'vue'
import { SDrawer } from '@shein-aidc/sui-drawer/mobile'

defineEmits(['closeDrawer'])

const props = defineProps({
  visible: {
    type: Boolean,
    default: false
  },
  title: {
    type: String,
    default: ''
  },
  subtitle: {
    type: String,
    default: ''
  }
})

const _visible = ref(false)

watch(
  () => props.visible,
  visible => _visible.value = visible,
  {
    immediate: true
  }
)
</script>

<style lang="less" scoped>
.new-user-drawer {
  :deep(.sui-drawer__container) {
    border-radius: 10px 10px 0 0;
  }
  :deep(.sui-drawer__header_normal) {
    background-image: url(https://img.ltwebstatic.com/images3_ccc/2024/04/25/9b/1714030657cd1c95b12dfdf31e313bbc56177c4a00.webp);
    background-size: 100%;
    background-repeat: no-repeat;
    padding-top: 40/75rem;
    border-bottom: 0;
    max-height: 208/75rem;
    min-height: unset;
    display: flex;
    flex-direction: column;
    align-items: center;

    .sui-drawer__close-btn {
      top: 8/75rem;
      right: 8/75rem;
      .font-dpr(36px);
      color: #666;
    }
  }

  :deep(.sui-drawer__body) {
    padding: 0.2rem 52/75rem 0 62/75rem;
  }

  :deep(.sui-drawer__normal-footer) {
    margin-top: 0;
    flex: 0 0 30px;
  }

  &__title {
    .font-dpr(48px);
    font-weight: 700;
    background-image: linear-gradient(90deg, #F91F7D 0.3%, #7E28F5 55.12%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    line-height: 1.2;
    margin-bottom: 68/75rem;
  }

  &__subtitle {
    margin: -12/75rem 0 24/75rem;
    color: #7A2DDC;
    text-align: center;
    font-family: Roboto;
    .font-dpr(24px);
    font-weight: 300;
    line-height: 1.15;
  }

}
</style>
