import { ref, reactive, nextTick } from 'vue'
import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'
import {
  useMapMutations,
} from '@/public/src/pages/checkout_new/hooks/store'


export const useC2pAlert = () => {
  const { changeCheckoutLoading } = useMapMutations(['changeCheckoutLoading'])
  const c2pAlertRef = ref()
  const visibleC2pAlertDrawer = ref(false)
  const consumerPresent = ref(false)
  const c2pAlertConfig = reactive({
    scene: '',
    email: '',
    phone: '',
    area_code: '',
  })

  const closeC2pAlertDrawer = () => {
    changeCheckoutLoading({ config: { loading: true }, setLoading: false })
    visibleC2pAlertDrawer.value = false
  }

  const handleStartAccessYourCards = ({ scene } = {}) => {
    nextTick(() => {
      changeCheckoutLoading({ config: { loading: true }, setLoading: true })
      const userInfo = UserInfoManager.getSync({ key: 'UserInfo', actionType: '/checkout' })
      c2pAlertRef.value?.onOpen?.(() => {
        changeCheckoutLoading({ config: { loading: true }, setLoading: false })
        visibleC2pAlertDrawer.value = true
      }, scene === 'access_your_cards' ? {
        scene: 'callIdLookup',
        params: {
          email: UserInfoManager.getSync({ key: 'pwa_user_email', actionType: '/checkout' }) || '',
          area_code: userInfo?.area_code || '',
          phone: userInfo?.phone || ''
        }
      } : {})
    })
  }

  return {
    consumerPresent,
    c2pAlertRef,
    visibleC2pAlertDrawer,
    c2pAlertConfig,
    handleStartAccessYourCards,
    closeC2pAlertDrawer,
  }
}
