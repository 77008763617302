<template>
  <div
    id="i-incentive-wrapper"
    :class="prefixCls('incentive-wrapper')"
  >
    <CarouselSwiper
      v-if="isIncentiveCarousel && isCarouselDataLoaded && carouselDataRef?.length > 1"
      :autoplayDelay="1500"
      :isDelayPlay="false"
      :config="carouselDataRef"
    />

    <ClientOnly>
      <div
        v-if="isOnlyOneIncentive || carouselDataRef?.length <= 1"
        :class="prefixCls('scroll-text-wrapper')"
      >
        <ScrollText
          :speed="60"
          :icon="benefitCarousel?.data?.[0]?.img?.url"
          :text="benefitCarousel?.data?.[0]?.text?.label || ''"
          :delay="3"
        />
      </div>
      <div
        v-for="(item, index) in benefitCarousel?.data"
        :key="index"
      >
        <span
          :class="`pure-incentive-item${index}`"
          style="display: none;"
          v-html="item?.text?.label || ''"
        ></span>
      </div>
    </ClientOnly>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, watch, computed, nextTick } from 'vue'
import { ClientOnly } from '@sheinfe/vue-client-only'
import CarouselSwiper from '@/public/src/pages/checkout_new/components/animation/CarouselSwiper.vue'
import { isEqual } from '@/public/src/pages/checkout_new/utils'
import ScrollText from './scrollText.vue'
import { exposeScenesAbtAggregatorBff as exposeScenesAbtAggregator } from 'public/src/pages/checkout_new/utils/EventAggregator.js'
import useIsClient from '@/public/src/pages/checkout_new/hooks/useIsClient.js'
import useFilterFooterBenefit from '@/public/src/pages/checkout_new/pages/footer/hooks/useFilterFooterBenefit.js'

import { useMapState } from '@/public/src/pages/checkout_new/hooks/store'
import { prefixCls } from '@/public/src/pages/checkout_new/pages/footer/utils'

const { isClient } = useIsClient()
const { getCornerMarkBenefitTypes } = useFilterFooterBenefit()

defineProps({
  tax: {
    type: Array,
    default: () => []
  }
})

// -------- useMap_**** --------
const { abtInfo, checkout, cartsInfo } = useMapState(['abtInfo', 'checkout', 'cartsInfo'])

// ---------- computed ----------
// 获取可展示的利诱点轮播数据集
const benefitCarousel = computed(() => {
  const checkoutLure = checkout.value?.payableAmountLure || []
  const cartsLure = cartsInfo.value?.payableAmountLure || []
  const list = [...checkoutLure, ...cartsLure]
    ?.map(item => {
      return {
        ...item,
        text: {
          label: item.textList.map(textItem => `<span style="color: ${textItem.color}; font-size: 12px;">${textItem.text}</span>`).join(`<span style="color: #FA6338;"> | </span>`),
          // color: item.textList[0].color
        },
        img: {
          url: item.icon,
          style: { height: '12px', width: '12px' }
        }
      }
    })
  const renderList = list?.filter(item => isEqual(item.isDisplay, '1') && getCornerMarkBenefitTypes.value?.indexOf(item.type) == -1) || []
  const carouselDataTypes = list?.map(i => i.type).filter(Boolean)?.join('、') || ''

  return {
    carouselDataTypes,
    data: renderList.sort((a, b) => +a.sort - +b.sort)
  }
})

const carouselDataRef = ref([benefitCarousel.value?.data?.[0]] || [])
const isCarouselDataLoaded = ref(false)

onMounted(() => {
  nextTick(()=>{
    calcuDataByStyles()
  })
  window.addEventListener('resize', calcuDataByStyles)
})

onUnmounted(() => {
  // 移除 resize 事件监听器
  window.removeEventListener('resize', calcuDataByStyles)
})

watch(() => benefitCarousel.value?.data, () => {
  if (isClient.value) {
    nextTick(()=>{
      calcuDataByStyles()
    })
  }
}, {
  deep: true,
  immediate: true
})

watch(
  () => benefitCarousel.value?.carouselDataTypes,
  val => {
    if (val) {
      exposeScenesAbtAggregator.addEvent({
        id: 'expose_scenesabt:simple',
        data: {
          scenes: 'PlaceOrderLeft',
          benefits_type: val
        }
      })
    }
  },
  {
    immediate: true
  }
)

const calcuDataByStyles = () => {
  if (benefitCarousel.value?.data?.length > 1) {
    isCarouselDataLoaded.value = false
    const footerElement = getComputedStyle && getComputedStyle(document.querySelector('.checkout-footer__container'))
    const totalCleanFooterWidth = parseFloat(footerElement?.width) - parseFloat(footerElement?.paddingLeft) - parseFloat(footerElement?.paddingRight) - 12

    let carouselDataCp = JSON.parse(JSON.stringify(benefitCarousel.value?.data))

    for (let index = 0; index < carouselDataCp.length; index++) {
      const parent = document.querySelector(`.pure-incentive-item${index}`)
      // 保存原始样式，用于后续恢复
      const originalDisplay = parent?.style?.display
      const originalVisibility = parent?.style?.visibility
      const originalPosition = parent?.style?.position

      // 临时修改样式：显示元素但不可见，且不影响布局
      parent.style.display = 'block'
      parent.style.visibility = 'hidden'
      parent.style.position = 'absolute'

      // 检测换行（通过文本行的矩形数量）
      const rects = parent.getClientRects()

      // 恢复原始样式
      parent.style.display = originalDisplay
      parent.style.visibility = originalVisibility
      parent.style.position = originalPosition

      if (rects[0].width >= (totalCleanFooterWidth / 2 - 18)) {
        carouselDataCp = carouselDataCp.splice(0, index)
        break
      }
    }
    carouselDataRef.value = carouselDataCp
    isCarouselDataLoaded.value = true
  }
}

const isOnlyOneIncentive = computed(() => {
  return (
    isEqual(
      abtInfo.value?.PlaceOrderLeftPreStrategy?.param?.PlaceOrderLeftPreStrategy,
      'typeA'
    ) 
  )
})

const isIncentiveCarousel = computed(() => {
  return (
    isEqual(
      abtInfo.value?.PlaceOrderLeftPreStrategy?.param?.PlaceOrderLeftPreStrategy,
      'typeB'
    ) 
  )
})

</script>

<style lang="less">
@import '../../../variables.less';

.@{prefixCls}incentive-wrapper {
  .checkout-component__label-swiper_item {
    justify-content: flex-start;;
  }
  .@{prefixCls}scrolling {
    &_text{
      margin-top: 4/37.5rem;
    }
  }
}
.@{prefixCls}pure-incentive-text {
  display: none;
}
.@{prefixCls}scroll-text-wrapper{
  margin-top: 4/37.5rem;
}
</style>
