/* eslint-disable vue/one-component-per-file */
import { onMounted, ref, defineComponent, h, getCurrentInstance, computed, onUnmounted, watch, createApp, Transition } from 'vue'
import BBenefitFloor, { BenefitComponentType } from '@shein-aidc/bs-benefit-floor-mobile'
import { daEventCenter } from '@shein-aidc/basis-sa-event-center'
import { useMapState, useMapGetters } from '@/public/src/pages/checkout_new/hooks/store'
import Footer from '../../footer/Index.vue'
import { expose } from 'public/src/pages/common/analysis/directive'
import { useStore } from 'vuex'

/**
* 
* @param { viewId } 视窗 id 
* @param { bottomId } 底部组件 id
* @returns 
*/

const BenefitFloorHoc = () => defineComponent({
  directives: {
    expose,
  },
  props: {
    viewId: {
      type: String,
      default: ''
    },
    bottomId: {
      type: String,
      default: ''
    },
    benefitFloorVisible: {
      type: Boolean,
      default: false
    },
  },

  setup(props) {
    let observer = null
    const { checkout, footerState } = useMapState(['checkout', 'footerState'])
    const { rewardFloorConfig } = useMapGetters(['rewardFloorConfig'])

    const instance = getCurrentInstance()
    const isTop = ref(false)
    const isVisualArea = ref(false)
    const isMounted = ref(false)
    const isFirstReport = ref(true)
    
    const store = useStore()

    const data = computed(() => checkout.value?.rewardFloor || {})

    onMounted(() => {
      isMounted.value = true
      handleBenefitReport()
    })

    onUnmounted(() => {
      document.removeEventListener('scroll', handleIsTop, true)
      if (observer) {
        observer.disconnect()
      }
    })

    const handleBenefitReport = () => {
      const { allRewardList, allRewardValue } = data.value.buriedPoint || {}
      if (!allRewardList?.length) return
      daEventCenter.triggerNotice(({
        id: 'expose_scenesabt:simple',
        data: {
          scenes: 'orderRewards',
          orderRewardsType: allRewardList?.join('_'),
          orderRewardsDetailValue: allRewardValue,
          orderRewardsOriginPath: 'cart'
        }
      }))
    }

    const handleDrawerOpened = () => {
      const { satisfiedRewardList, satisfiedRewardValue } = data.value.buriedPoint || {}
      daEventCenter.triggerNotice(({
        id: 'click_order_rewards:simple',
        data: {
          orderRewardsType: satisfiedRewardList?.join('_'),
          orderRewardsDetailValue: satisfiedRewardValue
        }
      }))
    }

    const addEventListener = () => {
      document.addEventListener('scroll', handleIsTop, true)
    }

    const handleIsTop = () => {
      isTop.value = document.getElementById(props.viewId).getBoundingClientRect().top === 0
    }

    const createStyle = (dom) => {
      const styleElement = document.createElement('style')
      styleElement.innerHTML = `
        .checkout-benefit-fade-enter-active {
          animation: bounce-in .3s;
        }
        .checkout-benefit-fade-leave-active {
          animation: bounce-in .3s;
        }
        @keyframes bounce-in {
          0% {
            opacity: 0;
          }
          50% {
            opacity: 0.5; 
          }
          100% {
            opacity: 1; 
          }
        }
      `
      dom?.appendChild(styleElement)
    }

    const createElement = () => {
      const divDom = document.createElement('div')
      const bottomDom = document.getElementById(props.bottomId)
      const app = createApp({
        render: () =>
          h(
            Transition, 
            { name: 'checkout-benefit-fade' }, 
            [
              h(
                BBenefitFloor, 
                {
                  type: componentType.value,
                  data: data.value,
                  style: {
                    display: isVisualArea.value ? 'none' : 'flex',
                    width: '100%',
                    '--arrow-right': '3.05rem',
                    marginBottom: `${16 / 75}rem`
                  },
                  onAfterDrawerOpen: handleDrawerOpened,
                },
                { footer: () => h(Footer, { isSimple: true, store }) }
              )
            ]
          )
      })
      bottomDom?.appendChild(divDom)
      app.mount(divDom)
      createStyle(bottomDom)
    }

    const setVisualArea = (observerDom) => {
      observer = new IntersectionObserver((entries) => {
        isVisualArea.value = isOnlyShowBar.value ? false : (entries[0]?.isIntersecting || false)
      }, {
        threshold: 1
      })
      observer.observe(observerDom)
    }

    watch(
      () => data.value,
      (val) => {
        if (typeof window === 'undefined' || isFirstReport) return
        isFirstReport.value = false
        const { satisfiedRewardList, satisfiedRewardValue } = val?.buriedPoint || {}
        if (!satisfiedRewardList?.length) return
        daEventCenter.triggerNotice({
          id: 'expose_order_rewards:simple',
          data: {
            orderRewardsType: satisfiedRewardList?.join('_'),
            orderRewardsDetailValue: satisfiedRewardValue
          }
        })
      },
      {
        immediate: true,
        deep: true
      }
    )

    watch(
      () => [isMounted.value, footerState.value.isFooterMounted],
      ([isMounted, isFooterMounted]) => {
        if (isMounted && isFooterMounted && rewardFloorConfig.value.rewardBarVisible) {
          const el = instance?.proxy?.$el || null
          handleIsTop()
          addEventListener()
          setVisualArea(el)
          createElement()
        }
      },
      { immediate: true }
    )

    const getComponentType = () => {
      if (isTop.value) {
        return BenefitComponentType.Bar // 1
      } else if (isVisualArea.value) {
        return BenefitComponentType.Floor // 2
      } else {
        return BenefitComponentType.MiniBar // 0
      }
    }

    const componentType = computed(() => getComponentType())
    // 只显示吸底
    const isOnlyShowBar = computed(() => (rewardFloorConfig.value.rewardBarVisible && !rewardFloorConfig.value.rewardFloorVisible))

    // 单一利益点，展示吸底样式
    const floorType = computed(() => {
      if (data.value.guide?.rewardList?.length === 1) return BenefitComponentType.Bar
      return BenefitComponentType.Floor
    })

    if (rewardFloorConfig.value.rewardFloorVisible) {
      const app = h(
        BBenefitFloor,
        {
          type: floorType.value,
          data: data.value,
          onAfterDrawerOpen: handleDrawerOpened,
        },
        { footer: () => h(Footer, { isSimple: true, forceShowFsTips: true, store }) }
      )
      return () => app
    } else if (isOnlyShowBar.value) {
      return () => h('div')
    }
    return null
  },
})
export default BenefitFloorHoc
