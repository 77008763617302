<template>
  <s-dialog
    :visible="visible"
    show-close
    append-to-body
    lock-scroll
    @close="closeDialog"
  >
    <p style="text-align: center">
      {{ language.SHEIN_KEY_PWA_26448 }}
    </p>
    <template #footer>
      <s-button
        :type="['primary']"
        width="100%"
        @click="closeDialog"
      >
        {{ language.SHEIN_KEY_PWA_15312 }}
      </s-button>
    </template>
  </s-dialog>
</template>

<script setup>
import { SDialog } from '@shein-aidc/sui-dialog/mobile'
import { SButton } from '@shein-aidc/sui-button/mobile'
const emit = defineEmits(['closeDialog'])

defineProps({
  visible: {
    type: Boolean,
    default: false
  },
  dialogData: {
    type: String,
    default: ''
  },
  language: {
    type: Object,
    default: () => {}
  },
})

const closeDialog = () => {
  emit('closeDialog', 'textDialog')
}

</script>
<style lang="less" scoped>
.header-bg-container{
  position: absolute;
  top: .85rem;
  // left: 19%;
  font-size: 16px;
  text-align: center;
  width: 100%;
  font-weight: 600;
  .header-num{
    font-size: 24px;
    font-weight: 600;
  }
}
.pickColor_xtra{
  color: #F82854
}
.origin_xtra{
  color: #000;
}
.text-xtra_discount{
  line-height: 1.5;
  font-size: 24px;
}
.virXrt_container{
  margin-bottom: 0.7rem;
  margin-top: -0.8rem;
  font-size: 14px;
  .virXrt_line{
    display: flex;
    justify-content: space-between;
    margin-top: 10/70rem;
  }
  .virXrt-line_left{
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.virXrt-lineLeft_tipsXtra{
  margin-right: 5/70rem;
}
</style>
