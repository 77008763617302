<template>
  <div
    :id="[prefixCls('wrapper')]"
    ref="checkoutFooterWrapperRef"
    :class="[prefixCls('wrapper')]"
  >
    <ClientOnly>
      <div :class="[prefixCls('float')]">
        <template v-if="visibleFloatContainer">
          <!-- 悬浮区域[集合] -->
          <FloatingArea :style="styleAffectedByPriceDetailDrawer" />

          <!-- 违禁品提示，暂时只是商品中含有烟草类的商品 -->
          <ContrabandTip
            v-if="isShowContrabandTips"
            :style="styleAffectedByPriceDetailDrawer"
          />

          <!-- 权益楼层悬浮条 -->
          <div 
            v-else-if="rewardFloorConfig.rewardBarVisible"
            id="checkout-footer-dom"
            :class="[prefixCls('float_benefit-bar')]"
            :style="styleAffectedByPriceDetailDrawer"
          ></div>

          <!-- 选择支付方式 -->
          <PaymentSelector
            v-if="isShowBottomPaymentSelector.show"
            :style="styleAffectedByPriceDetailDrawer"
          >
            <BtnWrapper
              :class="[prefixCls('float_pay-select')]"
              style="width: 100%"
              :isSimple="true"
            />
          </PaymentSelector>

          <!-- 营销裂变 -->
          <ExtraPromotionTip
            v-if="isShowExtraPromotion"
            :style="styleAffectedByPriceDetailDrawer"
          />

          <!-- cod支付后端强制转换货币提示 -->
          <s-popover
            placemen="top-end"
            trigger="user"
            :model-value="!!checkout?.auto_change_currency_tips?.codChangeCurrencyTip"
            :append-to-body="false"
            :show-close-icon="true"
            theme="light"
            :prop-style="{
              width: 'calc(100% - 0.64rem)',
              left: '0',
              right: '0',
            }"
            :style="{left: '0.32rem'}"
          >
            <em class="tips">{{
              checkout?.auto_change_currency_tips?.codChangeCurrencyTip
            }}</em>
            <template #reference>
              <div></div>
            </template>
          </s-popover>
        </template>

        <!--  订单价格明细抽屉  -->
        <PriceDetail
          v-if="!isNotShowPriceDetail && isRenderedPriceDetail"
          :class="[prefixCls('price-detail_wrapper')]"
          :visible="footerState.isPriceDetailVisible"
          @changePriceDetailVisible="changePriceDetailVisible"
        />
      </div>
    </ClientOnly>

    <!-- 底部主按钮区域 -->
    <div :class="[prefixCls('container')]">
      <!-- 左侧内容区域 -->
      <TotalWrapper
        :visible="footerState.isPriceDetailVisible"
        :isNotShowPriceDetail="isNotShowPriceDetail"
        @changePriceDetailVisible="changePriceDetailVisible"
      />

      <!-- 右侧 button 区域 -->
      <BtnWrapper
        :isSimple="isSimple"
        :isOnlyRenderNormalBtn="isOnlyRenderNormalBtn"
        :forceShowFsTips="forceShowFsTips"
        :c2pInfo="c2pInfo"
        @handleClickNormalBtn="handleClickNormalBtn"
      />
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'CheckoutFooter'
})
</script>
<script setup name="CheckoutFooter">
import { ref, defineAsyncComponent, computed, onMounted, onUnmounted, nextTick, provide, inject } from 'vue'
import { ClientOnly } from '@sheinfe/vue-client-only'
import { SPopover } from '@shein-aidc/sui-popover/mobile'
import { throttle } from '@shein/common-function'

import BtnWrapper from './components/btn_wrapper/Index.vue'
import TotalWrapper from './components/total_wrapper/Index.vue'

import { prefixCls } from '@/public/src/pages/checkout_new/pages/footer/utils/index.js'
import {
  useMapState,
  useMapGetters,
  useMapMutations
} from '@/public/src/pages/checkout_new/hooks/store'

const FloatingArea = defineAsyncComponent(() =>
  import(/* webpackChunkName: "checkoutv2-floating-area" */ './components/floating_area/Index.vue')
)
const ContrabandTip = defineAsyncComponent(() =>
  import(/* webpackChunkName: "checkoutv2-contraband-tip" */ './components/alert_tip/ContrabandTip.vue')
)
const PaymentSelector = defineAsyncComponent(() =>
  import(/* webpackChunkName: "checkoutv2-payment-selector" */ './components/footer_payment/FooterSelectPayment.vue')
)
const ExtraPromotionTip = defineAsyncComponent(() =>
  import(/* webpackChunkName: "checkoutv2-extra-promotion-tip" */ './components/alert_tip/ExtraPromotionTip.vue')
)
const PriceDetail = defineAsyncComponent(() =>
  import(/* webpackChunkName: "checkoutv2-price-detail" */ './components/price_detail/Index.vue')
)

// 首次渲染priceDetail，将dom存储到文档流中，后期通过v-show控制【因为priceDetail为高频弹窗】=== performance提升
const isRenderedPriceDetail = ref(false)
const checkoutFooterWrapperRef = ref(null)
let cacheHeight = 0

const props = defineProps({
  // 强制展示角标利诱点
  forceShowFsTips: {
    type: Boolean,
    default: false
  },
  // 仅仅展示 Btn 按钮，不展示浮窗那些
  isSimple: {
    type: Boolean,
    default: false
  },
  // 仅仅展示NormalBtn，不展示其他
  isOnlyRenderNormalBtn: {
    type: Boolean,
    default: false
  },
  // 不展示价格明细drawer
  isNotShowPriceDetail: {
    type: Boolean,
    default: false
  },
  store: {
    type: Object,
    default: (() => {}),
  },
  c2pInfo: {
    type: Object,
    default: () => ({})
  }
})

const emit = defineEmits(['handleClickNormalBtn'])
// 获取store上下文，当footer被私包夹带时，store上下文丢失，可以以props形式传进来
const store = inject('store', props.store)
// 手动显示的向所有子组件提供store上下文
provide('store', store)

// -------- useMap_**** --------
const {
  //
  isSelectPaymentMethod,
  checkout,
  footerState
} = useMapState([
  //
  'isSelectPaymentMethod',
  'checkout',
  'footerState'
], store)

const { isShowBottomPaymentSelector, rewardFloorConfig } = useMapGetters([
  'isShowBottomPaymentSelector',
  'rewardFloorConfig'
], store)

const { updatePriceDetailVisible, updateFooterState } = useMapMutations([
  'updatePriceDetailVisible',
  'updateFooterState'
], store)
// ---------- computed ----------
const isShowContrabandTips = computed(() => {
  return !!checkout.value?.tobacco_tip
})

const styleAffectedByPriceDetailDrawer = computed(() => {
  return { display: footerState.value.isPriceDetailVisible ? 'none' : 'block' }
})

const isShowExtraPromotion = computed(() => {
  const extraPromotionTip =
    checkout.value.extraPromotion?.marketFission?.rule?.reason_tip
  return (
    !!extraPromotionTip &&
    !isShowContrabandTips.value &&
    !isSelectPaymentMethod.value &&
    !rewardFloorConfig.value.rewardBarVisible
  )
})

const visibleFloatContainer = computed(() => {
  return !props.isSimple && !props.isOnlyRenderNormalBtn
})

// ---------- method ----------
const changePriceDetailVisible = visible => {
  setReturnTopOpacity(visible)
  if (!isRenderedPriceDetail.value && visible) {
    isRenderedPriceDetail.value = true
  }
  nextTick(() => {
    setTimeout(() => {
      updatePriceDetailVisible(visible)
    }, 100)
  })
}

// 控制returnTop箭头的状态，保障价格详情弹窗内不展示returnTop
const setReturnTopOpacity = visible => {
  const opacity = visible ? 0 : 1
  const returnTopCls = '.mshe-z-returnTop'
  const dom = document.querySelector(returnTopCls)
  dom.style.opacity = opacity
}

const handleClickNormalBtn = () => {
  isRenderedPriceDetail.value = false
  emit('handleClickNormalBtn')
}

// 结束触发，不再多执行一次func
const throttled = throttle({
  func: function () {
    window.requestAnimationFrame(function () {
      setCheckoutContainerPaddingBottom()
    })
  },
  wait: 3000,
  options: {
    trailing: false
  }
})

// 抬起最外层容器，避免被footer遮挡
const setCheckoutContainerPaddingBottom = () => {
  const height = checkoutFooterWrapperRef.value?.clientHeight
  if (height > 0 && cacheHeight !== height) {
    const checkoutContainer = document?.getElementById('checkout-app')
    checkoutContainer.style.paddingBottom = `${height}px`
    cacheHeight = height
  }
}

onMounted(() => {
  document.addEventListener('scroll', throttled)
  updateFooterState({ isFooterMounted: true })
})

onUnmounted(() => {
  document.removeEventListener('scroll', throttled)
})
</script>

<style lang="less">
@import './variables.less';

.@{prefixCls}wrapper {
  position: fixed;
  bottom: 0;
  z-index: @zindex-attrmenusize;
  width: 10rem;
  border-top: 1px solid #e5e5e5;
  box-shadow: 0 -0.1rem 0.1rem rgba(0, 0, 0, 0.06);
  background: #fff;

  .@{prefixCls}container {
    display: flex;
    justify-content: space-between;
    padding: 16/75rem 24/75rem;
    background: #fff;
    border-top: 1px solid #e5e5e5;
  }
}

.@{prefixCls}float {
  position: relative;
  // 为了消除高度
  // font-size: 0;
  display: flex;
  flex-direction: column;

  &_benefit-bar {
    background-color: #fff;
  }

  &_pay-select {
    .checkout-footer__group-btn__wrapper {
      width: 100% !important;
    }
  }
}
.opened-checkout-price-detail {
  overflow: hidden;
}
</style>
