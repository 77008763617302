<template>
  <s-alert
    v-if="+bannerInfo.is_display"
    v-expose="{
      id: 'expose_freeshipping:simple',
      data: { freeshipping_external: 1 }
    }"
    class="free-shipping-notice" 
    type="success"
    :show-icon="false"
    :style="{
      backgroundColor: bannerInfo.bg_color,
    }"
  >
    <img
      v-if="bannerInfo.icon"
      class="free-shipping-notice__icon"
      :src="bannerInfo.icon"
    />
    <span 
      class="free-shipping-notice__content"
      v-html="bannerInfo.content"
    ></span>
  </s-alert>
</template>
<script setup>
import { vExpose } from 'public/src/pages/common/analysis/directive'
import { computed } from 'vue'
import { SAlert } from '@shein-aidc/sui-alert/mobile'
import { useMapState } from '@/public/src/pages/checkout_new/hooks/store'

const { checkout } = useMapState(['checkout'])

const bannerInfo = computed(() => checkout.value?.free_shipping_policy_banner_info || {})

</script>
<style lang="less">
.free-shipping-notice {
  padding: 16/75rem 24/75rem;

  &__icon {
    width: 32/75rem;
    height: 32/75rem;
    margin-right: 8/75rem;
  }

  &__content {
    font-family: "SF Pro";
    color: #0E8C33;
    font-weight: 590;
  }
}
</style>
