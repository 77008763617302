<template>
  <NewUserDrawer
    v-bind="props"
    :title="language.SHEIN_KEY_PWA_29981"
    class="new-user-rule"
    @closeDrawer="$emit('update:visible', false)"
  >
    <template #content>
      <div 
        class="new-user-rule__content"
        v-html="rule"
      >
      </div>
    </template>
  </NewUserDrawer>
</template>

<script setup>

import NewUserDrawer from './NewUserDrawer'

const props = defineProps({
  visible: {
    type: Boolean,
    default: false
  },
  rule: {
    type: String,
    default: ''
  },
  language: {
    type: Object,
    default: () => {}
  }
})


</script>

<style lang="less" scoped>
.new-user-rule {
  &__content {
    color: #6D6D6D;
    font-family: "SF Pro";
    .font-dpr(24px);
  }
}
</style>
