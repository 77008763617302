<template>
  <div>
    <!-- qs freight弹窗 -->
    <s-dialog
      :visible="isShowDelivery"
      :append-to-body="true"
      :show-close="false"
      :close-on-click-modal="false"
    >
      <template #title>
        {{ checkout.quick_shipping?.quick_shipping_tip.quick_shipping_title }}
      </template>
      <div style="margin-top: 10px">
        <span>{{ renderData?.[0] }}</span>
        <LabelTag
          v-if="!!productTags.text"
          :tag="productTags"
          use-icon
        />
        <span>{{ renderData?.[1] }}</span>
      </div>
      <template #footer>
        <s-button
          :type="['primary', 'H72PX']"
          :width="'100%'"
          @click="closeDeliveryTips"
        >
          {{ language.SHEIN_KEY_PWA_15312 }}
        </s-button>
      </template>
    </s-dialog>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { SDialog } from '@shein-aidc/sui-dialog/mobile'
import { SButton } from '@shein-aidc/sui-button/mobile'
import LabelTag from '@/public/src/pages/checkout_new/components/QuickShipTag.vue'
import { useMapState } from '@/public/src/pages/checkout_new/hooks/store'

defineProps({
  isShowDelivery: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits(['emitCloseDeliveryTips'])

// --------- useMap_**** --------
const { language, checkout } = useMapState(['language', 'checkout'])

// ---------- computed ----------
const productTags = computed(() => {
  const { quick_shipping } = checkout.value

  return (
    {
      textColor: '',
      bgColor: '',
      prefixIcon: '',
      text:
        quick_shipping?.quick_shipping_tip
          ?.quick_shipping_content_fill_label_desc || ''
    } || {}
  )
})
const renderData = computed(() => {
  const { quick_shipping } = checkout.value
  return quick_shipping?.quick_shipping_tip?.quick_shipping_new_tip?.split(
    '{0}'
  )
})
// ----------- method ------------
const closeDeliveryTips = () => {
  emit('emitCloseDeliveryTips', false)
}
</script>
