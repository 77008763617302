<template>
  <div ref="virAssetsRef">
    <AssetsList
      class="assets-list__wrap"
    />
    <ClientOnly>
      <CouponErrDialogByGift
        @couponScrollIntoView="handleCouponScrollInView"
      />
      <FreezeTipsAndGoods />
      <Drawer />
    </ClientOnly>
  </div>
</template>

<script setup name="VirtualAssetsWrapper">
import { ClientOnly } from '@sheinfe/vue-client-only'

import { defineAsyncComponent, ref } from 'vue'
import AssetsList from './AssetsList.vue'
import Drawer from './Drawer.vue'
import { useVirtualAssets } from '@/public/src/pages/checkout_new/pages/virtual_assets/event-bus'

useVirtualAssets()

const CouponErrDialogByGift = defineAsyncComponent(() => import(/* webpackChunkName: "checkoutv2-coupon-err-dialog-by-gift" */ './CouponErrDialogByGift.vue'))
const FreezeTipsAndGoods = defineAsyncComponent(() => import(/* webpackChunkName: "checkoutv2-freeze-tips-and-goods" */ './freeze_goods_limit/Index.vue'))

const virAssetsRef = ref(null)

const handleCouponScrollInView = () => {
  virAssetsRef.value?.scrollIntoView()
}
</script>

<style lang="less">
.assets-list__wrap {
  .price-coupon-diff {
    width: 100%;
  }
  .price-diff-tip {
    background: #FFF7F0;
    padding: 0.21333rem;
    .tip-red {
      color: #FA6338;
    }
  }
	li {
		padding: .2rem;
		.padding-r(.32rem);
		.padding-l(0);
		.align-center();
		.border-dpr(border-bottom,2px,#e5e5e5);

    .input-icon {
      width: 100%;
      display: table;
      .input-icon-cell {
        display: table-cell;
      }
    }
    .input-coupon {
      .flexbox();
      .align-center();
      .space-between()
    }
	}
}
</style>
