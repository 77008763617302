<template>
  <div class="checkout-payment-options checkout-scroll__payment">
    <!-- <div
      class="mock-card"
      @click="openNewCard"
    >
      use new card
    </div> -->
    <div
      ref="paymentOptionsTitleRef"
      class="checkout-payment-options__title"
      tabindex="0"
    >
      <div class="title-text">
        {{ language.SHEIN_KEY_PWA_15145 }}
      </div>
      <span
        v-show="noSelectPayment"
        class="title-error j-payment-tips"
        tabindex="0"
      >
        {{ language.SHEIN_KEY_PWA_15092 }}
      </span>
    </div>
    <!-- <ClientOnly> -->
    <BPayPaymentOptions
      ref="paymentOptionsRef"
      class="checkout-payment-options"
      :payment-info="checkoutPaymentInfo"
      :checkout-info="paymentCheckoutInfo"
      :before-select="handleBeforeSelect"
      :coupon-list-state="couponListState"
      :user-info-getter="userInfoGetter"
      :payment-config="paymentConfig"
      :inject-fs-data="injectFsData"
      :c2pInfo="c2pInfo"
      @select="handleSelectPayment"
      @payment-info-change="handlePaymentInfoChange"
      @init-common-method="handleInitCommonMethod"
      @pay-now="handlePayNow"
      @bs-payment-action="handleBsPaymentAction"
    >
      <template #checkout-footer="checkoutFooterProps">
        <div class="pre-checkout-footer">
          <ClientOnly>
            <!-- footer -->
            <Footer
              isOnlyRenderNormalBtn
              isSimple
              :is-not-show-price-detail="checkoutFooterProps.isNotShowPriceDetail"
              @handleClickNormalBtn="handleClickNormalBtn(checkoutFooterProps)"
            />
          </ClientOnly>
        </div>
      </template>
    </BPayPaymentOptions>

    <AsidePaymentSelectDrawer
      ref="changePaymentRef"
      :title="title"
      :tips="tips"
      :payments="payments"
      :inject-fs-data="injectFsData"
      :actionsConfig="actionsConfig"
      @close="asideCloseCallback"
    />
  </div>
</template>

<script setup name="PaymentOptions">
import { ref, watch } from 'vue'
import { ClientOnly } from '@sheinfe/vue-client-only'
import { BPayPaymentOptions } from '@shein-aidc/bs-pay-toolkit-mobile'
import Footer from 'public/src/pages/checkout_new/pages/footer/Index.vue'
import { usePaymentOptions } from '@/public/src/pages/checkout_new/hooks/usePaymentOptions'
import { useMapMutations, useMapState } from '@/public/src/pages/checkout_new/hooks/store'
import AsidePaymentSelectDrawer from '@/public/src/pages/checkout_new/components/aside-payment-select-drawer/index.vue'
import useChangePaymentForShip from './hooks/useChangePaymentForShip'
const { 
  assignPaymentState
} = useMapMutations([
  'assignPaymentState',
])

const { paymentState } = useMapState(['paymentState', 'checkoutParams'])

const emit = defineEmits(['selected-payment', 'change-price-detail-visible'])

defineProps({
  c2pInfo: {
    type: Object,
    default: () => ({})
  }
})

const paymentOptionsTitleRef = ref()

const {
  language,
  checkoutPaymentInfo,
  paymentCheckoutInfo,
  injectFsData,
  couponListState,
  userInfoGetter,
  paymentConfig,
  noSelectPayment,
  handleSelectPayment,
  handlePaymentInfoChange,
  handleInitCommonMethod,
  handlePayNow,
  handleBsPaymentAction,
  handleCheckoutFooterBtnClick,
  handleBeforeSelect,
  setSelectedPayment,
  setSelectedPaymentForBinCoupon,
  resetSelectedPayment,
  scrollToPaymentByCode,
  getPaymentOptionsLocalState,
  paymentOptionsRef
} = usePaymentOptions({ emit })

const {
  changePaymentRef,
  title,
  tips,
  payments,
  actionsConfig,
  updatePaymentOptionsRef,
  asideCloseCallback
} = useChangePaymentForShip()

// 缓存实例
watch(() => paymentOptionsRef.value, (newVal)=>{
  updatePaymentOptionsRef(newVal)
}, {
  immediate: true
})

// 聚焦
const focusNotSelectPayment = () => {
  const titleTop = paymentOptionsTitleRef.value?.offsetTop || 0
  const checkoutHeaderHeight = document.querySelector('.checkout-header__header-wrapper')?.getBoundingClientRect()?.height || 0

  const top = titleTop - checkoutHeaderHeight
  top && window.scrollTo({
    top: titleTop - checkoutHeaderHeight,
    behavior: 'smooth'
  })
}

assignPaymentState({ payInstanceEvent: Object.assign({}, paymentState.value.payInstanceEvent, {
  focusNotSelectPayment,
  getLocalStatus: getPaymentOptionsLocalState
}) })

// const openNewCard = () => {
//   paymentOptionsRef.value.handleStartFrontBindingCard({ paymentCode: 'routepay-card' })
// }

// const handlePrePayManualClose = () => {
//   visiblePrePay.value = false
// }

defineExpose({
  setSelectedPayment,
  setSelectedPaymentForBinCoupon,
  scrollToPaymentByCode,
  resetSelectedPayment
})

const handleClickNormalBtn = props => {
  handleCheckoutFooterBtnClick(props)
}

</script>

<style lang="less">
.checkout-payment-options {
  background-color: #fff;
  &__title {
    padding: 0.32rem var(--bs-payments-container-main-padding, 0.32rem) 0;
    text-transform: capitalize;
    .title-text {
      color: @sui_color_main;
      font-weight: 700;
    }
    .title-error {
      display: inline-block;
      color: @sui_color_unusual;
      text-transform: none;
    }
  }
}
.pre-checkout-footer {
  position: relative;
  padding: 0;
  display: flex;
  text-align: initial;
  .checkout-footer__wrapper {
    position: inherit;
  }
}
</style>
