import { dprInsert } from 'public/src/pages/common/customDetailDialog/utils/tools.js'
import { transformImg } from '@shein/common-function'

/**
 *
 * @param [String] amountWithSymbol 带币种金额
 * @return string
 */
export const amountWithSymbolTransform = (amountWithSymbol = '', currencyDecPointSymbol = '') => {
  if ( !amountWithSymbol ) return ''
  if(!currencyDecPointSymbol && typeof window != 'undefined') {
    let { currency, currencies, lang, appLanguage } = gbCommonInfo
    let currencyObj = currencies?.[lang]?.[currency] || {}
    let { separator_multi_resp_list = [] } = currencyObj || {}
    let existSeparator = separator_multi_resp_list?.filter(item => item?.language_flag == appLanguage)
    if (existSeparator?.length) {
      currencyDecPointSymbol = existSeparator?.[0]?.dec_point_symbol || '.'
    } else {
      currencyDecPointSymbol = currencyObj?.dec_point_symbol || '.'
    }
  }
  let hasDecPointSymbol = amountWithSymbol.includes(currencyDecPointSymbol)
  let reg = hasDecPointSymbol ? new RegExp(`^(\\D*)(.*?)([\\${currencyDecPointSymbol}])(\\d*?)(\\D*)$`, 'g') : new RegExp(`^(\\D*)(.*?)(\\D*)$`, 'g')
  let result = amountWithSymbol.replace(reg, function () {
    let currencyStr = RegExp.$1 || (hasDecPointSymbol ? RegExp.$5 : RegExp.$3) ? `<span class="price-currency">${ RegExp.$1 || (hasDecPointSymbol ? RegExp.$5 : RegExp.$3) }</span>` : ''
    let str = `<span class="price-amount-decimal">`
    str = str + (RegExp.$2 ? `<span class="price-amount">${ RegExp.$2 }</span>` : '')
    str = str + (hasDecPointSymbol && RegExp.$3 ? `<span>${ RegExp.$3 }</span>` : '')
    str = str + (RegExp.$4 ? `<span class="price-decimal">${ RegExp.$4 }</span>` : '')
    str = str + `</span>`
    return RegExp.$1 ? currencyStr + str : str + currencyStr
  })
  return result
}

export function getMainImageUrl({ item, IS_RW, customServiceImgDomains, thirdPartyServiceImgDomains }) {
  if (item?.isBlindBoxFlag) {
    return transformImg({ img: 'http://img.ltwebstatic.com/images3_acp/2024/11/22/24/1732274754479c270973cb429359284ea52acad318.png' })
  } else {
    const isCustomGood = Boolean(Object.keys(item.product?.customization_info || {}).length)
    if(isCustomGood && !IS_RW) {
      const effectUrl = dprInsert({ img: item.product?.customization_info?.preview?.effects?.[0] || item.product?.goods_img || '', customServiceImgDomains, thirdPartyServiceImgDomains }) // effects 为空则不做替换
      return transformImg({ img: effectUrl })
    } else {
      return transformImg({ img: item.product?.goods_img })
    }
  }
}


/* 判断当前活动是否是新人返券活动
* @param {*} promotionInfo 活动信息
*/
export const isNewUserReturnCoupon = (promotionInfo) => {
  if (typeof window === 'undefined') return false
  const { NEW_USER_COUPON_TYPE_ID = 0 } = gbCommonInfo || {}
  return promotionInfo?.typeId == 21 && promotionInfo?.coupons?.length && promotionInfo?.coupons?.every(item => item?.type_id == NEW_USER_COUPON_TYPE_ID)
}
