<template>
  <div
    v-if="newPolicyTip"
    class="checkout-agree-policy__wrapper"
    da-expose-code="agree-policy-tip-expose"
  >
    <p
      class="checkout-agree-policy__content"
      @click="handleNewPrivacyClick"
      v-html="newPolicyTip"
    ></p>
  </div>
</template>
<script setup name="AgreePolicy">
import { computed, nextTick, onMounted } from 'vue'
import { template } from '@shein/common-function'
import { daEventCenter } from '@shein-aidc/basis-sa-event-center'

const props = defineProps({
  // US/EUR市场下单页增加已同意TC和PP协议的文案
  // wiki.pageId=1043354548
  isShowNewTcPpTip: {
    type: Boolean,
    default: false
  },
  language: {
    type: Object,
    default() {
      return {}
    }
  }
})

const newPolicyTip = computed(() => {
  if (!props.isShowNewTcPpTip) {
    return ''
  }
  const location = 'page_checkout'
  const tip = props.language?.SHEIN_KEY_PWA_22994
  const terms = `<a tabindex="0" href="javascript:void(0);" class="j-terms-and-conditions" da-event-click="2-8-61" da-event-expose="2-8-62" data-location="${location}">${props.language.SHEIN_KEY_PWA_22998}</a>`
  const privacy = `<a href="javascript:void(0);" class="j-privacy-and-cookies" da-event-click="2-8-59" da-event-expose="2-8-60" data-location="${location}" tabindex="0">${props.language.SHEIN_KEY_PWA_22999}</a>`
  return template(terms, privacy, tip)
})

const handleNewPrivacyClick = async (e) => {
  let { handleNewPrivacyClick } = await import('public/src/pages/login/util')
  return handleNewPrivacyClick(e)
}


onMounted(() => {
  if(newPolicyTip.value) {
    nextTick(() => {
      const daEventExpose = daEventCenter.getExposeInstance()
      daEventExpose.subscribe({
        keycode: 'agree-policy-tip-expose`2-8-60|2-8-62',
      })
    })
  }
})
</script>

<style lang="less">
.checkout-agree-policy{
  &__wrapper {
    padding: 0 24/75rem 20/75rem;

    a {
      text-decoration: none;
      color: @sui_color_link;
    }
  }

  &__content {
    .font-dpr(24px);
    color: @sui_color_gray_dark2;
  }
}
</style>
