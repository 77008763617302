import { computed } from 'vue'
import { useMapState } from '@/public/src/pages/checkout_new/hooks/store'
import { isEqual } from '@/public/src/pages/checkout_new/utils'

/**
 * @description 免邮提示
 * */
export default function useFilterFooterBenefit() {
  // -------- useMap_**** --------
  const { checkout, cartsInfo, abtInfo } = useMapState(['checkout', 'cartsInfo', 'abtState', 'abtInfo'])

  // 获取角标type集
  const getCornerMarkBenefitTypes = computed(() => {
    const checkoutPoints = checkout.value?.placeOrderButtonRollTips || []
    const cartsInfoPoints = cartsInfo.value?.placeOrderButtonRollTips || []
    const filterUsedPoints = [...checkoutPoints, ...cartsInfoPoints]?.filter(item => isEqual(item?.is_display, '1'))

    return filterUsedPoints?.map(i => i?.type).filter(Boolean)?.join(',') || ''
  })

  // 获取角标和金额下方type集
  const getPayableAmountBenefitTypes = computed(() => {
    const checkoutCornerPoints = checkout.value?.placeOrderButtonRollTips || []
    const cartsInfoCornerPoints = cartsInfo.value?.placeOrderButtonRollTips || []
    const checkoutAmountPoints = checkout.value?.payableAmountLure || []
    const cartsAmountPoints = cartsInfo.value?.payableAmountLure || []

    // const isOnlyFreeShipping = abtState.value?.placeOrderBarLabelStatus
    const PlaceOrderLeftPreStrategy = abtInfo.value?.PlaceOrderLeftPreStrategy?.param?.PlaceOrderLeftPreStrategy
    let types = ''

    // 金额下方利诱点展示轮播
    if(PlaceOrderLeftPreStrategy == 'typeB') {
      const filterUsedPoints = [...checkoutCornerPoints, ...cartsInfoCornerPoints, ...checkoutAmountPoints, ...cartsAmountPoints]?.filter(item => isEqual(item?.isDisplay, '1') || isEqual(item?.is_display, '1'))
      types = filterUsedPoints?.map(i => i?.type).filter(Boolean)?.join(',') || ''
    } else if(PlaceOrderLeftPreStrategy == 'typeA') {
      const cornerPonts = [...checkoutCornerPoints, ...cartsInfoCornerPoints]?.filter(item => isEqual(item?.isDisplay, '1') || isEqual(item?.is_display, '1')) || []
      const amountBenefitType = [...checkoutAmountPoints, ...cartsAmountPoints]?.filter(item => isEqual(item?.isDisplay, '1') || isEqual(item?.is_display, '1'))?.sort((a, b) => +a?.sort - +b?.sort)?.[0]

      const allPoint = amountBenefitType ? [...cornerPonts, amountBenefitType] : [...cornerPonts]
      types = allPoint?.map(i => i?.type).filter(Boolean)?.join(',') || ''
    }
    return types
  })

  return {
    // 角标利诱点type字符集
    getCornerMarkBenefitTypes,
    getPayableAmountBenefitTypes,
  }
}
