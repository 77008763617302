// 公共类名前缀
export const prefixCls = cls => {
  const prefix = 'checkout-sbs__'
  return `${prefix}${cls}`
}

export const allQuickShippingLabel = {
  color: '#198055',
  'background-color': '#F3FAF6',
  'font-size': '10px',
  'icon-size': '16px',
  'border-radius': '2px',
  border: '0.5px solid rgba(25, 128, 85, 0.20)',
  padding: '1px 3px 1px 0',
}

export const orderSectionId = (data, storeGroupIndex) => {
  return `_current_dom_together_buy_mall${data?.mall_code || ''}${
    typeof storeGroupIndex === 'number' ? storeGroupIndex : ''
  }_quick${data?.real_quick_ship || ''}`
}

export function debuggerLog(...args) {
  if (typeof window === 'undefined') return
  try {
    const logStatus = sessionStorage?.getItem?.('__checkout_log__') == '1'
    if (!logStatus) return
    console.info(
      '%c checkout cashier log info:',
      'background: green ; padding: 3px; border-radius: 3px;  color: aliceblue; font-weight: 700;',
      ...args,
      '\n',
    )
  } catch (e) {
    console.log(...args)
  }
}
