import { nextTick } from 'vue'
import { useMapState, useMapMutations } from '@/public/src/pages/checkout_new/hooks/store.js'
import { useDelayMount } from '@/public/src/pages/checkout_new/pages/address_bar/hooks/useDelayMount'

export default () => {
  /*------ useMap*** ------*/
  const { addressBarState } = useMapState(['addressBarState'])
  const { updateAddressBarState } = useMapMutations(['updateAddressBarState'])
  const { changeWriterToReady } = useDelayMount()

  /**
 * @description: 用于唤醒地址列表弹窗，其他vue文件就不需要导入各种useMap***等地址相关参数，同时，这里进行一些兜底判断
 * @param {Object} params
 * @return {*}
 */
  const handleAddressListDrawer = async(params) => {
    const { status, extraData = {}, callBack = () => {}, selectedAddress = {} } = params
    
    // 当需要打开地址列表弹窗的时候，必须要有默认选中的地址数据
    if (status && (!selectedAddress || (selectedAddress && Object.keys(selectedAddress)?.length === 0))) {
      return
    }
    
    changeWriterToReady()

    // 防止打开的时候，地址dom没有加载
    await nextTick()
    updateAddressBarState({
      addressListDrawer: {
        status,
        extraData,
        callBack,
        selectedAddress,
      }
    })

    if (status) {
      addressBarState.value.cacheSaPageInfo = addressBarState.value.cacheSaPageInfo?.page_id ? addressBarState.value.cacheSaPageInfo : SaPageInfo || {}
      SaPageInfo = {
        page_id: 127,
        page_name: 'page_checkout_address',
        start_time: new Date().getTime()
      }
      window.SaPageInfo = SaPageInfo
      appEventCenter.emit('pageOnload')
    } else {
      window.SaPageInfo = addressBarState.value.cacheSaPageInfo
      appEventCenter.emit('pageOnload')
    }
  }

  return {
    handleAddressListDrawer
  }
}
