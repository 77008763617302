<template>
  <div>
    <s-alert
      v-expose="{
        id: 'expose_announcement:simple'
      }"
      class="j-checkout-top-notice"
      :class="prefixCls('container')"
      type="info"
      :closeable="true"
      :show-icon="true"
      :load-more-txt="language.SHEIN_KEY_PWA_15698"
      @close="handleNoticeClose"
      @onLoadMoreClick="onLoadMoreClick"
    >
      {{ checkoutNotice?.content }}
    </s-alert>

    <ClientOnly>
      <s-dialog
        :type="'long'"
        v-model:visible="isSHowMoreNotice"
        :show-close="false"
        :close-on-click-modal="false"
        append-to-body
      >
        {{ checkoutNotice?.content }}
        <template #footer>
          <s-button
            :type="['primary', 'H72PX']"
            :width="'100%'"
            @click="handleClose"
          >
            {{ language.SHEIN_KEY_PWA_15312 }}
          </s-button>
        </template>
      </s-dialog>
    </ClientOnly>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'CheckoutNotice'
})
</script>
<script setup>
import { vExpose } from 'public/src/pages/common/analysis/directive'
import { ref } from 'vue'
import { ClientOnly } from '@sheinfe/vue-client-only'
import { SAlert } from '@shein-aidc/sui-alert/mobile'
import { SDialog } from '@shein-aidc/sui-dialog/mobile'
import { SButton } from '@shein-aidc/sui-button/mobile'

import {
  useMapState,
  useMapMutations
} from '@/public/src/pages/checkout_new/hooks/store'
import { daEventCenter } from '@shein-aidc/basis-sa-event-center'
import { prefixCls } from '@/public/src/pages/checkout_new/pages/notice/utils'

const isSHowMoreNotice = ref(false)

// -------- useMap_**** --------
const { language, checkoutNotice } = useMapState(['language', 'checkoutNotice'])
const { clearCheckoutNotice } = useMapMutations(['clearCheckoutNotice'])

// ---------- method ----------
const onLoadMoreClick = () => {
  daEventCenter.triggerNotice({ id: 'expose_popup_announcement:simple' })
  isSHowMoreNotice.value = true
}
const handleClose = () => {
  daEventCenter.triggerNotice({ id: 'click_announcement_ok:simple' })
  isSHowMoreNotice.value = false
}

/**
 * @description: 关闭公告notice
 * @return {*}
 */
const handleNoticeClose = () => {
  daEventCenter.triggerNotice({ id: 'click_announcement_close:simple' })
  clearCheckoutNotice()
}
</script>

<style lang="less">
@import './variables.less';

.@{prefixCls}container {
  max-height: 2.1rem;
  overflow: hidden;
}
</style>
