<template>
  <BFFClubProductPackage
    v-expose="exposeEventData"
    :primeProductList="props.primeInfo.prime_products"
    :primeTips="props.primeInfo.prime_tips"
    :styleInfo="props.primeInfo.style_info"
    :language="props.language"
    :notSupportTip="props.notSupportTip"
    :detailDrawerProps="innerDetailDrawerProps"
    :usedPrimeIndex="props.usedPrimeIndex"
    :selectedPrimeIndex="selectedPrimeIndex"
    :onPrimeCardClick="props.onPrimeSelect"
    :viewMore="viewMorePackage"
    :styleType="styleType"
    :onBubbleClose="handleBubbleClose"
    :closedPayBubbleIndexList="closedPayBubbleIndexList"
    :freeGiftInfo="freeGiftInfo"
    :gotoCouponList="props.gotoCouponList"
  />
</template>

<script setup>
import { vExpose } from 'public/src/pages/common/analysis/directive'
import BFFClubProductPackage from './index.vue'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { watch } from 'vue'
import { ref, computed } from 'vue'

const { SiteUID } = gbCommonInfo

daEventCenter.addSubscriber({ modulecode: '1-11-1' })

const props = defineProps({
  language: {
    type: Object,
    default: () => ({})
  },
  primeInfo: {
    type: Object,
    default: () => ({})
  },
  gotoCouponList: {
    type: Function,
    default: () => {}
  },
  notSupportTip: {
    type: String,
    default: ''
  },
  onPrimeSelect: {
    type: Function,
    /** @type {(index: number) => void} */
    default: () => {}
  },
  usedPrimeIndex: {
    type: Number,
    default: -1
  },
  detailDrawerProps: {
    type: Object,
    default: () => ({
      opened: false,
      /** @type {(value: boolean) => void} */
      setOpened: () => {},
      /** @type {(index: number) => void} */
      onOk: () => {},
      /** @type {(index: number) => void} */
      onBubbleClick: () => {},
      /** @type {(index: number) => void} */
      onClose: () => {},
      /** @type {(index: number) => void} */
      onCloseFromIcon: () => {}
    })
  }
})

const styleType = SiteUID.includes('ar') ? 'new' : 'old'
const activityInfo = props.primeInfo.prime_products[0]?.activity_infos?.find(
  m => m.type === 'free_gifts_rush'
)
const freeGiftInfo = ref(
  activityInfo
    ? {
      text: activityInfo.content_tip,
      endTime: activityInfo.countdown_end_time
    }
    : null
)


const recommendProductIndex = props.primeInfo.prime_products.findIndex(
  item => +item.is_recommend
)
const defaultSelectedIndex =
  recommendProductIndex > -1 ? recommendProductIndex : 0
const selectedPrimeIndex = ref(defaultSelectedIndex)

const exposeEventData = {
  id: '1-11-1-75',
  data: {
    select_flag: '',
    privilege_count:
      props.primeInfo.prime_products[defaultSelectedIndex].right_config_list
        .length,
    buy_num_accumulate: props.primeInfo.buy_effective_num
  }
}

const innerDetailDrawerProps = computed(() => {
  return {
    ...props.detailDrawerProps,
    onProductSelect: index => {
      selectedPrimeIndex.value = index
      const productInfo = props.primeInfo.prime_products[index]
      daEventCenter.triggerNotice({
        daId: '1-11-1-81',
        extraData: {
          prime_level: productInfo.product_cycle_type || '',
          prime_product_id: productInfo.product_goods_id,
          sequence: index + 1
        }
      })
    }
  }
})

// 打开更多包详情弹窗
const viewMorePackage = () => {
  if (props.notSupportTip) return

  props.detailDrawerProps.setOpened(true)
  daEventCenter.triggerNotice({
    daId: '1-11-1-77',
    extraData: {
      select_flag: props.usedPrimeIndex > -1 ? '1' : '0'
    }
  })
  daEventCenter.triggerNotice({
    daId: '1-11-1-78'
  })
}

const closedPayBubbleIndexList = ref([])

const handleBubbleClose = index => {
  closedPayBubbleIndexList.value = [...closedPayBubbleIndexList.value, index]
}

watch(
  () => props.usedPrimeIndex,
  () => {
    selectedPrimeIndex.value =
      props.usedPrimeIndex > -1 ? props.usedPrimeIndex : defaultSelectedIndex
  }
)
</script>
