<template>
  <BenefitFloor
    v-if="showBar || benefitFloorVisible" 
    v-expose="exposedData"
    viewId="checkout-app"
    bottomId="checkout-footer-dom"
  />
</template>
<script setup>
import { computed } from 'vue'
import { useMapState, useMapGetters } from '@/public/src/pages/checkout_new/hooks/store'
import useBenefitAnimation from './hooks/useBenefitAnimation.js'
import { vExpose } from 'public/src/pages/common/analysis/directive'

defineOptions({ name: 'BenefitFloor' })

const props = defineProps({
  location: {
    type: Number,
    default: -1
  },
  showBar: {
    type: Boolean,
    default: false,
  }
})

const { checkout } = useMapState(['checkout'])
const { rewardFloorConfig } = useMapGetters(['rewardFloorConfig'])
const BenefitFloor = useBenefitAnimation()

const benefitFloorVisible = computed(() => rewardFloorConfig.value.floorLocation === props.location)

const exposedData = computed(() => {
  const { satisfiedRewardList, satisfiedRewardValue } = checkout.value?.rewardFloor?.buriedPoint || {}
  if (!satisfiedRewardList?.length) return {}
  return {
    id: 'expose_order_rewards:simple',
    data: {
      orderRewardsType: satisfiedRewardList?.join('_'),
      orderRewardsDetailValue: satisfiedRewardValue
    }
  }
})
</script>
