import { useMapMutations, useMapState } from '@/public/src/pages/checkout_new/hooks/store'
import { computed, reactive } from 'vue'
import { VirtualAssetsTypes } from '@/public/src/pages/checkout_new/utils/constant'
import { daEventCenter } from '@shein-aidc/basis-sa-event-center'

export function useVirtualAssetsDrawer({
  type,
  emits
}) {
  const model = reactive({
    input: '',
    
    // 请求发送中状态
    loading: false,
    
    // 使用过积分或者钱包
    isApplied: false
  })
  const { wallet_balance: wallet, point, orderCurrency, virtualAssetsState, language } = useMapState([
    'checkout.orderCurrency',
    'checkout.wallet_balance',
    'checkout.point',
    'virtualAssetsState',
    'language'
  ])
  const { updateVirtualAssetsPopupState } = useMapMutations([
    'updateVirtualAssetsPopupState'
  ])
  
  const placeholderTextInit = computed(() => {
    if (type === VirtualAssetsTypes.POINT && +point.value.total_point === 0) {
      return point.value?.noPointsAvailableTip || language.value.SHEIN_KEY_PWA_28535
    }
    
    if (type === VirtualAssetsTypes.WALLET) {
      if (+orderCurrency?.value.trunc > 1 && +orderCurrency?.value.decimal_place === 0) {
        return  language.value.SHEIN_KEY_PWA_16508.replace('{0}', +orderCurrency.value.trunc)
      }
      
      return language.value.SHEIN_KEY_PWA_16509
    }
    
    return language.value.SHEIN_KEY_PWA_16495
  })
  
  const maxValue = computed(() => {
    return VirtualAssetsTypes.POINT === type ? point.value.maxAvailablePoint : wallet.value.subtractPrice.amount
  })
  
  const showClearIcon = computed(() => {
    return model.input && !model.isApplied
  })
  
  const _handleUseAll = () => {
    model.input = maxValue.value
    
    if(type === VirtualAssetsTypes.WALLET) {
      daEventCenter.triggerNotice({
        id: 'click_popup_edit_wallet_all:simple',
      })
    } else {
      daEventCenter.triggerNotice({
        id: 'click_popup_edit_points_all:simple',
      })
    }
  }
  
  const _cleanInput = () => {
    model.input = ''
    model.isApplied = false
  }
  
  const inputBlur = function () {
    document.body.scrollIntoView(false)
  }
  
  const _formatWalletInput = (_str) => {
    const decimalIdx = +orderCurrency.value.decimal_place || 0 // 小数点位数
    let str = _str
    
    if (decimalIdx === 0) { // 整数
      return str.replace(/\D/g, '')
    }
    
    if (decimalIdx > 0) { // 小数
      str = str.replace(/[^0-9|.]/g, '').replace(/\.+/, '.')
      
      const groups = str.split('.').map(item => item)
      
      if(!groups[0]) {
        groups[0] = 0
      }
      
      if (groups.length > 1) {
        // 只允许一个小数点
        if (groups.length > 2) {
          groups.length = 2
        }
        // 小数位数不可超过decimal_place
        if (groups[1].length > decimalIdx) {
          groups[1] = groups[1].substring(0, decimalIdx)
        }
        
        str = groups.join('.')
      }
    }
    
    return str
  }
  
  const setMaxInput = function () {
    let input = model.input
    
    if (VirtualAssetsTypes.WALLET === type) {
      input = _formatWalletInput(input)
    } else if (VirtualAssetsTypes.POINT === type) {
      input = input.replace(/\D/g, '')
    }
    
    if (+input > +maxValue.value) {
      input = maxValue.value
    }
    
    model.input = input
  }
  
  const updateDrawerVisible = (visible) => {
    const key = VirtualAssetsTypes.POINT === type ? 'pointDrawer' : 'walletDrawer'
    const state = virtualAssetsState.value[key]
    updateVirtualAssetsPopupState({
      [key]: {
        ...state,
        visible,
      }
    })
  }
  
  const visible = computed(() => {
    const state = VirtualAssetsTypes.WALLET === type ? virtualAssetsState.value.walletDrawer : virtualAssetsState.value.pointDrawer
    return state.visible
  })
  
  const onSubmit = () => {
    if(model.loading || !model.isApplied && !model.input) {
      return false
    }
    
    const isClickApplyBtn = !model.isApplied && !model.loading
    
    if(isClickApplyBtn) {
      updateDrawerVisible(false)
    } else {
      model.input = ''
      
      const saName = VirtualAssetsTypes.WALLET === type ? 'click_popup_edit_wallet_remove' : 'click_popup_edit_points_remove'
      daEventCenter.triggerNotice({
        id: `${saName}:simple`,
      })
    }
    
    // 点击 apply 按钮和 applied 互斥
    const cb  = (status, res, realUsedValue) => {
      model.loading = false
      
      if (status && +res?.code === 0) {
        model.input = realUsedValue || ''
        // 已经取反，直接赋值
        model.isApplied = isClickApplyBtn
      }
    }
    
    emits && emits('onSubmit', { value: +model.input, isClickApplyBtn, cb })
  }
  
  const handleIconClick = () => {
    if(showClearIcon.value) {
      _cleanInput()
    } else {
      _handleUseAll()
    }
  }
  
  return {
    visible,
    model,
    placeholderTextInit,
    showClearIcon,
    handleIconClick,
    inputBlur,
    setMaxInput,
    updateDrawerVisible,
    onSubmit
  }
}
