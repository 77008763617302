<template>
  <div class="autoRenewal-bubble">
    <div class="logo">
      <img
        v-for="(item, idx) in props.logoList.slice(0, 3)"
        :key="idx"
        :src="item"
        :class="{ third: idx === 2 }"
        alt="logo"
      />
    </div>
    <div
      v-if="props.logoList.length"
      class="detail"
      @click.stop.prevent="props.onClick"
    >
      {{ props.text }}
    </div>
    <div class="arrow">
      >
    </div>
  </div>
</template>

<script setup>
// pay_methods_renewal.vue
const props = defineProps({
  logoList: {
    type: Array,
    default: () => []
  },
  text: {
    type: String,
    default: ''
  },
  onClick: {
    type: Function,
    default: () => {}
  }
})
</script>

<style lang="less" scope>
.autoRenewal-bubble {
  display: flex;
  flex-direction: row;
  align-items: center;

  .logo {
    display: flex;
    flex-direction: row;
    img {
      width: 0.64rem; /* 24px*2/75 */
      height: 0.42667rem; /* 16px*2/75 */
      margin-right: 0.10667rem;
      z-index: 9999;
      background-color: #fff;
      border-radius: 0.05333rem; /* 2px*2/75 */
    }
    .third {
      opacity: 0.6;
      width: 0.56rem;
      height: 0.37333rem;
      z-index: 9998;
      margin-top: -0.06rem;
      margin-left: -0.61rem; /* 23px*2/75 */
      opacity: 0.6;
    }
  }
  .detail {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-right: 0.10667rem;
    color: var(---sui_color_white, #fff);
    font-family: 'SF UI Text';
    font-size: 0.26667rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
  .arrow {
    color: var(---sui_color_white, #fff);
    font-family: 'SF UI Text';
    font-size: 0.26667rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }
}
</style>
