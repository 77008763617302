<template>
  <!-- 运输方式 -->
  <!--会员信息-->
  <ShippingPrime
    v-if="+group.isSheinGroup === 1"
    class="checkout-shipping__cart-group-item checkout-shipping__prime-banner-info"
    :mall-code="mallCode"
  />

  <!-- shipping method -->
  <ShippingMethodNext
    class="checkout-shipping__cart-group-item"
    :mall-code="mallCode"
    :group="group"
  />
</template>

<script setup>
import { computed } from 'vue'
import { useMapState } from '@/public/src/pages/checkout_new/hooks/store'
import ShippingPrime from '@/public/src/pages/checkout_new/pages/shipping_method/components/shipping_prime/Index.vue'
import ShippingMethodNext from '@/public/src/pages/checkout_new/pages/shipping_method/Next.vue'

const props = defineProps({
  mallCode: {
    type: String,
    default: ''
  },
  groupId: {
    type: String,
    default: ''
  },
})

const { cartGroupInfo } = useMapState(['cartGroupInfo'])

const group = computed(() => {
  const mall = cartGroupInfo.value.mallList?.find(item => +item.mallCode === +props.mallCode)
  const group = mall.groupList.find(group => group.groupId === props.groupId)

  return group
})
</script>

<style lang="less">
// 会员信息样式
div.checkout-shipping__prime-banner-info > * {
  margin: 0;
}
</style>
