<template>
  <!-- 巴西50美金税费入口 -->
  <s-alert
    v-expose="{
      id: 'expose_tax_hint:simple',
      data: { mall_code: mallCode, location: 'page' }
    }"
    v-tap="{
      id: 'click_tax_hint:simple',
      data: { mall_code: mallCode, location: 'page' }
    }"
    type="info"
    :jumpable="isAble"
    :max-rows="1000"
    :class="prefixCls('br50-dollars_tax')"
    @click.stop="handleClick"
  >
    <div v-html="text"></div>
  </s-alert>
</template>

<script setup>
import { vExpose, vTap } from 'public/src/pages/common/analysis/directive'
import { computed, inject } from 'vue'
import { SAlert } from '@shein-aidc/sui-alert/mobile'
import { prefixCls } from '@/public/src/pages/checkout_new/pages/shopping_bags/utils'
import { useMapState } from '@/public/src/pages/checkout_new/hooks/store'
import { useMapMutations } from '@/public/src/pages/checkout_new/hooks/store'

import useBr50DollarsTax from 'public/src/pages/checkout_new/pages/shopping_bags/hooks/useBr50DollarsTax.js'

defineProps({
  // 文本信息
  text: {
    type: String,
    default: ''
  }
})
const mallIndex = inject('mallIndex')

// --------- useMap_**** --------
const { 
  // 
  cartsInfo, 
  shoppingBagsState 
} = useMapState([
  'cartsInfo',
  'shoppingBagsState',
])
const { 
  // 
  updateBr50DollarsTaxDrawerState, 
  displayedBr50DollarDrawer 
} =
  useMapMutations([
    'updateBr50DollarsTaxDrawerState',
    'displayedBr50DollarDrawer',
  ])

// ---------- computed ----------
const { isAble } = useBr50DollarsTax(mallIndex)
const mallCode = computed(() => {
  return cartsInfo?.value?.good_by_mall?.[mallIndex]?.mall_code
})

// ----------- method -----------
const handleClick = () => {
  if(!shoppingBagsState.value.isDisplayedBr50DollarDrawer){
    displayedBr50DollarDrawer(true)
  }
  isAble.value && updateBr50DollarsTaxDrawerState(true)
}
</script>

<style lang="less">
@import '../../../variables.less';

.@{prefixCls}br50-dollars_tax {
  margin: 20/75rem 0 8/75rem 0;
  .margin-l(0);
  padding: 20/75rem;
}
</style>
