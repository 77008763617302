<template>
  <div
    v-if="isMultiMall"
    class="total-price"
  >
    <span class="stand-left">
      {{ language.SHEIN_KEY_PWA_15097 }}
    </span>
    <span>{{ priceMall?.total_price?.amountWithSymbol }}</span>
  </div>
</template>
<script setup>
import { computed } from 'vue'
import { useMapState } from '@/public/src/pages/checkout_new/hooks/store'

const props = defineProps({
  mallCode: {
    type: String,
    default: ''
  }
})

const { mall_price_list, language, isMultiMall } = useMapState([
  'checkout.mall_price_list',
  'language',
  'isMultiMall'
])

const priceMall = computed(() => {
  return  mall_price_list.value?.find(
    item => +item.mall_code === +props.mallCode
  ) || {}
})
</script>

<style scoped lang="less">
@import "public/src/pages/checkout_new/pages/shipping_method/variables";

.total-price {
  display: flex;
  justify-content: space-between;

  margin: 0 @g_ship_pad_hor_offset;
  padding: 20/75rem 0 20/75rem 0;
}

.stand-left {
  width: 100%;
  .shipping-title {
    .fl();
  }
}
</style>
