<template>
  <ul :class="prefixCls('scroll-box')">
    <swiper-container
      ref="shippingMethodSwiper"
      :class="[prefixCls('swipper__wrap'), {[prefixCls('swipper__only-one')]: shippingMethods.length === 1}]"
      init="false"
      :dir="GB_cssRight ? 'rtl' : 'ltr'"
    >
      <swiper-slide
        v-for="(item, index) in shippingMethods"
        :key="`${item.transport_type}-${mallCode}`"
        :class="prefixCls('swipper__item')"
      >
        <ShippingMethodItem
          :item="item"
          :index="index"
          :mallCode="mallCode"
          :class="[{'mshe-ship-disabled': !item.is_available}, {'last-shipping-item': (index + 1) === shippingMethods.length}]"
        />
      </swiper-slide>
    </swiper-container>
  </ul>
</template>
<script setup name="ShippingMethodSwiper">
import { onMounted, nextTick, ref, watch, computed } from 'vue'
import ShippingMethodItem from './shipping_method_item/index.vue'
import { register } from 'swiper/element'
import { prefixCls } from '../../utils/index'
import { useMapGetters, useMapState } from '@/public/src/pages/checkout_new/hooks/store'

const { fullShippingMethodsSelectedInfo } = useMapGetters(['fullShippingMethodsSelectedInfo'])

const { GB_cssRight } =
  useMapState([
    'GB_cssRight',
  ])

const props = defineProps({
  mallCode: {
    type: String,
    default: ''
  },
  shippingMethods: {
    type: Array,
    default: () => []
  },
  defaultShippingItem: {
    type: Object,
    default: () => ({})
  },
})

const shippingMethodSwiper = ref(null)

const selectIndex = computed(() => fullShippingMethodsSelectedInfo.value?.[props.mallCode]?.index)

onMounted(() => {
  Object.assign(shippingMethodSwiper.value, {
    slidesPerView: 'auto',
    slidesPerGroup: 1,
    slidesOffsetAfter: 12,
    normalizeSlideIndex: false,
    injectStyles: [
      `.swiper {
            overflow: visible;
          }
          `,
    ],
  })

  nextTick(() => {
    register()
    shippingMethodSwiper.value?.initialize()
    shippingMethodSwiper.value?.swiper?.slideTo(+selectIndex.value)
  })
})

watch(selectIndex, (index) => {
  if(index === '' || index < 0) {
    return false
  }
  if(shippingMethodSwiper?.value) {
    shippingMethodSwiper.value?.swiper?.slideTo(+index)
  }
})
</script>

<style lang="less">
@import 'public/src/pages/checkout_new/pages/shipping_method/variables';

.@{prefixCls} {
  &scroll-box {
    margin-left: @g_ship_pad_hor_offset;
  }
}

.@{prefixCls}swipper {
  &__wrap {
    display: flex;
    flex-wrap: nowrap;
    padding: 12/75rem 0;
    z-index: 0;
    overflow: hidden;
  }

  &__only-one {
    margin-right: @g_ship_pad_hor_offset;

    .@{prefixCls}swipper__item {
      width: 100%;

      &.swiper-slide-active {
        width: 100%;
      }
    }
  }

  &__item {
    flex-shrink: 0;
    height: auto;

    width: 75%;
    border-radius: 2px;
    background: @sui_color_gray_weak2;
    padding: 20/75rem 16/75rem 20/75rem 16/75rem;
    .margin-r(16/75rem);

    &:last-child {
      .margin-r(0);
    }
  }
}
</style>
