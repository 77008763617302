<template>
  <div
    class="checkout-ship-method__wrapper"
    :class="isSheinGroup ? `checkout-shipping-method__wrapper-${mallCode}` : ''"
    :fsp-key="`${prefixCls('head')}_${mallCode}`"
    tabindex="0"
  >
    <!--垂直排列-->
    <ShippingMethodVertical
      v-if="!showShipPopupStyle && isSheinGroup"
      :shipping-methods="shippingMethods"
      :mall-code="mallCode"
      :group-id="group.groupId"
    />
    <ShippingCartsGroupHome
      v-else
      :mall-code="mallCode"
      :shipping-methods="shippingMethods"
      :isSheinGroup="isSheinGroup"
      :group="group"
    />
  </div>
</template>

<script setup name="ShippingMethodNext">
import { computed } from 'vue'
import { prefixCls } from '@/public/src/pages/checkout_new/pages/shipping_method/utils'
import ShippingMethodVertical
  from './components/shipping_method_list/ShippingMethodVertical.vue'
import ShippingCartsGroupHome from './components/ShippingCartsGroupHome.vue'

const props = defineProps({
  mallCode: {
    type: String,
    default: ''
  },
  group: {
    type: Object,
    default: () => ({})
  }
})

const isSheinGroup = computed(() => {
  return +props.group.isSheinGroup === 1
})

const showShipPopupStyle = computed(() => {
  return +props.group.shippingInfo?.showShipPopupStyle === 1
})

const shippingMethods = computed(() => {
  return props.group.shippingInfo?.shipping_methods || []
})
</script>

<style scoped lang="less">
.checkout-ship-method {
  &__wrapper {
    background-color: #fff;
    //padding: 20/75rem 0;

    .checkout-sm__ship-wrap--vertical {
      padding: 0;
    }
  }
}
</style>
<style lang="less">
.checkout-ship-method__wrapper {
  .checkout-sm__ship-wrap--vertical__btn-more {
    border-top: 1px solid #e5e5e5;
    padding: 20/75rem 0;
    margin-bottom: -20/75rem;
  }
}
</style>
