<template>
  <div
    ref="checkoutCarouselRef"
    :class="prefixCls('header__carousel-container')"
  >
    <template
      v-for="(_, index) in [0, 1]"
      :key="index"
    >
      <div
        :class="[
          prefixCls('header__cc-item'),
          {
            [prefixCls('header__cc-item_set')]:
              noAnimationIndex === index
          }
        ]"
      >
        <slot :name="`item${index}`"></slot>
      </div>
    </template>
  </div>
</template>

<script>
import { defineComponent, nextTick } from 'vue'
import { prefixCls } from '@/public/src/pages/checkout_new/pages/header/utils'

export default defineComponent({
  name: 'CommonCarousel',
  data() {
    return {
      prefixCls,
      isReversed: false,
      noAnimationIndex: -1,
      // 映射关系为 位置: translateY
      locationYMap: [
        {
          last: '-100%',
          current: 0,
          next: '100%'
        },
        {
          last: '-200%',
          current: '-100%',
          next: 0
        }
      ],
      // 防止动画重复播放，导致错乱
      finished: true
    }
  },
  computed: {
    currentIndex() {
      // 当正序时，isReversed 为 false，currentIndex 为 0
      // 当翻转时，isReversed 为 true，currentIndex 为 1
      return Number(this.isReversed)
    },
    nextIndex() {
      return Number(!this.isReversed)
    }
  },
  methods: {
    playAnimation() {
      if (!this.finished) return
      nextTick(() => {
        this.finished = false
        const elements = this.$refs.checkoutCarouselRef.children
        const current = elements?.[this.currentIndex]
        const next = elements?.[this.nextIndex]
        if (!current || !next) return
        // 当前的标题上移到标题栏上方
        current.style.transform = `translateY(${
          this.locationYMap[this.currentIndex].last
        })`
        // 下一个标题上移到标题栏
        next.style.transform = `translateY(${
          this.locationYMap[this.nextIndex].current
        })`
        setTimeout(() => {
          this.noAnimationIndex = this.currentIndex
          // 已被移至上方的标题移到标题栏下方，等待下一次切换
          current.style.transform = `translateY(${
            this.locationYMap[this.currentIndex].next
          })`
          setTimeout(() => {
            this.noAnimationIndex = -1
            this.finished = true
            this.isReversed = !this.isReversed
            this.$emit('callBackAfterAnimation')
          }, 100)
        }, 600)
      })
    },
    reset() {
      this.isReversed = false
      const elements = this.$refs.checkoutCarouselRef.children
      const current = elements?.[this.currentIndex]
      const next = elements?.[this.nextIndex]
      current.style.transform = 'none'
      next.style.transform = 'none'
    }
  },
  emits: ['callBackAfterAnimation']
})
</script>
<style lang="less">
@import '../variables.less';

.@{prefixCls}header {
  &__carousel-container {
    position: relative;
    overflow: hidden;
    height: 26/75rem;
    .font-dpr(22px);
  }

  &__cc-item {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: transform 0.4s;
    position: relative;

    &_set {
      transition: none;
    }
  }
}
</style>
