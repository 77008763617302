import { computed } from 'vue'
import { useMapState } from '@/public/src/pages/checkout_new/hooks/store'
import { isEqual } from '@/public/src/pages/checkout_new/utils'

/**
 * @description 免邮提示
 * */
export default function useFreeShipping() {
  // -------- useMap_**** --------
  const { abtState, checkout, cartsInfo } = useMapState(['abtState', 'checkout', 'cartsInfo'])

  const isCarousel = computed(() => {
    // const isFreeShipping = abtState.value.placeOrderFreeShippingstatus
    // const isQuickShipping = abtState.value.PlaceOrderQuickshipWordStatus
    // return isFreeShipping && isQuickShipping
    return !abtState.value?.placeOrderBarLabelStatus
  })
  const visibleFsTips = computed(() => {
    return isCarousel.value
      ? !!carouselData.value?.length
      : !!checkout.value.freeShippingPlaceOrderTip
  })
  const allRollTips = computed(() => {
    let checkoutPoints = checkout.value?.placeOrderButtonRollTips || []
    let cartsInfoPoints = cartsInfo.value?.placeOrderButtonRollTips || []
    return [...checkoutPoints, ...cartsInfoPoints] || []
  })
  const freeShippingTips = computed(() => {
    return isCarousel.value
      ? allRollTips.value
      : checkout.value.freeShippingPlaceOrderTip
  })

  const carouselDataTypes = computed(() => {
    return allRollTips.value?.map(i => i?.type).filter(Boolean)?.join('、') || ''
  })

  const carouselData = computed(() => {
    const renderList = freeShippingTips.value?.filter(item => isEqual(item?.is_display, '1')) || []
    return isCarousel.value
      ? renderList?.map(item => {
        return {
          text: {
            label: item.desc,
            bgColor: item?.bg_color,
            padding: '.02666667rem .10666667rem',
            color: '#fff'
          },
          icon: {
            name: '',
            color: ''
          },
          img: {
            url: item.icon_url || '',
            style: { width: '12px' }
          }
        }
      })
      : []
  })

  return {
    // 是否展示“免邮提示”
    visibleFsTips,
    // 是否轮播
    isCarousel,
    // 轮播内容
    carouselData,
    // 角标利诱点type字符集
    carouselDataTypes,
    // 免邮提示内容
    freeShippingTips
  }
}
