<template>
  <div
    :class="prefixCls('part-quick-shipping_wrapper')"
    @click="handleLayoutArea"
  >
    <!-- quickShip商品 -->
    <div
      v-if="!!shoppingBagsState.bagsInfo?.mall?.[mallIndex]?.quickShip?.length"
      :class="prefixCls('pqs_item')"
    >
      <QuickLabel
        :mallCode="shoppingBagsState.bagsInfo?.mall?.[mallIndex]?.mall_code"
      />

      <SingleProduct
        v-if="
          shoppingBagsState.bagsInfo?.mall?.[mallIndex]?.isRenderSingleProductByQuickShip
        "
        :data="shoppingBagsState.bagsInfo?.mall?.[mallIndex]?.quickShip?.[0]"
      />
      <!-- 多商品购物袋内容区域 -->
      <MultiProduct
        v-else
        :data="shoppingBagsState.bagsInfo?.mall?.[mallIndex]?.quickShip"
      />
    </div>

    <!-- 非quickShip商品【普通商品】 -->
    <div
      v-if="!!shoppingBagsState.bagsInfo?.mall?.[mallIndex]?.normal?.length"
      :class="prefixCls('pqs_item')"
    >
      <div :class="prefixCls('pqs_label')">
        {{ total }}
      </div>

      <!-- qs运输提示 -->
      <div
        v-if="
          checkout.quick_shipping?.shop_bag_other_shipping_desc
        "
        :class="prefixCls('pqs_shop_bag_shipping')"
      >
        {{ checkout.quick_shipping.shop_bag_other_shipping_desc }}
      </div>

      <SingleProduct
        v-if="
          shoppingBagsState.bagsInfo?.mall?.[mallIndex]?.isRenderSingleProductByNormal
        "
        :data="shoppingBagsState.bagsInfo?.mall?.[mallIndex]?.normal?.[0]"
      />
      <!-- 多商品购物袋内容区域 -->
      <MultiProduct
        v-else
        :data="shoppingBagsState.bagsInfo?.mall?.[mallIndex]?.normal"
      />
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'PartQuickShipping'
})
</script>
<script setup :name="QuickShipType.PART">
import { computed, inject, provide } from 'vue'

import QuickLabel from './components/QuickLable.vue'
import SingleProduct from '../../single_product/Index.vue'
import MultiProduct from '../../multi_product/Index.vue'

import { template } from '@shein/common-function'

import { prefixCls } from '@/public/src/pages/checkout_new/pages/shopping_bags/utils'
import { useMapState } from '@/public/src/pages/checkout_new/hooks/store'

// 外页
provide('isOutsidePage', true)
const mallIndex = inject('mallIndex')
const emit = defineEmits(['emiClickLayoutArea'])
// --------- useMap_**** --------
const { shoppingBagsState, checkout } = useMapState([
  'shoppingBagsState',
  'checkout'
])

// ---------- method ----------
const handleLayoutArea = (event) => {
  emit('emiClickLayoutArea', { event })
}

// --------- computed ---------
// 商品数量
const total = computed(() => {
  const { quick_shipping } = checkout.value
  const {
    bagsInfo: { mall }
  } = shoppingBagsState.value
  const quantity = mall[mallIndex]?.totalQuantity?.normal
  return template(quantity, quick_shipping?.other_item_text || '')
})
</script>

<style lang="less">
@import '../../../variables.less';

.@{prefixCls}part-quick-shipping {
  &_wrapper {
    padding-top: 0.16rem;

    .@{prefixCls}pqs_label {
      margin-bottom: 12/75rem;
      margin-top: 20/75rem;
      .padding-r(24/75rem);
      .font-dpr(24px);
      color: #222;
    }
    .@{prefixCls}pqs_shop_bag_shipping{
      background: #f6f6f6;
      padding: 0 .21333333rem;
      border-radius: .05333333rem;
      border: .01333333rem solid var(---sui_color_gray_weak1, #e5e5e5);
      margin-bottom: 7/37.5rem;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
    }
    .@{prefixCls}pqs_item {
      .@{prefixCls}single-product_wrapper {
        margin-right: 0.32rem;
      }
    }
  }
}
</style>
