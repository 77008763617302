<template>
  <div>
    <DoubleConfirmDialog />
    <OrderDetailDrawer />
  </div>
</template>

<script setup>
/**
 * 主库
 * */
import { defineAsyncComponent, ref } from 'vue'

/**
 * 函数库
 * */

/**
 * 当前业务组件
 * */

/**
 * 异步组件
 * */
const DoubleConfirmDialog = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "checkoutv2-double-confirm-dialog" */ 'public/src/pages/checkout_new/pages/shopping_bags/components/double_confirm/Index.vue'
  ),
)
const OrderDetailDrawer = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "checkoutv2-order-detail-drawer" */ 'public/src/pages/checkout_new/pages/shopping_bags/components/order_detail_drawer/Index.vue'
  ),
)
</script>

<style lang="less"></style>
