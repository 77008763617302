// 普通按钮的布局样式
export const NormalBtnLayoutType = {
  TOP_TEXT_BOTTOM_IMAGE: 1, // 1上文下图布局
  LEFT_IMAGE_RIGHT_TEXT: 2, // 2左图右文布局
  LEFT_TEXT_RIGHT_IMAGE: 3, // 3左文右图布局
  DEFAULT: -1 // 默认兜底
}

// 普通按钮Type
export const NormalBtnType = {
  BnplExclusive: 'bnpl_exclusive',
  BnplExclusiveFallback: 'bnpl_exclusive_fallback',
  BnplNormal: 'bnpl_normal',
  CardTokenPay: 'card_token_pay', //卡token支付
  CardNormalPay: 'card_normal_pay', //普通卡支付
  CommonBnplPay: 'common_bnpl_pay', //bnpl支付
  CommonNotBnplPay: 'common_not_bnpl_pay'//非bnpl支付
}

export const BtnBenefitMap = {
  FLASH_SALE: 'Flash_sale', // 普通闪购
  ORDER_DISCOUNT: 'order_discount', // 整单折扣
  LOW_STOCK: 'low_stock', // 低库存
  CHEAPER_THAN_ADDED_CART: 'cheaper_than_added_cart', // 商品相比加车降价
  LOWEST_PRICE: 'lowest_prices', // 最低价
  SECURE: 'Secure' // 支付安全
}

export const FlashType = {
  NORAML: 1, // 普通闪购
  NEW: 2, // 新人闪购
  SPECIAL: 3, // 特殊闪购
  TODOKEY: [2, 3], //
  SPECIAL_AREA: [4, 5] // 专区闪购
}

export const PromotionTypeIds = {
  ID1: 10, //
  ID2: 11, //
  ID3: 29 //
}

// 对应的是接口返回的名称
export const ActivityPopoverTypeMap = {
  Promotion: 'promotion',
  LowStock: 'lowStock',
  LowStockA: 'lowStockA',
  LowStockB: 'lowStockB',
  Coupon: 'coupon',
  TaskIntegral: 'TaskPoints',
  LowestPrice: 'lowestPrice',
  PrimeClub: 'SHEINClubPromotion',
  SaverCard: 'SaverPromotion',
  CommentsTag: 'commentstag',
  CheaperThanAddedCart: 'cheaperThanAddedCart',
}

// 埋点需要用到的
export const analyzeBenefitTypeMap = {
  [ActivityPopoverTypeMap.Promotion]: 'huodong',
  [ActivityPopoverTypeMap.LowStock]: 'kucun',
  [ActivityPopoverTypeMap.LowStockA]: 'kucun',
  [ActivityPopoverTypeMap.LowStockB]: 'kucun',
  [ActivityPopoverTypeMap.Coupon]: 'quan',
  [ActivityPopoverTypeMap.TaskIntegral]: 'TaskPoints',
  [ActivityPopoverTypeMap.LowestPrice]: 'LowestPrice',
  [ActivityPopoverTypeMap.PrimeClub]: 'SHEINClubPromotion',
  [ActivityPopoverTypeMap.SaverCard]: 'SaverPromotion',
  [ActivityPopoverTypeMap.CommentsTag]: 'ugctags',
  [ActivityPopoverTypeMap.CheaperThanAddedCart]: 'cheaperThanAddedCart',
}

export const iconConfig = {
  prime: {
    name: 'sui_icon_club_logo_fill_14px',
    color: '#C96E3F',
    size: '14px'
  },
  flash: {
    name: 'sui_icon_flashsale_15px',
    color: '#FACF19',
    size: '12px'
  },
  stock: {
    name: 'sui_icon_hourglass_12px',
    color: '#FA6237',
    size: '12px'
  },
  lowestPrice: {
    name: 'sui_icon_lowest_price_12px_2',
    color: '#C44A01',
    size: '12px'
  },
  primeClub: {
    name: 'sui_icon_club_logo_fill_14px',
    color: '#C96E3F',
    size: '12px'
  },
  decrease: {
    name: 'sui_icon_decrease_12px',
    color: '#FA6338',
    size: '12px'
  }
}

export const paypalChannelMap = {
  PAYPAL: 'PayPal-GApaypal',
  PAYLATER: 'PayPal-bnpl',
  VENMO: 'PayPal-Venmo',
}
