import {
  useMapActions,
  useMapMutations,
} from '@/public/src/pages/checkout_new/hooks/store'
import { useShippingSelect } from '@/public/src/pages/checkout_new/pages/shipping_method/hooks/useShippingSelect'

/**
 * @description 更改购物袋商品数量
 * return {updateCartsAndCheckout} 实际执行函数
 * */
export function useAllCartsAndCheckout() {
  // --------- useMap_**** --------
  const { updateShoppingBagsData } = useMapMutations(['updateShoppingBagsData'])
  const { updateCheckoutData, updateCartsInfoData } = useMapActions([
    'updateCheckoutData',
    'updateCartsInfoData',
  ])
  const { getShippingMethodsParams, resetNormalOnSuccess } =
    useShippingSelect()
  
  const updateCartsAndCheckout = async (
    _opts = {
      isFullShippingParams: false,
      checkout: {},
      carts: {},
    },
  ) => {
    {
      const opts = {
        isFullShippingParams: false,
        ..._opts,
      }
      
      let result = await Promise.all([
        updateCartsInfoData(opts.carts || {}),
        updateCheckoutData({
          opts: opts.isFullShippingParams
            ? Object.assign(opts.checkout || {}, getShippingMethodsParams())
            : opts.checkout,
        }),
      ])
      
      if (opts.isFullShippingParams && +result[1]?.code === 0) {
        resetNormalOnSuccess()
      }
      
      updateShoppingBagsData({
        cartsInfo: result[0].info,
        checkout: result[1].info,
      })
      return result
    }
  }
  
  return { updateCartsAndCheckout }
}
