<template>
  <OldPrime
    v-if="!currShipMall?.prime_with_order_info"
    :mallCode="mallCode"
    :primeFreeTip="currShipMall?.prime_free_shipping_tip"
    :autoUseCouponTip="currShipMall?.auto_use_coupon_quantity_tip"
    :language="language"
  />
  <NewPrime
    v-else
    :mallCode="mallCode"
    :primeFreeTip="currShipMall?.prime_with_order_info"
  />
</template>

<script setup>
import { computed } from 'vue'
import { useMapState } from '@/public/src/pages/checkout_new/hooks/store'
import OldPrime from './OldPrime.vue'
import NewPrime from './NewPrime.vue'

const props = defineProps({
  mallCode: {
    type: String,
    default: ''
  }
})

const { shipping_methods_mall_adapt, language } = useMapState([
  'shippingMethodState.shipping_methods_mall_adapt',
  'language',
])

const currShipMall = computed(() => {
  return shipping_methods_mall_adapt.value?.find(item => +item.mall_code === +props.mallCode)
})

</script>
