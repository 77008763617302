<template>
  <div
    v-if="sfsInfo.visibleOuterPageSfs"
    :class="[
      prefixCls('sfs-tips'),
      shoppingBagHeadInfo.visible && 'ambiance-header',
    ]"
    @click.stop="handleVisible"
  >
    <span :class="prefixCls('sfs-tips__label')">
      {{ sfsInfo?.data?.outerPage?.tag }}
    </span>
    <span :class="prefixCls('sfs-tips__desc')">
      {{ sfsInfo?.data?.outerPage?.desc }} >
    </span>

    <SFSProductsDrawer
      v-if="isRendered"
      v-model:visible="sfsProductsDrawerVisible"
      :title="sfsInfo?.data?.popUpMetaData?.title"
      :subtitle="sfsInfo?.data?.popUpMetaData?.desc"
      :sfs-products="sfsInfo?.product"
    />
  </div>
</template>

<script setup name="SFSProductsTips">
import { ref, defineAsyncComponent } from 'vue'
import { prefixCls } from '@/public/src/pages/checkout_new/pages/shopping_bags/utils'
import useHandleMallAndStoreGroup from 'public/src/pages/checkout_new/pages/shopping_bags/hooks/useHandleMallAndStoreGroup.js'

const SFSProductsDrawer = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "checkoutv2-sfs-profuct-drawer" */ './components/SFSProductsDrawer.vue'
  ),
)


const { sfsInfo, shoppingBagHeadInfo } = useHandleMallAndStoreGroup()

const isRendered = ref(false)
const sfsProductsDrawerVisible = ref(false)

const handleVisible = () => {
  // 渲染组件，有sfs商品才能渲染
  isRendered.value = !!sfsInfo.value?.product?.length
  // 打开弹窗
  sfsProductsDrawerVisible.value = true
}
</script>
<style lang="less">
@import '../../variables.less';

.@{prefixCls}sfs-tips {
  margin: 12/75rem 24/75rem 0 0;
  font-family: 'SF Pro';
  font-size: 10px;
  line-height: 1.4;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  &.ambiance-header {
    padding-left: 0.26666667rem;
  }

  &__label {
    padding: 2/75rem 6/75rem;
    background-color: #ecfcf3;
    color: #198055;
    display: inline-block;
    line-height: 1.2;
    border-radius: 4/75rem;
    margin-right: 6/75rem;
    vertical-align: top;
  }

  &__desc {
    color: #666;
  }
}
</style>
