import { computed, watch } from 'vue'
import { useMapState, useMapGetters, useMapMutations } from '@/public/src/pages/checkout_new/hooks/store'
import { PrimeDrawerLocation } from 'public/src/pages/checkout_new/pages/prime_saver/utils/constant.js'
import { daEventCenter } from '@shein-aidc/basis-sa-event-center'

/**
 * @description 运输方式下付费会员入口信息
 * */
export default function useShippingPrime(props) {
  const { shipping_methods_mall_adapt, mall_price_list } = useMapState(['shippingMethodState.shipping_methods_mall_adapt', 'checkout.mall_price_list'])
  const { updateShipPopupByKey, updatePrimeSaverState } = useMapMutations(['updateShipPopupByKey', 'updatePrimeSaverState'])
  const { fullShippingMethodsSelectedInfo } = useMapGetters(['fullShippingMethodsSelectedInfo'])
  
  const saObj = computed(() => {
    return { texttype: props.primeFreeTip.prime_free_shipping_type, prime_level: '0', total_saving: '0', location: 'page', prime_promotion: '0', expiring_flag: '-', renew_flag: '0' }
  })
  
  const couponTpSa = computed(() => {
    const selectedShipItem = fullShippingMethodsSelectedInfo.value[props.mallCode]
    const mall = shipping_methods_mall_adapt.value.find(item => +item.mall_code === +props.mallCode)
  
    if(+selectedShipItem.shipping_price_symbol_amount !== 0 && mall.used_prime_shipping_coupon) {
      return 1
    }
  
    if(+selectedShipItem.shipping_price_symbol_amount < +selectedShipItem.originNoFreeShippingPriceAmount &&  selectedShipItem.originNoFreeShippingPriceAmount > 0 && +mall.used_prime_shipping_coupon === 1) {
      return 2
    }
  
    return 0
  })

  // 加入付费会员弹窗
  const onClickJoinPrime = () => {
    updatePrimeSaverState({
      primeDetailsDrawerVisible: true,
      primeDetailsDrawerLocation: PrimeDrawerLocation.SHIPPING
    })
  }
  
  // 打开使用优惠券弹窗
  const goCouponPage = () => {
    updateShipPopupByKey({
      key: 'couponDrawerVisible',
      value: true
    })
  
    daEventCenter.triggerNotice({
      id: 'click_shipping_coupon:simple',
      data: {
        mall_code: props.mallCode,
        coupon_tp: couponTpSa.value,
      }
    })
  }
  
  watch(() => fullShippingMethodsSelectedInfo.value[props.mallCode]?.shipping_price_symbol_amount, () => {
    daEventCenter.triggerNotice({
      id: 'click_shipping_coupon:simple',
      data: {
        mall_code: props.mallCode,
        coupon_tp: couponTpSa.value,
      }
    })
  })

  const actualShippingPrice = computed(() => {
    const selectedShipItem = fullShippingMethodsSelectedInfo.value[props.mallCode]
    const currMall = mall_price_list.value.find(item => +item.mall_code === +props.mallCode)
    const target = currMall?.shipping_price_all?.find(item => item.transport_type === selectedShipItem?.transport_type)
  
    return target?.price?.amount
  })
  
  watch(actualShippingPrice, (newVal, oldVal) => {
    if (newVal !== oldVal && props.autoUseCouponTip) {
      daEventCenter.triggerNotice({
        id: 'expose_shipping_coupon:simple',
        data: {
          coupon_tp: couponTpSa.value,
          mall_code: props.mallCode,
        }
      })
    }
  }, {
    immediate: true,
  })

  return {
    saObj,
    onClickJoinPrime,
    goCouponPage
  }
}
