import { computed, inject } from 'vue'
import { template } from '@shein/common-function'

import {
  useMapState,
  useMapGetters,
} from '@/public/src/pages/checkout_new/hooks/store'

/**
 * @description 处理mall和store的关系 === 映射关系
 * */
export default function useHandleMallAndStoreGroup(data = {}, store) {
  // --------- useMap_**** --------
  const { shoppingBagsState, showNewCartGroup, cartsInfo, checkout, locals } =
    useMapState([
      'shoppingBagsState',
      'showNewCartGroup',
      'cartsInfo',
      'checkout',
      'locals',
    ])
  const { shoppingBagHeadInfoMap } = useMapGetters(['shoppingBagHeadInfoMap'])

  /***
   * @description 内部按照 list 铺开，获取对应数据，
   * @description 备注：使用索引，时间复杂度 O(1), 匹配数据时间复杂度 O(n)
   * */
  // mall 索引
  const mallIndex = inject('mallIndex', 0)
  // 当前mall下 store 索引
  const storeGroupIndex = inject('storeGroupIndex', undefined)

  // ----------- computed ---------
  // 当前mall数据
  const currentMall = computed(
    () => shoppingBagsState.value?.bagsInfo?.mall?.[mallIndex] || {},
  )

  // 当前mall下的店铺所有数据
  const currentMallStoreGroup = computed(
    () => currentMall.value.storeGroupList || [],
  )

  // 当前mall下的当前店铺数据
  const currentMallCurrentStore = computed(() => {
    return currentMallStoreGroup.value?.[storeGroupIndex?.value]
  })

  // 当前处在店铺维度中。。。渲染店铺数据
  const isRenderStoreGroup = computed(() => {
    return showNewCartGroup.value && typeof storeGroupIndex?.value === 'number'
  })

  // 当前处在店铺维度的索引 索引01 ===[mall = 0, storeGroupIndex = 1]
  const renderIndex = computed(() => {
    return isRenderStoreGroup.value
      ? `${mallIndex}${storeGroupIndex?.value}`
      : mallIndex
  })

  // 展示对应的订单详情弹窗
  const visibleOrderDetailDrawer = computed(() => {
    // store中的弹窗状态数据，类型TS：Record<number, number>
    return shoppingBagsState.value?.visibleOrderDetailDrawer?.[
      renderIndex.value
    ]
  })

  // 氛围头
  const shoppingBagHeadInfo = computed(() => {
    // mall维度的 shopping_bag_head_info【氛围头】
    const mallHeadInfo =
      shoppingBagHeadInfoMap.value?.[currentMall.value?.mall_code]

    // mall下店铺维度的 shopping_bag_head_info【氛围头】
    const mallStoreHeadInfo =
      currentMallStoreGroup.value?.[storeGroupIndex?.value]
        ?.shopping_bag_head_info
    const data = isRenderStoreGroup.value ? mallStoreHeadInfo : mallHeadInfo
    return {
      visible:
        (isRenderStoreGroup.value && !!mallStoreHeadInfo) ||
        (!isRenderStoreGroup.value && !!mallHeadInfo),
      data,
      isRenderStoreGroup,
    }
  })

  // 利诱点
  const shoppingBagScrollBarTips = computed(() => {
    // mall维度利诱点
    const mallShoppingBagScrollBarTips =
      cartsInfo?.value?.good_by_mall?.[mallIndex]?.shoppingBagScrollBarTips ||
      []

    // mall级店铺维度利诱点
    const mallStoreShoppingBagScrollBarTips =
      currentMallStoreGroup.value?.[storeGroupIndex?.value]
        ?.shoppingBagScrollBarTips || []

    const data = isRenderStoreGroup.value
      ? mallStoreShoppingBagScrollBarTips
      : mallShoppingBagScrollBarTips

    return data
  })

  // sfs信息
  const sfsInfo = computed(() => {
    const sfsKey = 'localWarehouse'
    // sfs内容
    const mallSfsInfo = currentMall.value?.localWarehouse || {}
    const mallStoreSfsInfo = currentMallCurrentStore.value?.localWarehouse || {}
    const data = isRenderStoreGroup.value ? mallStoreSfsInfo : mallSfsInfo

    const mallSfsProduct = currentMall.value.all.filter(good =>
      good?.aggregateProductBusiness?.productTags?.find(
        tag => tag.type === sfsKey,
      ),
    )
    const mallStoreSfsProduct = currentMallCurrentStore.value?.goods?.filter(
      good =>
        good?.aggregateProductBusiness?.productTags?.find(
          tag => tag.type === sfsKey,
        ),
    )
    // sfs商品
    const product = isRenderStoreGroup.value
      ? mallStoreSfsProduct
      : mallSfsProduct

    const isMallQs = currentMall.value.quickShip?.length
    const isMallStoreQs = currentMallCurrentStore.value?.goods?.some(
      item => +item?.quick_ship === 1,
    )

    // 非qs才展示的条件
    const isAllNormalProduct = isRenderStoreGroup.value
      ? !isMallStoreQs
      : !isMallQs

    // 展示内页sfs信息
    const visibleInnerSfs =
      data?.innerPage?.tag && data?.innerPage?.desc && isAllNormalProduct
    // 展示外页sfs信息
    const visibleOuterPageSfs =
      data?.outerPage?.tag && data?.outerPage?.desc && isAllNormalProduct
    return { visibleInnerSfs, visibleOuterPageSfs, data, product }
  })

  /**
   * @description 大件海陆运提示
   * @returns {visible} 是否展示
   * @returns {icon} icon
   * @returns {desc} 文本
   * */
  const seaLandInfo = computed(() => {
    const mallSeaLand = checkout?.value?.seaLandInfo?.shoppingBag
    const mallStoreSeaLand = currentMallCurrentStore.value?.seaLandBanner || {}
    const data = isRenderStoreGroup.value ? mallStoreSeaLand : mallSeaLand
    return {
      visible: !!(data?.icon || data?.desc),
      icon: data?.icon,
      desc: data?.desc,
    }
  })

  /**
   * @description quickShip渲染数据
   * @returns {productTags} label标签
   * @returns {itemsLabel} desc文案
   * */
  const quickShipInfo = computed(() => {
    const mallQuickShipping = checkout.value?.quick_shipping || {}
    const mallStoreQuickShipping =
      currentMallCurrentStore.value?.quick_shipping || {}
    const quickShipping = isRenderStoreGroup.value
      ? mallStoreQuickShipping
      : mallQuickShipping

    const {
      new_quick_shipping_tag_desc,
      quick_shipping_time_desc,
      multi_mall_code,
    } = quickShipping || {}

    const currentMallCode =
      shoppingBagsState.value.bagsInfo?.mall[mallIndex].mall_code

    const specialCountryMap = {
      mbr: currentMallCode == multi_mall_code,
    }
    // 特定站点专项性条件
    const specialSiteResult = Object.keys(specialCountryMap).includes(
      locals.value.SiteUID,
    )
      ? specialCountryMap[locals.value.SiteUID]
      : true

    return {
      productTags:
        {
          textColor: '',
          bgColor: '',
          prefixIcon: '',
          text: new_quick_shipping_tag_desc || '',
          IconSize: '15px',
          themTextStyle: {
            'font-style': 'italic',
            color: 'rgb(25, 128, 85)',
            'font-weight': '590',
            'font-size': '12px',
          },
        } || {},
      quickShippingDesc: quick_shipping_time_desc || '',
      visible:
        specialSiteResult &&
        !!new_quick_shipping_tag_desc &&
        !!quick_shipping_time_desc,
    }
  })

  /**
   * @description 敏感品提示
   * @returns {visible} 是否展示
   * @returns {icon} icon
   * @returns {desc} 文本
   * */
  const sensitive = computed(() => {
    const mallSensitive = checkout?.value?.sensitive_info || {}

    const visibleMallStoreSensitive =
      !!currentMallCurrentStore.value?.innerSensitiveBanner
        ?.sensitive_tag_img || false

    const { sensitive_goods, sensitive_tag_img, sensitive_tips } = mallSensitive
    const mallAllGoods =
      shoppingBagsState.value?.bagsInfo?.mall?.[mallIndex]?.all || []
    const visibleMallSensitive = mallAllGoods?.some(item =>
      sensitive_goods?.some(it => it.cart_id === item.id),
    )

    const visible = isRenderStoreGroup.value
      ? visibleMallStoreSensitive
      : visibleMallSensitive
    return {
      visible,
      icon: sensitive_tag_img || '',
      desc: sensitive_tips || '',
    }
  })

  /**
   * @description 渲染数据
   * @returns {productTags} label标签
   * @returns {itemsLabel} all item文案
   * */
  const ShippingLabel = computed(() => {
    const mallQuickShipping = checkout?.value?.quick_shipping || {}
    const mallStoreQuickShipping =
      currentMallCurrentStore.value?.quick_shipping || {}
    const quick_shipping = isRenderStoreGroup.value
      ? mallStoreQuickShipping
      : mallQuickShipping

    const quantity = currentMall.value?.totalQuantity?.quickShip || 0
    return {
      productTags:
        {
          textColor: '',
          bgColor: '',
          prefixIcon: '',
          text: quick_shipping?.new_quick_shipping_tag_desc || '',
          labelStyle: {
            'font-style': 'italic',
            'font-weight': 590,
            'font-size': '10px',
            'box-shadow': 'inset 0 0 0 .5px rgba(25,128,85,.2)',
          },
        } || {},
      itemsLabel: template(
        quantity,
        quick_shipping?.quick_shipping_items_desc || '',
      ),
      tagVisible:
        quick_shipping.current_quick_ship_status == 2 ||
        (quick_shipping.current_quick_ship_status == 1 &&
          checkout.value?.quick_shipping?.switch_qs?.switch_qs_selected), // 1. 全部qs，展示。 2. 部分qs，存在运费，展示。
    }
  })

  return {
    visibleOrderDetailDrawer,
    renderIndex,
    isRenderStoreGroup,
    mallIndex,
    storeGroupIndex,
    currentMall,
    currentMallStoreGroup,
    shoppingBagHeadInfo,
    shoppingBagScrollBarTips,
    sfsInfo,
    seaLandInfo,
    quickShipInfo,
    sensitive,
    ShippingLabel,
    currentMallCurrentStore,
  }
}
