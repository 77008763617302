import { computed, inject, reactive } from 'vue'
import { useMapState } from '@/public/src/pages/checkout_new/hooks/store'
import { ModeList } from 'public/src/pages/checkout_new/pages/shopping_bags/utils/constant.js'

/**
 * @description 价格信息
 * return {txt} 价格内容
 * return {color} 价格色值
 * */

export default function usePrice(data, store) {
  // --------- useMap_**** --------
  const { checkout } = useMapState(['checkout'], store)
  const {
    aggregateProductBusiness: { priceData: rawPriceData },
  } = data 

  const priceData = reactive(rawPriceData);

  const isOutsidePage = inject('isOutsidePage', false)

  const isMode = computed(() => {
    // 合规
    const priceMode = priceData?.priceMode
    return ModeList.includes(priceMode) && isOutsidePage
  })
  /**
   * @description 价格信息
   * @returns {txt} 文本
   * @returns {color} 颜色
   * */
  const price = computed(() => {
    const matchPrice = checkout.value?.cartList?.find(
      item => item?.cartId === data?.id,
    )?.priceData?.unitPrice?.price

    let res = {
      txt:
        (!!matchPrice ? matchPrice : priceData?.unitPrice?.price)
          ?.amountWithSymbol || '',
      color: priceData?.unitPrice?.color,
    }
    return res
  })

  /**
   * @description 价格文本过长，过长则隐藏折扣
   * */
  const isPriceTxtTooLong = computed(() => {
    // 价格文本
    const renderPrice = price?.value.txt
    // 折扣最大宽度
    const maxDiscountContentWidth = 31
    // 商品卡的宽度，设计稿宽度即可
    const normalProductItemWidth = 74
    // 计算得知价格区域的最大宽度
    const priceWidth = normalProductItemWidth - maxDiscountContentWidth
    // 这段逻辑是为了表述清楚函数 isPriceTxtTooLong的处理思想是什么，同时为了兼容SSR和CSR【实际只需要renturn那部分代码即可】
    let maxPriceCharacterCount = 0
    if (priceWidth) {
      maxPriceCharacterCount = 7
    }
    return renderPrice?.trim()?.length >= maxPriceCharacterCount
  })

  return { price, isPriceTxtTooLong, isMode }
}
