import { computed } from 'vue'
import { useMapState } from '@/public/src/pages/checkout_new/hooks/store'
import useHandleMallAndStoreGroup from 'public/src/pages/checkout_new/pages/shopping_bags/hooks/useHandleMallAndStoreGroup.js'

/**
 * @description 头部轮播标题
 * @param {mallIndex} 当前 mall 索引
 * @returns {swiperTitleConfig} 轮播标题
 * */
export default function useHeaderTitle(mallIndex) {
  const { cartsInfo, shoppingBagsState } = useMapState([
    'cartsInfo',
    'shoppingBagsState',
  ])
  const { shoppingBagHeadInfo, shoppingBagScrollBarTips } = useHandleMallAndStoreGroup()

  const swiperTitleConfig = computed(() => {
    const result = shoppingBagScrollBarTips.value?.map(item => {
        if (item?.type === 'soldout' && ['3', '5'].includes(shoppingBagHeadInfo.value?.data?.type)) return

        return {
          text: {
            label: item?.displayText || '',
            color: item?.textColor || '',
          },
          icon: {
            name: '',
            color: '',
          },
          img: {
            url: item?.imgIconUrl || '',
            style: { width: '12px' },
          },
        }
      })
      .filter(Boolean)
    return result
  })
  return { swiperTitleConfig }
}
