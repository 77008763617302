<template>
  <!-- 以店铺维度的购物袋和运输方式的组合.doc= https://arc.sheincorp.cn/docs/2743204757322610688 -->
  <div 
    class="store-group_shopping-shipping__wrapper" 
    da-expose-code="checkout-store-group-code"
  >
    <StoreHeader
      v-if="isMultiMall"
      isMultiMallStore
    />
    <template 
      v-for="(group, index) in storeGroupList" 
      :key="group.store_code"
    >
      <div
        v-if="!!storeGroupList?.[index]?.goods?.length"
        class="store-group_group-item"
      >
        <!--  店铺头 -->
        <StoreHeader
          :groupIndex="index"
          :groupId="group.buryingGroupCode"
          @handleVisibleBagsDetail="handleVisibleBagsDetail"
        />
        <!-- 购物袋 -->
        <ShoppingBags
          :groupIndex="index"
          @handleVisibleBagsDetail="handleVisibleBagsDetail"
        />

        <ShippingMethodGroupWrap
          :mall-code="mallCode"
          :group-id="group.store_code"
        />
      </div>
    </template>

    <!--运费险-->
    <ShippingInsurance
      class="checkout-shipping__cart-group-item"
      :mall-code="mallCode"
    />

    <!--total price-->
    <ShippingTotalPrice
      class="checkout-shipping__cart-group-item"
      :mall-code="mallCode"
    />

    <!-- 因为店铺维度需要遍历，所以，事件类触发产生的组件，需要提出来【放到ClientOnly下对应的业务模块】，不然造成组件重复渲染 -->
    <!-- 公共/业务弹窗/异步组件，非首屏 -->
    <ClientOnly>
      <!-- 购物袋 -->
      <ShoppingBagsClient />
      <!-- 运输方式 -->
    </ClientOnly>
  </div>
</template>
<script>
export default {
  name: 'StoreGroupShoppingShipping',
}
</script>
<script setup name="StoreGroupShoppingShipping">
/**
 * 主库
 * */
import { provide, computed, ref } from 'vue'
import { ClientOnly } from '@sheinfe/vue-client-only'

/**
 * 当前业务组件
 * */
import StoreHeader from './components/StoreHeader.vue'
import ShoppingBags from './components/ShoppingBags.vue'
import ShoppingBagsClient from './components/ShoppingBagsClient.vue'

import ShippingMethodGroupWrap from './components/ShippingMethodGroupWrap.vue'
import ShippingTotalPrice from '@/public/src/pages/checkout_new/pages/shipping_method/components/ShippingTotalPrice.vue'
import ShippingInsurance from '@/public/src/pages/checkout_new/pages/shipping_method/components/ShippingInsurance.vue'
/**
 * 当前业务函数
 * */
import {
  useMapState,
  useMapMutations,
} from '@/public/src/pages/checkout_new/hooks/store'
import useShoppingBagReport from 'public/src/pages/checkout_new/pages/shopping_bags/hooks/useShoppingBagReport.js'
const props = defineProps({
  mallIndex: {
    type: Number,
    default: 0,
  },
  mallCode: {
    type: String,
    default: '',
  },
})

const storeGroupIndex = ref(null)

// 内部按照 cartGroupInfo 渲染，获取对应数据，
// 备注：使用索引，时间复杂度 O(1), 匹配数据时间复杂度 O(n)
provide('mallIndex', props.mallIndex || 0)
provide('storeGroupIndex', storeGroupIndex)

// --------- useMap_**** --------
const { shoppingBagsState, cartGroupInfo, isMultiMall } = useMapState([
  'shoppingBagsState',
  'cartGroupInfo',
  'isMultiMall',
])
const { updateVisibleOrderDetailDrawerState } = useMapMutations([
  'updateVisibleOrderDetailDrawerState',
])

const { handleShoppingBagOpen, handleShoppingBagDrawerExposed } =
  useShoppingBagReport()

// ----------- computed ---------
const currentMall = computed(
  () => shoppingBagsState.value?.bagsInfo?.mall?.[props.mallIndex] || {},
)

const storeGroupList = computed(() => currentMall.value.storeGroupList)

// ------------ method ----------
// 打开购物袋商品详情弹窗
const handleVisibleBagsDetail = ({
  event,
  groupIndex,
  source = 'pictures',
}) => {
  event.stopPropagation()

  storeGroupIndex.value = groupIndex
  console.log('handleVisibleBagsDetail__', props.mallIndex, groupIndex, source)
  updateVisibleOrderDetailDrawerState({
    [`${props.mallIndex}${groupIndex}`]: true,
  })

  handleShoppingBagOpen(props.mallIndex, source)
  handleShoppingBagDrawerExposed(props.mallIndex)
}
</script>

<style lang="less">
.store-group_shopping-shipping__wrapper {
  background: #fff;
  padding: 0 0 0 0.32rem; // 别动，购物袋商品项滑动需要贴边

  .store-group_group-item {
    padding-bottom: .26666667rem;
    border-top: 1px solid #e5e5e5;

    &:first-child {
      border-top: none;
    }
  }

  div.checkout-shipping__cart-group-item {
    margin-left: 0;
    margin-right: 0.32rem;
  }

  .checkout-sm__prime-entry {
    margin-top: 12/75rem;
    margin-bottom: 0.1066666rem;
    margin-left: 0;
  }

  .checkout-sm__ship-wrap--vertical__btn-more {
    margin-top: 16/75rem;
  }

  .checkout-sm__ship-item__inner-item:first-child {
    max-width: calc(100% - 0.4rem);
  }
}
</style>
