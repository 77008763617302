<template>
  <div :class="[prefixCls('mp__price')]">
    <!-- 价格 -->
    <span
      v-if="isClient"
      :class="prefixCls('mp__price-info')"
      :style="{ color: renderData.isMode ? '#222222' : renderData.price.color }"
    >
      {{ renderData.price.txt }}
    </span>
    <div
      v-else
      :class="prefixCls('mp__price-info')"
      :style="{ maxWidth: '74px' }"
    >
      <SAdapterText
        :class="`${prefixCls('mp__price-info')}_${data.id}`"
        min-size="10"
        :text="renderData.price.txt"
        :style="{ color: renderData.price.color }"
      />
    </div>

    <!-- 折扣角标 -->
    <Discount
      v-if="!renderData.isPriceTxtTooLong && !renderData.isMode"
      :data="data"
      isMultiple
      :class="[prefixCls('mp__discount')]"
    />
  </div>
</template>

<script setup name="ProductPrice">
import { computed, inject } from 'vue'
import { SAdapterText } from '@shein-aidc/sui-adapter-text/mobile'
import Discount from '@/public/src/pages/checkout_new/pages/shopping_bags/components/common/discount.vue'
import { prefixCls } from '@/public/src/pages/checkout_new/pages/shopping_bags/utils'
import usePrice from 'public/src/pages/checkout_new/pages/shopping_bags/hooks/usePrice.js'
import useIsClient from '@/public/src/pages/checkout_new/hooks/useIsClient.js'
import productPrice from 'public/src/pages/checkout_new/utils/productPrice.js'
import { ModeList } from 'public/src/pages/checkout_new/pages/shopping_bags/utils/constant.js'
import { useMapState } from '@/public/src/pages/checkout_new/hooks/store'
const props = defineProps({
  data: {
    type: Object,
    default: () => ({}),
  },
})

const { isClient } = useIsClient()
// --------- useMap_**** --------
const { checkout } = useMapState(['checkout'])
const isOutsidePage = inject('isOutsidePage', false)
/**
 * @description 价格文本过长，过长则隐藏折扣
 * */
const isPriceTxtTooLong = computed(() => {
  const priceInfo = productPrice(props.data, checkout.value?.cartList)
  const { price } = priceInfo
  // 价格文本
  const renderPrice = price?.txt
  // 折扣最大宽度
  const maxDiscountContentWidth = 31
  // 商品卡的宽度，设计稿宽度即可
  const normalProductItemWidth = 74
  // 计算得知价格区域的最大宽度
  const priceWidth = normalProductItemWidth - maxDiscountContentWidth
  // 这段逻辑是为了表述清楚函数 isPriceTxtTooLong的处理思想是什么，同时为了兼容SSR和CSR【实际只需要renturn那部分代码即可】
  let maxPriceCharacterCount = 0
  if (priceWidth) {
    maxPriceCharacterCount = 7
  }
  return renderPrice?.trim()?.length >= maxPriceCharacterCount
})

const isMode = computed(() => {
  // 合规
  const priceData = props.data?.aggregateProductBusiness?.priceData
  const priceMode = priceData?.priceMode
  return ModeList.includes(priceMode) && isOutsidePage
})

const renderData = computed(() => {
  const priceInfo = productPrice(props.data, checkout.value?.cartList)
  const { price } = priceInfo
  return {
    price,
    isPriceTxtTooLong: isPriceTxtTooLong.value,
    isMode: isMode.value,
  }
})
</script>

<style lang="less">
@import '../../../variables.less';

.@{prefixCls}mp {
  &__price {
    width: 148/75rem;
    height: 30/75rem;
    text-align: center;
    line-height: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #222222;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 8/75rem;

    &-info {
      .font-dpr(24px);
      font-weight: bold;
    }
  }

  &__discount {
    display: inline-block;
    border-radius: 2px;
    padding: 2/75rem 4/75rem;
    .font-dpr(20px);
    .margin-l(4/75rem);
  }
}
</style>
