<template>
  <CommonShippingItem
    v-expose="{
      id: 'expose_shipping_list.page_checkout',
      data: {
        mallCode,
        isFold,
        index,
        item,
        checkout,
        fullShippingMethodsSelectedInfo
      }
    }"

    v-tap="{
      id: 'click_shipping_list.page_checkout',
      data: {
        mallCode,
        isFold,
        index,
        item,
        checkout,
        fullShippingMethodsSelectedInfo
      }
    }"
    :mall-code="mallCode"
    :groupId="groupId"
    :item="item"
    :render-options="{ hasChecked, checked: isCheckedCombine, showAddItemEntryInTitle: isShowShippingTitleAdd}"
    :disabled="disabled"
    @on-ship-item-click="handleShipItemSelect"
  >
    <slot name="add-item-title">
      <template v-if="isShowShippingTitleAdd">
        <span v-if="isShowShippingTitleAmount">&nbsp;{{ item.shippingPrices.amountWithSymbol }},&nbsp;</span>
        <!-- 凑单 -->
        <div style="display: inline-block">
          <AddOnItem
            :freeShippingAddItem="item.free_shipping_add_item"
            :shippingPrices="item.shippingPrices"
            :language="language"
            :mallCode="mallCode"
            :transportType="item.transport_type"
            :class="prefixCls('ship-item__inner-item')"
          />
        </div>
      </template>
    </slot>

    <!--大件运费入口-->
    <div
      v-if="item.shippingFeeInfoTip"
      v-expose="{
        id: 'expose_shippingfee_detail:simple',
        data: {
          mall_code: mallCode,
          shipping_method: item.transport_type
        }
      }"
      v-tap="{
        id: 'click_shippingfee_detail:simple',
        data: {
          mall_code: mallCode,
          shipping_method: item.transport_type
        }
      }"
      :class="[prefixCls('ship-item__fee-info'), prefixCls('ship-item__inner-item')]"
      @click.stop="showLargeShipInfo"
      v-html="item.shippingFeeInfoTip"
    >
    </div>

    <!-- 凑单入口 -->
    <AddOnItem
      v-if="isShowShippingContentAdd"
      :freeShippingAddItem="item.free_shipping_add_item"
      :language="language"
      :mallCode="mallCode"
      :transportType="item.transport_type"
      :class="prefixCls('ship-item__inner-item')"
    />

    <!-- 大件海陆运提示 -->
    <LargeShipTips
      v-if="item.seaLandInfo?.outsideDesc"
      :class="prefixCls('ship-item__inner-item')"
      :largeShipInfo="item.seaLandInfo"
    />
  </CommonShippingItem>
</template>

<script setup name="ShippingMethodItem">
import { vExpose, vTap } from 'public/src/pages/common/analysis/directive'
import { computed, watch } from 'vue'
import CommonShippingItem from './CommonShippingItem.vue'
import { useMapState, useMapMutations, useMapGetters } from '@/public/src/pages/checkout_new/hooks/store'
import LargeShipTips from './large_ship_tip/index.vue'
import { daEventCenter } from '@shein-aidc/basis-sa-event-center'
import AddOnItem from './AddItem.vue'
import { findGroup, prefixCls } from '../../../utils/index'
import { useShippingSelect } from '../../../hooks/useShippingSelect'

/*------ props ------*/
const props = defineProps({
  item: {
    type: Object,
    default: () => {
    }
  },
  index: {
    type: Number,
    default: 0
  },
  mallCode: {
    type: [String, Number],
    default: ''
  },
  groupId: {
    type: String,
    default: ''
  },
  onShipItemClick: {
    type: Function,
    default: null
  },
  isFold: {
    type: Boolean,
    default: true
  },
  hasChecked: {
    type: Boolean,
    default: true
  },
  isChecked: {
    type: [Boolean, undefined],
    default: undefined
  },
  disabled: {
    type: Boolean,
    default: false
  }
})

/*------ useMap** ------*/
const { abtInfo, abtState, cartsInfo, language, checkout, shipping_methods_mall, cartGroupInfo } = useMapState(['abtInfo', 'abtState', 'cartsInfo', 'language', 'checkout',  'checkout.shipping_methods_mall', 'cartGroupInfo'])
const { updateShipPopupState } = useMapMutations(['updateShipPopupState'])
const { fullShippingMethodsSelectedInfo } = useMapGetters(['fullShippingMethodsSelectedInfo'])

const { onShippingSelect } = useShippingSelect()

/*------ state ------*/

/*------ computed ------*/
const addOnItemAbtVisible = computed(() => {
  return abtInfo.value.CheckoutShippingAddEntracne?.param.checkout_shipping_add_entracne_switch === 'on'
})

const isShowShippingTitleAmount = computed(() => {
  return +props.item?.shippingPrices?.amount > 0
})

const isSingleMall = computed(() => {
  return !cartsInfo.value?.is_multi_mall
})

// 只有单mall
const isShowShippingTitleAdd = computed(() => {
  const isShowTitleAdd = props.item?.free_shipping_add_item?.show_tip?.tip
    && isSingleMall.value
    && abtState.value?.addFreeShippingNewEntrance === 'typeB'
    || abtState.value?.addFreeShippingNewEntrance === 'typeC'

  return isShowTitleAdd
})

const isShowShippingContentAdd = computed(() => {
  const addTypeNotBAndC = abtState.value?.addFreeShippingNewEntrance !== 'typeB' && abtState.value?.addFreeShippingNewEntrance !== 'typeC'
  const isShowContentAdd = props.item?.free_shipping_add_item?.show_tip?.tip
    && (abtState.value?.addFreeShippingNewEntrance === 'typeA' && isSingleMall.value) || (addOnItemAbtVisible.value && addTypeNotBAndC)

  return isShowContentAdd
})

const isCheckedCombine = computed(() => {
  if(props.isChecked !== undefined) {
    return props.isChecked
  }

  return fullShippingMethodsSelectedInfo.value?.[props.mallCode]?.transport_type === props.item.transport_type
})

const addItemCb = () => {
  const addItem = props.item?.free_shipping_add_item
  if (addItem?.show_tip?.tip
    && isSingleMall.value
    && abtState.value?.addFreeShippingNewEntrance === 'typeC'
  ) {
    daEventCenter.triggerNotice({
      id: 'click_shipping_doorsill:simple',
      data: {
        shipping_method: props.item.transport_type || '-',
        class: +addItem?.add_item_type === 1 ? 'coupon' : +addItem?.add_item_type === 2 ? 'activity' : '-',
        origin_price: addItem?.threshold_price_symbol || '-',
      }
    })
  }
}

/*------ methods ------*/
/**
 * @description: 选择运输方式
 * @return {*}
 */
const handleShipItemSelect = () => {
  // 禁用不能选中或已选中
  if(isCheckedCombine.value) {
    return false
  }

  const group = findGroup(cartGroupInfo.value, props.mallCode, props.groupId)

  // 非 shein 店铺不允许切换
  if(props.groupId && +group.isSheinGroup !== 1) {
    return false
  }

  if(props.onShipItemClick && typeof props.onShipItemClick === 'function') {
    props.onShipItemClick({
      mallCode: props.mallCode,
      groupId: props.groupId,
      transportType: props.item.transport_type,
      item: props.item
    })
    addItemCb()
    return
  }

  onShippingSelect({
    mallCode: props.mallCode,
    groupId: props.groupId,
    transportType: props.item.transport_type,
    isItemClick: true
  })

  addItemCb()
}

// 大件运输
const showLargeShipInfo = (e) => {
  updateShipPopupState({
    shippingFee: {
      visible: true,
      mallCode: props.mallCode,
      groupId: props.groupId,
      transportType: props.item.transport_type
    }
  })
  e.stopPropagation()
}

watch(() => props.item.free_shipping_add_item, () => {
  if(shipping_methods_mall.value.length > 1) {
    return
  }

  daEventCenter.triggerNotice({
    id: 'expose_scenesabt:simple',
    data: {
      scenes: 'checkout_shipping_add',
      type: props.item.free_shipping_add_item?.show_tip?.tip && addOnItemAbtVisible ? 1 : 0,
      progress: !!props.item.transport_type ? props.item.free_shipping_add_item?.progressPercent : '-'// 当type=1时，上报当前邮费券或免邮活动的凑单进度百分比；当type=0时，上报"-"
    }
  })
})

</script>

