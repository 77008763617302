<template>
  <div
    v-if="pointsVisible"
    :class="[
      'checkout-reward-points__wrapper',
    ]"
  >
    <!-- 无任务积分 -->
    <NoPoints />  
  </div>
</template>

<script setup name="RewardPoints">
import { computed } from 'vue'
import NoPoints from './components/NoPoints.vue'

import { useMapState, useMapGetters } from '@/public/src/pages/checkout_new/hooks/store'

const { checkout } = useMapState(['checkout'])
const { rewardFloorConfig } = useMapGetters(['rewardFloorConfig'])

const pointsVisible = computed(() => {
  return !rewardFloorConfig.value.rewardVisible &&
    checkout.value.reward_point_info?.regular_reward_point_info
})
</script>

<style lang="less">
@import './variables.less';

.checkout-reward-points__wrapper {
  background: #ffffff;
  padding: 24/75rem;
}
</style>
