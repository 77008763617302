<template>
  <div 
    class="new-user-gifts"
    :style="moduleStyle"
  >
    <div 
      class="new-user-gifts__rule"
      @click="ruleDrawerVisible = true"
    >
      {{ language.SHEIN_KEY_PWA_29981 }}
    </div>
    <div class="new-user-gifts__top">
      <div 
        ref="activityTitleRef"
        class="new-user-gifts__title"
        :class="{
          'new-user-gifts__title_ellipsis': ellipsisVisible
        }"
      >
        {{ xtraAggregatedCouponList.title }}
      </div>
      <div class="new-user-gifts__desc">
        <SAdapterText
          min-size="10"
          lines="2"
          :text="xtraAggregatedCouponList.desc"
        />
      </div>
    </div>
    <div class="new-user-gifts__coupons">
      <swiper-container
        v-for="(module, index) in newUserCoupons"
        :key="index"
        class="new-user-gifts__list"
        :class="`new-user-gifts__list_${module.type}`"
      >
        <swiper-slide
          v-for="(coupon) in module.couponList"
          :key="coupon.couponCode"
          class="new-user-gifts__item"
          :class="{
            'new-user-gifts__item_small': module.type === NewUserCouponType.Single &&
              module.couponList.length > 1
          }"
        >
          <NewUserCoupon
            :coupon="coupon" 
            :language="language"
            :isSmall="
              module.type === NewUserCouponType.Single &&
                module.couponList.length > 1
            "
            infoIconVisible
            @showDetailsDrawer="showDetailsDrawer({
              ...module,
              coupon
            })"
          />
        </swiper-slide>
      </swiper-container>
    </div>

    <NewUserRuleDrawer
      v-model:visible="ruleDrawerVisible"
      :rule="xtraAggregatedCouponList.ruleDesc"
      :language="language"
    />

    <NewUserDetailsDrawer
      v-model:visible="detailsDrawer.visible"
      :attributeName="xtraAggregatedCouponList.title"
      :language="language"
      v-bind="detailsDrawer"
    />
  </div>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'NewUserGifts'
})
</script>

<script setup>
import { computed, nextTick, ref, watch } from 'vue'
import { register } from 'swiper/element'
import { SAdapterText } from '@shein-aidc/sui-adapter-text/mobile'
import NewUserCoupon from './NewUserCoupon.vue'
import NewUserRuleDrawer from './NewUserRuleDrawer'
import NewUserDetailsDrawer from './NewUserDetailsDrawer'
import { NewUserCouponType } from './config.js'

typeof window !== 'undefined' && register()
const { GB_cssRight = false } = typeof gbCommonInfo !== 'undefined' ? gbCommonInfo : {}

const props = defineProps({
  language: {
    type: Object,
    default: () => {}
  },
  xtraAggregatedCouponList: {
    type: Object,
    default: () => {}
  },
  currentCode: {
    type: String,
    default: ''
  }
})


const activityTitleRef = ref(null)
const ellipsisVisible = ref(false)
const ruleDrawerVisible = ref(false)
const detailsDrawer = ref({ visible: false, subtitle: '', list: [] })

const newUserCoupons = computed(() => {
  const singleCouponList = props.xtraAggregatedCouponList?.singleCycle?.couponList || []
  const roundCouponList = props.xtraAggregatedCouponList?.multiCycle?.couponList || []
  return [
    { 
      type: NewUserCouponType.Single,
      couponList: singleCouponList
    },
    {
      type: NewUserCouponType.Round,
      couponList: roundCouponList
    }
  ].filter(item => !!item.couponList.length)
})

const moduleStyle = computed(() => {
  const imageMap = {
    default: {
      default: 'https://img.ltwebstatic.com/images3_ccc/2024/04/25/9c/171404046120632db9d04cd17324f129096240549d.webp',
      small: 'https://img.ltwebstatic.com/images3_ccc/2024/04/25/9c/1714040461b83941b9744431feb28169b0dfdd4b80.webp'
    },
    ar: {
      default: 'https://img.ltwebstatic.com/images3_ccc/2024/04/25/82/17140405359130c3abb7bf720244bb31cfcdf5d4ca.webp',
      small: 'https://img.ltwebstatic.com/images3_ccc/2024/04/25/82/171404053569c6f4042365544e18a4861bde7bf9f1.webp'
    },
  }
  const sizeKey = newUserCoupons.value.length < 2 ? 'small' : 'default'
  const regionKey = GB_cssRight ? 'ar' : 'default'
  return {
    backgroundImage: `url('${imageMap[regionKey][sizeKey]}')`,
    height: sizeKey === 'small' ? `${310 / 75}rem` : `${476 / 75}rem`
  }
})


const initStyle = () => {
  if (!activityTitleRef.value) return
  let singleRowHeight = 23
  const { clientHeight } = activityTitleRef.value
  ellipsisVisible.value = clientHeight > singleRowHeight
  nextTick(() => {
    if (ellipsisVisible.value) singleRowHeight = 12
    const isTwoRow = activityTitleRef.value.clientHeight > singleRowHeight
    activityTitleRef.value.style.backgroundSize = isTwoRow ? '100% 50%' : '100% 100%'
  })
}

const initSwiper = () => {
  newUserCoupons.value.forEach(item => {
    const className = `.new-user-gifts__list_${item.type}`
    const xtraSwiper = document.querySelector(className)
    const min = item.type === NewUserCouponType.Single ? 2 : 1
    Object.assign(xtraSwiper, {
      direction: 'horizontal',
      slidesPerView: 'auto',
      slidesPerGroup: 1,
      slidesOffsetAfter: 15,
      noSwiping: item.couponList.length <= min,
      noSwipingClass: 'new-user-gifts__item'
    })
    xtraSwiper.initialize()
  })
}

const showDetailsDrawer = ({ type, couponList, coupon }) => {
  let subtitle = '', list = []
  switch(type) {
    case NewUserCouponType.Single:
      subtitle = props.language.SHEIN_KEY_PWA_29993
      list = couponList
      break
    case NewUserCouponType.Round: {
      subtitle = coupon.validDayTip
      list = couponList
      break
    }
  }

  detailsDrawer.value = { visible: true, subtitle, list }
}


watch(() => props.xtraAggregatedCouponList, () => {  
  if (!props.xtraAggregatedCouponList) return
  nextTick(() => {
    initStyle()
    initSwiper()
  })
}, { immediate: true }
)

</script>

<style lang="less" scoped>
.new-user-gifts {
  width: 702/75rem;
  height: 476/75rem;
  background-size: 100% 100%;
  background-image: url('https://img.ltwebstatic.com/images3_ccc/2024/04/25/9c/171404046120632db9d04cd17324f129096240549d.webp');
  padding: 14/75rem 5/75rem 28/75rem 28/75rem;
  position: relative;
  // overflow: hidden;
  
  &__rule {
    background: rgba(0, 0, 0, 0.3);
    color: #fff;
    .font-dpr(22px);
    line-height: 1.2;
    padding: 6/75rem 16/75rem 4/75rem 20/75rem;
    border-top-left-radius: 20/75rem;
    border-bottom-left-radius: 20/75rem;
    position: absolute;
    right: 4/75rem;
    top: 54/75rem;
  }

  &__top {
    position: absolute;
    top: 36/75rem;
    left: 28/75rem;
  }

  &__title {
    max-width: 352/75rem;
    font-weight: 860;
    .font-dpr(38px);
    line-height: 1.2;
    background-image: linear-gradient(180deg, #FFF 39.13%, #FBCC70 86.96%);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    &_ellipsis {
      margin-top: 8/75rem;
      .font-dpr(20px);
      overflow : hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }

  &__desc {
    max-width: 378/75rem;
    height: 56/75rem;
    .font-dpr(24px);
    color: #fff;
    line-height: 1.15;
  }

  &__coupons {
    margin-top: 104/75rem;
  }

  &__list {
    margin-top: 8/75rem;
  }

  &__item {
    padding-top: 16/75rem;
    height: 160/75rem;
    width: 648/75rem !important;

    &_small {
      width: 328/75rem !important;
    }
  }
}
</style>
