import { useMapGetters } from 'public/src/pages/checkout_new/hooks/store'
import { CardType } from '../utils/constant.js'

export default function useQuickPayments() {
  
  const { 
    paymentInfo,
    selectedPaymentInfo,
  } = useMapGetters([
    'paymentInfo',
    'selectedPaymentInfo',
  ])

  // const {
  //   getLocalStatus
  // } = useMapMutations([
  //   'getLocalStatus'
  // ])

  // 判断该支付方式是否支持快捷支付
  const isQuickPayment = ({ cardType, payment, isPaymentList = false }) => {
    const supportKey = {
      [CardType.SAVE]: 'support_save_card_quick',
      [CardType.PRIME]: 'support_prime_quick',
    }
    const isSupport = +payment[supportKey[cardType]] === 1

    const isEnable = +payment.enabled === 1

    // 默认签约
    let isSignedUp = true
    // 过滤支付方式列表，需要通过支付列表本地存储的支付信息判断
    // let currentPaymentInfo = localStatus.value?.[payment.code] || {}

    // if (!isPaymentList) {
    //   // 过滤选中的支付方式，只需要通过当前选中支付信息判断
    //   currentPaymentInfo = selectedPaymentInfo.value
    // }

    let currentPaymentInfo = selectedPaymentInfo.value

    // 未签约且支持签约
    if ( payment.needToSign === '1') {
      // 如果是过滤支付列表，不需要判断是否签约
      if (isPaymentList) {
        isSignedUp = true
      } else {
        isSignedUp = currentPaymentInfo?.signupFlag === '1'
      }
    }

    // 已签约且未选择一次支付
    if (payment.paymentSignUp?.[0]?.id) {
      if (isPaymentList) {
        isSignedUp = true
      } else {
        isSignedUp = !currentPaymentInfo?.use_one_time_sign
      }
    }

    // 非同质化支付方式 && 支持快捷支付 && 已签约
    return payment.code && isSupport && isSignedUp && isEnable
  }

  // 过滤弹窗里的快捷支付方式
  const filterQuickPayments = ({ cardType, payments }) => {
    // getLocalStatus()
    return payments?.filter(payment => isQuickPayment({ cardType, payment, isPaymentList: true }))
  }

  // 过滤气泡上的快捷支付方式
  const filterBubbleQuickPayments = ({ cardType, payments }) => {
    return payments.filter(quickPayment => {
      let originPaymentInfo = paymentInfo.value.payments.find(payment => payment.code === quickPayment.code) || {}
      if (quickPayment.code === selectedPaymentInfo.value.code) {
        originPaymentInfo = {
          ...originPaymentInfo,
          ...(selectedPaymentInfo.value || {})
        }
      }
      // getLocalStatus()
      return isQuickPayment({ cardType, payment: originPaymentInfo, isPaymentList: true })
    })
  }

  return { 
    isQuickPayment, 
    filterQuickPayments,
    filterBubbleQuickPayments 
  }
}
