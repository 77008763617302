<template>
  <BCheckoutPrice
    ref="checkoutPrice"
    v-expose="{
      id: 'expose_price_detail:simple'
    }"
    class="checkout-price-wrap"
    :originSortedPrice="sortedPrice"
    :sorted-price-style="checkout.sorted_price_style"
    :total-style="totalStyle"
    :grand-total-title="grandTotalTitle"
    :tax-inclusive-tip="checkout.taxInclusiveTip"
    :save-price-tips="checkout.saved_total_price_text"
    :total-price="totalPrice.amountWithSymbol"
    :gov-tax-price="govTaxPrice"
    scene="checkout"
    :is-show-shipping-fee="isShowShippingFee"
    :is-in-drawer="isInDrawer"
    :dialogBtnText="language.SHEIN_KEY_PWA_15146"
    :drawerBtnText="language.SHEIN_KEY_PWA_29582"
    :addOrderInfo="addOrderInfo"
    @click-shipping-fee-icon="showShippingFee"
    @add-order="handleAddFreeClick"
  />
</template>

<script setup name="CheckoutPriceDetail">
import { vExpose } from 'public/src/pages/common/analysis/directive'
import {
  ref,
  computed,
  onMounted,
  onUnmounted,
  getCurrentInstance,
  watch,
} from 'vue'

import { BCheckoutPrice } from '@shein-aidc/bs-checkout-price-mobile'
import { daEventCenter } from '@shein-aidc/basis-sa-event-center'

import { prefixCls } from 'public/src/pages/checkout_new/pages/footer/utils/index.js'
import useIsClient from '@/public/src/pages/checkout_new/hooks/useIsClient.js'
import useAddOrder from '@/public/src/pages/checkout_new/hooks/useAddOrder.js'

import {
  useMapState,
  useMapMutations,
  useMapGetters
} from '@/public/src/pages/checkout_new/hooks/store'

const instance = getCurrentInstance()
const { isClient } = useIsClient()

const isMounted = ref(false)
const observer = ref(null)
const observerDom = ref(null)
const checkoutPrice = ref(null)

const {
  abtState,
  cartsInfo,
  checkout,
  language,
  isPagePriceTotalShowInView,
  paymentState
} = useMapState([
  'abtState',
  'cartsInfo',
  'checkout',
  'language',
  'isPagePriceTotalShowInView',
  'paymentState'
])

const props = defineProps({
  isInDrawer: {
    type: Boolean,
    default: false
  }
})

const priceDetailAddData = computed(() => checkout.value?.price_detail_free_shipping_add_item || {})

const { handleAddFreeOpen } = useAddOrder({
  mallCode: priceDetailAddData.value.mall_code,
  transportType: priceDetailAddData.value?.transport_type,
  isInPriceDetail: true,
})

const isShowShippingFee = computed(() => {
  return checkout.value?.freight_free_info?.show_in_price_detail == 1
})

// total区分ab展示不同样式（br站点仅展示老样式，可通过判断sorted_price_style === 0直接走老样式）
const totalStyle = computed(() => {
  return (
    checkout.value?.sorted_price_style == 1 &&
    abtState.value.orderConfirmDiscountSwitch
  )
})

const sortedPrice = computed(() => {
  return [
    ...(checkout.value?.sorted_price || []),
    ...(paymentState.value?.appendSortedPrice || [])
  ]
})

const grandTotalTitle = computed(() => {
  const taxInclusiveTip = checkout.value.taxInclusiveTip
  if (abtState.value.placeOrderStatusMove) {
    return !!taxInclusiveTip
      ? language.value?.SHEIN_KEY_PWA_27333
      : language.value?.SHEIN_KEY_PWA_29795
  }
  return language.value?.SHEIN_KEY_PWA_27333
})

const totalPrice = computed(() => {
  return checkout.value?.total_price_info?.grandTotalPrice ?? {}
})

const govTaxPrice = computed(() => {
  return checkout.value?.extraTaxInfo?.taxPriceAmount
})

const getAddItemAnalyzeData = computed(() => {
  const priceDetailAddData = checkout.value?.price_detail_free_shipping_add_item || {}

  return {
    class: priceDetailAddData?.add_item_type == 1 ? 'coupon' : priceDetailAddData?.add_item_type == 2 ? 'activity' : '-',
    diff_price: priceDetailAddData?.diffPrice?.amountWithSymbol || '-',
    shipping_method: priceDetailAddData?.transport_type || '-',
  }
})

const isSingleMall = computed(() => {
  return !cartsInfo.value?.is_multi_mall
})

const addOrderInfo = computed(() => {
  const { tip = '', replaceText = [] } = checkout.value?.price_detail_free_shipping_add_item?.show_tip || {}

  return {
    isShow: abtState.value?.addFreeShippingInPriceDetail && !!tip && isSingleMall.value,
    btnText: language.value?.SHEIN_KEY_PWA_29778 || '',
    showTip: { tip, replaceText },
    analyzeData: getAddItemAnalyzeData.value,
  }
})

const { changePagePriceTotalShowInView, updateCurrentFreightFreePopup } = useMapMutations([
  'changePagePriceTotalShowInView',
  'updateCurrentFreightFreePopup'
])

const { fullShippingMethodsSelectedInfo } = useMapGetters([
  'fullShippingMethodsSelectedInfo'
])

const showShippingFee = () => {
  updateCurrentFreightFreePopup({
    selectedInfo: fullShippingMethodsSelectedInfo.value
  })
}

const handleAddFreeClick = async () => {
  daEventCenter.triggerNotice({
    id: 'click_price_add_shipping:simple',
    data: getAddItemAnalyzeData.value
  })
  handleAddFreeOpen()
}

const addBreathEffect = () => {
  if (!isMounted.value || props.isInDrawer) return

  UnObserve()
  observerDom.value?.classList?.add('breath-effect')

  setTimeout(() => {
    addObserve()
  }, 1100)
}

const addObserve = () => {
  observerDom.value = instance?.proxy?.$refs?.checkoutPrice?.totalRightPriceWrapperRef

  // 仅当observedElement已经被定义时设置观察器
  if (
    abtState.value.placeOrderStatusMove &&
    observerDom.value &&
    !observer.value
  ) {
    observer.value = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          const isPagePriceTotalShowInView = entry.isIntersecting
          changePagePriceTotalShowInView(isPagePriceTotalShowInView)
        })
      },
      {
        rootMargin: `0px 0px -${getFooterWrapperHeight()}px 0px`,
        threshold: 1
      }
    )
    observer.value.observe(observerDom.value)
  }
}

const UnObserve = () => {
  if (observerDom.value) {
    observer.value?.unobserve(observerDom.value)
    observer.value = null
  }
}

const getFooterWrapperHeight = () => {
  const height = document.getElementById(prefixCls('wrapper'))
  // footer设计稿高度
  const defaultFooterHeight = 56
  return isClient.value ? height?.clientHeight || defaultFooterHeight : defaultFooterHeight
}

watch(
  () => isPagePriceTotalShowInView.value,
  (n, o) => {
    if (n != o && n) addBreathEffect()
  }
)

onMounted(() => {
  isMounted.value = true
  if (!props.isInDrawer) addObserve()
})

// 在组件卸载前断开观察，防止内存泄漏
onUnmounted(() => {
  UnObserve()
})
</script>

<style lang="less">
.breath-effect {
  animation: breathe-frames 0.5s ease-in-out 2;
}

@keyframes breathe-frames {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
</style>
