<template>
  <div
    :class="hitsize"
    :style="{ maxWidth: maxrem + 'rem', fontSize: fontSize }"
  >
    <span
      v-html="text"
    ></span>
    <div
      v-if="computedEndFlag"
      class="virtual"
    >
      <template v-for="(item, index) in maxNumber">
        <span
          v-if="item >= source[0]"
          :key="index"
          :ref="`f${item}`"
          :class="`f${item}`"
          :style="{ fontSize: `${item}px` }"
          v-html="text"
        >
        </span>
      </template>
    </div>
  </div>
</template>
<script>
import { defineComponent, nextTick } from 'vue'
export default defineComponent({
  name: 'AdaptText',
  props: {
    text: {
      default: '',
      type: String
    },
    maxrem: {
      default: 1,
      type: Number
    },
    source: {
      type: Array,
      default: () => [12, 16]
    }
  },
  data() {
    return {
      hitsize: '',
      fontSize: '',
      computedEndFlag: true,
    }
  },
  computed: {
    maxNumber () {
      return Object.keys(Array.from(Array(this.source[1] + 1))) || []
    },
  },
  watch: {
    text() {
      nextTick(() => this.calsize())
    }
  },
  mounted() {
    this.rem = parseFloat(document.querySelector('html').style.fontSize)
    this.calsize()
  },
  methods: {
    calsize() {
      const keys = Object.keys(this.$refs).reverse()
      this.hitsize = `f${this.source[0]} oversize`
      this.fontSize = `${+keys[0].replace('f', '')}px`
      for (var i = 0; i < keys.length; i++) {
        const el = this.$refs[keys[i]]?.[0]
        if (el.offsetWidth < this.maxrem * this.rem) {
          this.hitsize = keys[i]
          this.fontSize = `${+keys[i].replace('f', '')}px`
          break
        }
        this.fontSize = `${+keys[i].replace('f', '')}px`
      }
      this.computedEndFlag = false
    }
  },
})
</script>
<style scoped>
.virtual {
    visibility: hidden;
    position: absolute;
    width: 10000px;
}
.oversize {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
</style>
