import { SILogger } from 'public/src/pages/common/monitor/index.js'
import {
  C2pCheckoutService,
  WEB_DPA_LOCALE_MAP
} from '@shein-aidc/bs-sdk-click-to-pay'
import { nextTick, ref } from 'vue'
import UserInfoManager from 'public/src/services/UserInfoManager/UserInfoManager.js'
import { daEventCenter } from '@shein-aidc/basis-sa-event-center'
import { useRequestTime } from './useRequestTime.js'
import schttp from 'public/src/services/schttp'
import { useMapMutations } from '@/public/src/pages/checkout_new/hooks/store'

const IS_CLIENT = typeof window !== 'undefined'
const gbCommonInfo = (IS_CLIENT && window?.gbCommonInfo) ?? {}

let isDebug = ['localhost', 'debug'].includes(typeof window === 'undefined' ? '' : gbCommonInfo?.NODE_SERVER_ENV)

// let mockAccountMap = new Map()

const c2pCheckoutService = typeof window !== 'undefined' ? new C2pCheckoutService() : null


const { host, langPath } =
  gbCommonInfo

export const useC2p = () => {
  if (IS_CLIENT && sessionStorage?.getItem?.('__c2p_debug')) {
    isDebug = true
  }

  const { 
    assignPaymentState,
    changeCheckoutLoading
  } = useMapMutations([
    'assignPaymentState',
    'changeCheckoutLoading'
  ])

  const c2pIframeRef = ref(null)
  const visibleC2pCheckoutDrawer = ref(false)
  const isShowOtpValidateDrawer = ref(false)
  const rememberMe = ref(false)
  const c2pTokenList = ref([])
  const isShowBSrcRequestTimeOutDialog = ref(false)
  const tempHandlerC2pTokenCheck = ref(null)
  const signOutFetchLoading = ref(false)
  const handleStartCheckoutWithCardIsTimeout = ref(false)
  const tempOrderInfo = ref({})
  const isInitC2pSuccess = ref(false)
  const c2pAvailableCardBrands = ref([])
  const isNoShowBSrcTokenHeader = ref(false)

  const { startTiming, endTiming, isTimeout, duration } = useRequestTime({
    apolloKeys: 'C2P_CHECKOUT_WITH_CARD_LIMITE_TIME'
  })

  // const handleStartCheckoutWithCardIsTimeout = computed(() => {
  //   return true || isTimeout.value
  // })

  const getInitUserInfo = () => {
    const email = UserInfoManager.getSync({ key: 'pwa_user_email', actionType: 'hooks/useTokenPay' }) || ''
    // console.log('c2p-log---getUserEmail--userInfo===', email)
    return {
      email
    }
  }

  const init = async () => {
    const initStart = Date.now()
    const initRes = await c2pCheckoutService.init({
      dpaName: 'SHEIN',
      dpaPresentationName: 'SHEIN',
      amount: 0,
      currencyCode: 'USD',
      cardBrands: ['mastercard', 'visa'],
      dpaLocale: WEB_DPA_LOCALE_MAP[gbCommonInfo?.lang] || '',
    })

    SILogger.logInfo({
      tag: 'c2p_sdk_manager',
      params: {
        scene: initRes?.stauts !== 'success' ? 'initC2p_error' : 'initC2p_success',
        initTime: Date.now() - initStart,
        message: initRes?.message || '',
        errorDetails: initRes?.errorDetails || {},
        traceId: c2pCheckoutService.traceId,
        url: window.location.href,
      },
    })
    return initRes
  }

  const getCards = async () => {
    try {
      const startTime = Date.now()
      const cardsRes = await c2pCheckoutService.getCards()
      if (isDebug) {
        SILogger.logInfo({
          tag: 'c2p_sdk_manager',
          params: {
            status: cardsRes?.status,
            errorDetails: cardsRes?.errorDetails || {},
            scene: 'getCards_res',
            cardsCount: cardsRes?.info?.length,
            loadTime: Date.now() - startTime,
            traceId: c2pCheckoutService.traceId,
            url: window.location.href,
          },
        })
      }
      return cardsRes
    } catch (e) {
      SILogger.logInfo({
        tag: 'c2p_sdk_manager',
        params: {
          scene: 'getCards_error',
          details: e?.details || {},
          traceId: c2pCheckoutService.traceId,
          url: window.location.href,
        },
      })
      return { status: 'fail', info: [] }
    }
  }

  /**
   * 
   * @returns {Promise<{ status: string, errorDetails?: any; message?: string; info?: object }>}
   * status: 'success' | 'fail', // success后续才能进行c2p流程
   * errorDetails?: any,
   * message?: string,
   * info?: { availableCardBrands: string[] } // 可用的卡种类型，用于前端判断是否可以进行c2p流程
   */
  const initC2p = async () => {
    await window.TPM?.run?.({
      marketing: 'InitChannelSdk',
      method: '_initC2pSdk'
    })
    const initRes = await init()

    isInitC2pSuccess.value = initRes.status === 'success'
    c2pAvailableCardBrands.value = initRes?.info?.availableCardBrands || []

    return {
      status: initRes.status,
      errorDetails: initRes.errorDetails || {},
      info: initRes?.info || {},
      message: initRes.message
    }
  }

  async function getCardTokenList(nativeParams = {}) {
    // console.log('c2p-log---getCardTokenList--nativeParams===', nativeParams)
    const { phone = '', area_code = '', email = '' } = nativeParams || {}
    const stateTime = Date.now()
    SILogger.logInfo({
      tag: 'c2p_sdk_manager',
      params: {
        scene: 'getCardTokenList_req',
        params: nativeParams,
        isReady: typeof MastercardCheckoutServices !== 'undefined' ? '1' : '0',
        traceId: c2pCheckoutService.traceId,
        url: window.location.href,
      },
    })
    try {
      let idLookupInfo = null
      const idLookupParams = {
        email,
        area_code,
        phone
      }
      const [
        { value: cards = [] },
        { value: idLookupRes },
      ] = await Promise.allSettled([
        getCards().then(res => {
          if (res?.status !== 'success') {
            SILogger.logInfo({
              tag: 'c2p_sdk_manager',
              params: {
                scene: 'getCardTokenList_error_getCards',
                details: res?.errorDetails || {},
                reason: res?.message || '',
                loadTime: Date.now() - stateTime,
                traceId: c2pCheckoutService.traceId,
                url: window.location.href,
              },
            })
          }
          if (isDebug) {
            SILogger.logInfo({
              tag: 'c2p_sdk_manager',
              params: {
                scene: 'getCardTokenList_getCards_res',
                sdkRes: res,
                details: res?.errorDetails || {},
                reason: res?.message || '',
                loadTime: Date.now() - stateTime,
                traceId: c2pCheckoutService.traceId,
                url: window.location.href,
              },
            })
          }
          // mock
          // if (email === 'c2ptest508@mailnator.com') {
          //   return (res?.info || []).map(v => ({ ...v, status: 'EXPIRED' }))
          // }
          // if (email === 'c2ptest508@mailnator.com' && res?.info?.length > 1) {
          //   const cards = res?.info || []
          //   for(let i = 0; i < cards.length; i++) {
          //     if (i === (cards.length - 1)) {
          //       cards[i].status = 'EXPIRED'
          //     }
          //   }
          //   return cards
          // }
          return res?.info || []
        }),
        (email || (phone && area_code)) ?
          c2pCheckoutService.idLookup(idLookupParams).then(res => {
            // idLookupInfo = res?.info || {}
            if (res?.status !== 'success') {
              // console.log('c2p-log---getCardTokenList--idLookup_error===', res)
              SILogger.logInfo({
                tag: 'c2p_sdk_manager',
                params: {
                  scene: 'getCardTokenList_error_idLookup',
                  params: idLookupParams,
                  details: res?.errorDetails || {},
                  reason: res?.message || '',
                  loadTime: Date.now() - stateTime,
                  traceId: c2pCheckoutService.traceId,
                  url: window.location.href,
                },
              })
            }
            if (isDebug) {
              SILogger.logInfo({
                tag: 'c2p_sdk_manager',
                params: {
                  scene: 'getCardTokenList_idLookup_res',
                  sdkRes: res,
                  params: idLookupParams,
                  details: res?.errorDetails || {},
                  reason: res?.message || '',
                  loadTime: Date.now() - stateTime,
                  traceId: c2pCheckoutService.traceId,
                  url: window.location.href,
                },
              })
            }
            return res
          }) :
          Promise.resolve({})
      ])
      // console.log('c2p-log---cards--re===', cards, { idLookupRes })
      idLookupInfo = idLookupRes?.info || {}
      const res = {
        tokenList: cards || [],
        consumerPresent: idLookupInfo?.consumerPresent ? '1' : '0',
      }

      SILogger.logInfo({
        tag: 'c2p_sdk_manager',
        params: {
          scene: 'getCardTokenList_res',
          result: res,
          nativeParams,
          loadTime: Date.now() - stateTime,
          traceId: c2pCheckoutService.traceId,
          url: window.location.href,
        },
      })
      // mockAccountMap.set(email, {
      //   baseCards: res?.info || [],
      //   valCards: []
      // })

      return res
    } catch (e) {
      console.error('c2p-log---getCardTokenList--error===', e)

      SILogger.logInfo({
        tag: 'c2p_sdk_manager',
        params: {
          scene: 'getCardTokenList_error',
          details: e?.details || {},
          reason: e?.reason || '',
          loadTime: Date.now() - stateTime,
          traceId: c2pCheckoutService.traceId,
          url: window.location.href,
        },
      })
    }

    return {
      tokenList: [],
      consumerPresent: '0',
    }

  }

  /**
   * 使用卡token进行结账
   *
   * @param {*} [nativeParams={
   *  srcDigitalCardId: '',
   * rememberMe: false,
   * amount: 0,
   * currency: 'USD',
   * }]
   * @return {*} 
   */
  const checkoutWithCard = async (nativeParams = {}) => {
    // console.log('c2p-log---checkoutWithCard--nativeParams===', nativeParams)
    const stateTime = Date.now()
    if (!nativeParams?.srcDigitalCardId) {
      console.error('c2p-log---checkoutWithCard--srcDigitalCardId is required')
      SILogger.logInfo({
        tag: 'c2p_sdk_manager',
        params: {
          scene: 'checkoutWithCard_error_srcDigitalCardId_required',
          url: window.location.href,
        },
      })
      return {
        fail_tips: 'srcDigitalCardId is required',
        status: 'fail'
      }
    }

    try {
      const params = {
        srcDigitalCardId: nativeParams.srcDigitalCardId,
        windowRef: c2pIframeRef.value.contentWindow,
        rememberMe: nativeParams.rememberMe,
        recognitionTokenRequested: !!nativeParams.rememberMe,
        amount: nativeParams.amount,
        currency: nativeParams.currency,
      }

      const checkoutInfo = await c2pCheckoutService.checkoutWithCard(params)
      const { status, info = {}, message, errorDetails } = checkoutInfo || {}

      // console.log('c2p-log---checkoutWithCard--re===', checkoutInfo)

      SILogger.logInfo({
        tag: 'c2p_sdk_manager',
        params: {
          scene: status === 'success' ? 'checkoutWithCard_res_success' : 'checkoutWithCard_res_error',
          checkoutActionCode: info?.originData?.checkoutActionCode || '',
          params: {
            srcDigitalCardId: nativeParams.srcDigitalCardId,
            rememberMe: nativeParams.rememberMe,
            amount: nativeParams.amount ? 'is exist' : 'not exist',
            currency: nativeParams.currency ? 'is exist' : 'not exist',
          },
          status,
          details: errorDetails || {},
          loadTime: Date.now() - stateTime,
          traceId: c2pCheckoutService.traceId,
          url: window.location.href,
        },
      })

      if (isDebug) {
        SILogger.logInfo({
          tag: 'c2p_sdk_manager',
          params: {
            scene: 'checkoutWithCard_res',
            checkoutActionCode: info?.originData?.checkoutActionCode || '',
            params: {
              srcDigitalCardId: nativeParams.srcDigitalCardId,
              rememberMe: rememberMe,
              amount: nativeParams.amount,
              currency: nativeParams.currency,
            },
            sdkRes: info?.originData || {},
            status,
            details: errorDetails || {},
            loadTime: Date.now() - stateTime,
            traceId: c2pCheckoutService.traceId,
            url: window.location.href,
          },
        })
      }

      return {
        status,
        data: info?.data || {},
        fail_tips: status !== 'success' ? message : '',
      }

    } catch (e) {
      SILogger.logInfo({
        tag: 'c2p_sdk_manager',
        params: {
          scene: 'checkoutWithCard_res_fail',
          params: {
            srcDigitalCardId: nativeParams.srcDigitalCardId,
            rememberMe: rememberMe,
            amount: nativeParams.amount ? 'is exist' : 'not exist',
            currency: nativeParams.currency ? 'is exist' : 'not exist',
          },
          loadTime: Date.now() - stateTime,
          traceId: c2pCheckoutService.traceId,
          url: window.location.href,
        },
      })
      return {
        fail_tips: e?.message || 'checkoutWithCard error',
        status: 'fail',
        errorDetails: e?.details || {},
      }
    }
  }

  async function checkoutWithNewCard(nativeParams = {}) {
    // console.log('c2p-log---checkoutWithNewCard--nativeParams===', nativeParams)
    const {
      primaryAccountNumber,
      panExpirationMonth,
      panExpirationYear,
      cardSecurityCode,
      rememberMe,
    } = nativeParams || {}
    const stateTime = Date.now()
    const checkParams = ['primaryAccountNumber', 'panExpirationMonth', 'panExpirationYear', 'cardSecurityCode']
    if (!primaryAccountNumber || !panExpirationMonth || !panExpirationYear || !cardSecurityCode) {
      const fail_tips = checkParams.filter(key => !nativeParams[key]).map(key => `${key} is required`).join(', ')
      SILogger.logInfo({
        tag: 'c2p_sdk_manager',
        params: {
          scene: 'checkoutWithNewCard_error_cardInfo_required',
          loadTime: Date.now() - stateTime,
          params: {
            primaryAccountNumber: primaryAccountNumber ? 'is exist' : 'not exist',
            panExpirationMonth: panExpirationMonth ? 'is exist' : 'not exist',
            panExpirationYear: panExpirationYear ? 'is exist' : 'not exist',
            cardSecurityCode: cardSecurityCode ? 'is exist' : 'not exist',
          },
          fail_tips,
          traceId: c2pCheckoutService.traceId,
          url: window.location.href,
        },
      })
      return {
        fail_tips: fail_tips,
        status: 'fail'
      }
    }

    let encryptedCardInfo = {}
    const encryptedCardRes = await c2pCheckoutService.encryptCard({
      primaryAccountNumber,
      panExpirationMonth,
      panExpirationYear,
      cardSecurityCode
    })
    encryptedCardInfo = encryptedCardRes?.info || {}
    if (isDebug) {
      SILogger.logInfo({
        tag: 'c2p_sdk_manager',
        params: {
          scene: 'checkoutWithNewCard_encryptCard_res',
          details: encryptedCardRes?.errorDetails || {},
          message: encryptedCardRes?.message || '',
          status: encryptedCardRes?.status,
          sdkRes: {
            cardBrand: encryptedCardInfo?.cardBrand,
            encryptedCard: encryptedCardInfo?.encryptedCard ? 'is exist encrypt' : 'not exist encrypt',
            ...(encryptedCardRes.status !== 'success' ? { ...encryptedCardRes } : {})
          },
          params: {
            primaryAccountNumber,
            panExpirationMonth,
            panExpirationYear,
            cardSecurityCode
          },
          loadTime: Date.now() - stateTime,
          traceId: c2pCheckoutService.traceId,
          url: window.location.href,
        },
      })
    }
    if (encryptedCardRes.status !== 'success') {
      SILogger.logInfo({
        tag: 'c2p_sdk_manager',
        params: {
          scene: 'checkoutWithNewCard_error_encryptCard',
          details: encryptedCardRes?.errorDetails || {},
          message: encryptedCardRes?.message || '',
          loadTime: Date.now() - stateTime,
          params: {
            primaryAccountNumber: primaryAccountNumber ? 'is exist' : 'not exist',
            panExpirationMonth: panExpirationMonth ? 'is exist' : 'not exist',
            panExpirationYear: panExpirationYear ? 'is exist' : 'not exist',
            cardSecurityCode: cardSecurityCode ? 'is exist' : 'not exist',
          },
          traceId: c2pCheckoutService.traceId,
          url: window.location.href,
        },
      })
      return {
        fail_tips: 'encryptCard error',
        status: 'fail',
        errorDetails: encryptedCardInfo?.errorDetails || {},
      }
    }

    const params = {
      encryptedCard: encryptedCardInfo.encryptedCard || '',
      cardBrand: encryptedCardInfo.cardBrand || '',
      windowRef: c2pIframeRef.value.contentWindow,
      rememberMe: rememberMe == 1,
      recognitionTokenRequested: rememberMe == 1,
      email: nativeParams.email,
      phone: nativeParams.phone,
      area_code: nativeParams.area_code,
      amount: nativeParams.amount,
      currency: nativeParams.currency,
    }

    try {
      const checkoutInfo = await c2pCheckoutService.checkoutWithNewCard(params)
      const { status, info = {}, message, errorDetails } = checkoutInfo || {}

      // console.log('c2p-log---checkoutWithNewCard--re===', checkoutInfo)

      SILogger.logInfo({
        tag: 'c2p_sdk_manager',
        params: {
          scene: status === 'success' ? 'checkoutWithNewCard_res_success' : 'checkoutWithNewCard_res_error',
          status,
          message,
          errorDetails: errorDetails || {},
          checkoutActionCode: info?.originData?.checkoutActionCode || '',
          originData: {
            network: info?.originData?.network || '',
            checkoutResponse: info?.originData?.checkoutResponse ? 'is exist' : 'not exist',
          },
          params: {
            encryptedCard: encryptedCardInfo.encryptedCard ? 'is exist encrypt' : 'not exist encrypt',
            cardBrand: encryptedCardInfo.cardBrand || '',
            rememberMe: rememberMe == 1,
            email: nativeParams.email ? 'is exist' : 'not exist',
            phone: nativeParams.phone ? 'is exist' : 'not exist',
            area_code: nativeParams.area_code ? 'is exist' : 'not exist',
            amount: nativeParams.amount ? 'is exist' : 'not exist',
            currency: nativeParams.currency ? 'is exist' : 'not exist',
          },
          loadTime: Date.now() - stateTime,
          traceId: c2pCheckoutService.traceId,
          url: window.location.href,
        },
      })
      if (isDebug) {
        SILogger.logInfo({
          tag: 'c2p_sdk_manager',
          params: {
            scene: 'checkoutWithNewCard_res',
            status,
            message,
            errorDetails: errorDetails || {},
            sdkRes: info?.originData || {},
            params: {
              encryptedCard: encryptedCardInfo.encryptedCard ? 'is exist encrypt' : 'not exist encrypt',
              cardBrand: encryptedCardInfo.cardBrand || '',
              rememberMe: rememberMe == 1,
              email: nativeParams.email,
              phone: nativeParams.phone,
              area_code: nativeParams.area_code,
              amount: nativeParams.amount,
              currency: nativeParams.currency,
            },
            loadTime: Date.now() - stateTime,
            traceId: c2pCheckoutService.traceId,
            url: window.location.href,
          },
        })
      }

      return {
        status,
        data: info?.data || {},
        fail_tips: status !== 'success' ? message : '',
      }

    } catch (e) {
      SILogger.logInfo({
        tag: 'c2p_sdk_manager',
        params: {
          scene: 'checkoutWithNewCard_res_fail',
          message: e?.message,
          params: {
            rememberMe: rememberMe == 1,
            email: nativeParams.email ? 'is exist' : 'not exist',
            phone: nativeParams.phone ? 'is exist' : 'not exist',
            area_code: nativeParams.area_code ? 'is exist' : 'not exist',
            amount: nativeParams.amount ? 'is exist' : 'not exist',
            currency: nativeParams.currency ? 'is exist' : 'not exist',
          },
          loadTime: Date.now() - stateTime,
          traceId: c2pCheckoutService.traceId,
          url: window.location.href,
        },
      })
      return {
        fail_tips: e?.message || 'checkoutWithCard error',
        status: 'fail',
      }
    }
  }

  /**
   * 在idLookup（）请求成功之后，该方法将通过与配置文件关联的通道发起持卡人身份验证OTP请求。
   * 
   * 第一次调用此方法时，请省略请求的validationChannelid，因为该服务将没有验证Channelid的值。
     服务器将向持卡人配置文件中定义的首选（或默认）验证通道发送OTP消息。
     响应有效载荷可以包含其他选项的支持validationChannels阵列。如果是这样，则应向持卡人显示MaskEdvalidationChannel值的列表，并允许他们为OTP选择替代通道。
     如果他们从列表中进行选择，请从第一个响应中获取对应于所选蒙版的ValidationChannel的验证Channelid。
     然后，使用验证Channelid值作为请求的ValidationChannelId参数进行第二次调用。
   */
  const initiateValidation = async (params) => {
    const stateTime = Date.now()
    try {
      const res = await c2pCheckoutService.initiateValidation(params)
      if (res?.status !== 'success') {
        isNoShowBSrcTokenHeader.value = res?.errorDetails?.reason === 'UNKNOWN_ERROR'
        SILogger.logInfo({
          tag: 'c2p_sdk_manager',
          params: {
            scene: 'initiateValidation_error',
            requestedValidationChannelId: params?.requestedValidationChannelId || '',
            details: res?.errorDetails || {},
            reason: res?.message || '',
            loadTime: Date.now() - stateTime,
            traceId: c2pCheckoutService.traceId,
            url: window.location.href,
          },
        })
        // loading兜底处理
        changeCheckoutLoading({ config: { loading: true }, setLoading: false })
      }
      if (isDebug) {
        SILogger.logInfo({
          tag: 'c2p_sdk_manager',
          params: {
            scene: 'initiateValidation_res',
            sdkRes: res,
            requestedValidationChannelId: params?.requestedValidationChannelId || '',
            details: res?.errorDetails || {},
            reason: res?.message || '',
            loadTime: Date.now() - stateTime,
            traceId: c2pCheckoutService.traceId,
            url: window.location.href,
          },
        })
      }
      return res?.info || {}
    } catch (e) {
      SILogger.logInfo({
        tag: 'c2p_sdk_manager',
        params: {
          scene: 'initiateValidation_error',
          requestedValidationChannelId: params?.requestedValidationChannelId || '',
          details: e?.details || {},
          reason: e?.reason || '',
          loadTime: Date.now() - stateTime,
          traceId: c2pCheckoutService.traceId,
          url: window.location.href,
        },
      })
      return {}
    }
  }

  const handlerValidateCb = async ({
    res,
    cb,
    close,
  } = {}) => {
    // console.log('c2p-log---handlerValidateCb--res===', res)
    if (res?.reason === 'ACCT_INACCESSIBLE') {
      close()
      await resetInitC2P()
      showOtpValidateDrawer()
    } else if (['CODE_INVALID', 'INVALID_PARAMETER'].includes(res?.reason)) {
      cb?.()
    } else {
      close()
    }
  }

  /**
   * 验证持卡人输入的OTP，6位数
   *
   * @param {*} { value }
   * @return {{ status: string, fail_tips?: string, errorDetails?: any; tokenList?: any[] }} {
   *  status: 'success' | 'fail' | 'cancel',
   * fail_tips?: string,
   * errorDetails?: any,
   * tokenList?: any[],
   * }
   */
  const validate = async ({ value }) => {
    const stateTime = Date.now()
    try {
      const res = await c2pCheckoutService.validate({ value })
      // console.log('c2p-log---validate--re===', res)
      daEventCenter.triggerNotice({
        id: 'expose_receive_code:simple',
        data: {
          send_result: res?.status === 'success' ? 'success' : 'failure'
        }
      })
      if (res?.status !== 'success') {
        SILogger.logInfo({
          tag: 'c2p_sdk_manager',
          params: {
            scene: 'initiateValidation_error',
            valueExist: value ? '1' : '0',
            details: res?.errorDetails || {},
            reason: res?.message || '',
            loadTime: Date.now() - stateTime,
            traceId: c2pCheckoutService.traceId,
            url: window.location.href,
          },
        })
      }
      if (isDebug) {
        SILogger.logInfo({
          tag: 'c2p_sdk_manager',
          params: {
            scene: 'validate_res',
            valueExist: value ? '1' : '0',
            sdkRes: res,
            details: res?.errorDetails || {},
            reason: res?.message || '',
            loadTime: Date.now() - stateTime,
            traceId: c2pCheckoutService.traceId,
            url: window.location.href,
          },
        })
      }
      // if (res?.info?.length > 1) {
      //   const cards = res?.info || []
      //   for(let i = 0; i < cards.length; i++) {
      //     if (i === (cards.length - 1)) {
      //       cards[i].status = 'EXPIRED'
      //     }
      //   }
      //   res.info = cards
      // }
      // // mock
      // if (mockAccountMap.has('c2ptest508@mailnator.com')) {
      //   res.info = (res?.info || []).map(v => ({ ...v, status: 'EXPIRED' }))
      // }
      return {
        status: res?.status,
        tokenList: res?.info || [],
        fail_tips: res?.message || '',
        reason: res?.errorDetails?.reason || '',
        errorDetails: res?.errorDetails || {},
      }
    } catch (e) {
      SILogger.logInfo({
        tag: 'c2p_sdk_manager',
        params: {
          scene: 'initiateValidation_error',
          valueExist: value ? '1' : '0',
          details: e?.details || {},
          reason: e?.reason || '',
          loadTime: Date.now() - stateTime,
          traceId: c2pCheckoutService.traceId,
          url: window.location.href,
        },
      })
      return {
        status: 'fail',
        fail_tips: e?.message || 'validate error',
        errorDetails: e?.details || {},
        reason: '',
        tokenList: [],
      }
    }
  }

  /**
   * 此方法检查指定的电子邮件地址或手机号码是否存在渠道token，目前用于not your card和getCardTokenList场景
   *
   * @param {*} [nativeParams={}]
   * @return {{ consumerPresent: string }} { consumerPresent: '1' | '0' }
   */
  const idLookup = async (nativeParams = {}) => {
    const { phone, area_code, email } = nativeParams || {}
    const stateTime = Date.now()
    try {
      const idLookupRes = await c2pCheckoutService.idLookup({
        email,
        area_code,
        phone
      })
      if (idLookupRes?.status !== 'success') {
        SILogger.logInfo({
          tag: 'c2p_sdk_manager',
          params: {
            scene: 'initiateValidation_error',
            details: idLookupRes?.errorDetails || {},
            reason: idLookupRes?.message || '',
            nativeParams,
            loadTime: Date.now() - stateTime,
            traceId: c2pCheckoutService.traceId,
            url: window.location.href,
          },
        })
        daEventCenter.triggerNotice({
          id: 'expose_send_code:simple',
          data: {
            send_result: 'failure'
          }
        })
      } else {
        daEventCenter.triggerNotice({
          id: 'expose_send_code:simple',
          data: {
            send_result: 'success'
          }
        })
      }
      if (isDebug) {
        SILogger.logInfo({
          tag: 'c2p_sdk_manager',
          params: {
            scene: 'idLookup_res',
            sdkRes: idLookupRes,
            details: idLookupRes?.errorDetails || {},
            reason: idLookupRes?.message || '',
            nativeParams,
            loadTime: Date.now() - stateTime,
            traceId: c2pCheckoutService.traceId,
            url: window.location.href,
          },
        })
      }
      return { consumerPresent: idLookupRes?.info?.consumerPresent ? '1' : '0' }
    } catch (e) {
      SILogger.logInfo({
        tag: 'c2p_sdk_manager',
        params: {
          scene: 'initiateValidation_error',
          details: e?.details || {},
          reason: e?.reason || '',
          nativeParams,
          loadTime: Date.now() - stateTime,
          traceId: c2pCheckoutService.traceId,
        },
      })
      daEventCenter.triggerNotice({
        id: 'expose_send_code:simple',
        data: {
          send_result: 'failure'
        }
      })
      return { consumerPresent: '0' }
    }
  }

  async function signOut() {
    const stateTime = Date.now()
    try {
      const res = await c2pCheckoutService.signOut()
      if (res?.status !== 'success') {
        SILogger.logInfo({
          tag: 'c2p_sdk_manager',
          params: {
            scene: 'signOut_error',
            details: res?.errorDetails || {},
            reason: res?.message || '',
            loadTime: Date.now() - stateTime,
            traceId: c2pCheckoutService.traceId,
            url: window.location.href,
          },
        })
      }
      if (isDebug) {
        SILogger.logInfo({
          tag: 'c2p_sdk_manager',
          params: {
            scene: 'signOut_res',
            sdkRes: res,
            details: res?.errorDetails || {},
            reason: res?.message || '',
            loadTime: Date.now() - stateTime,
            traceId: c2pCheckoutService.traceId,
            url: window.location.href,
          },
        })
      }
      if (res?.status === 'success') {
        if (res?.info?.unbindSucccess) {
          return {
            status: 'success',
            tokenList: [],
            fail_tips: res?.fail_tips || '',
          }
        } else {
          const res = {
            status: res?.info?.tokenList.length ? 'error' : 'success',
            fail_tips: res?.fail_tips || '',
          }
          if (res?.info?.tokenList.length) {
            res.tokenList = res?.info?.tokenList
          }
          return res
        }
      } else {
        return {
          status: res?.status,
          fail_tips: res?.message || '',
        }
      }
    } catch (e) {
      SILogger.logInfo({
        tag: 'c2p_sdk_manager',
        params: {
          scene: 'signOut_error',
          details: e?.details || {},
          reason: e?.reason || '',
          loadTime: Date.now() - stateTime,
          traceId: c2pCheckoutService.traceId,
          url: window.location.href,
        },
      })
      return {
        status: 'error',
        fail_tips: e?.message || 'signOut error',
      }
    }
  }

  const handleNotYou = async (cb) => {
    signOutFetchLoading.value = true
    const signOutRes = await signOut()
    // console.log('c2p-log---handleNotYou--signOutRes===', signOutRes)
    if (signOutRes?.status === 'success') {
      await resetInitC2P()
      showOtpValidateDrawer()
      cb?.()
    }
    signOutFetchLoading.value = false
    if (signOutRes?.tokenList) {
      c2pTokenList.value = signOutRes?.tokenList
    }
  }

  async function resetInitC2P() {
    const initStart = Date.now()
    const res = await init()
    let cards = []
    if (res.status === 'success') {
      const cardsRes = await getCards()
      cards = cardsRes?.info || []
    }
    if (isDebug) {
      SILogger.logInfo({
        tag: 'c2p_sdk_manager',
        params: {
          scene: 'resetInitC2P',
          initTime: Date.now() - initStart,
          status: res?.status,
          sdkRes: res,
          errorDetails: res?.errorDetails || {},
          url: window.location.href,
        },
      })
    }
    return {
      status: res?.status,
      tokenList: cards,
      fail_tips: res?.message || '',
    }
  }



  const handleStartCheckoutWithCard = async (nativeParams) => {
    // console.log('c2p-log---handleStartCheckoutWithCard--nativeParams===', nativeParams)
    const { srcDigitalCardId } = nativeParams || {}
    if (!srcDigitalCardId) {
      // console.error('c2p-log---handleStartCheckoutWithCard--srcDigitalCardId is required')
      SILogger.logInfo({
        tag: 'c2p_sdk_manager',
        params: {
          scene: 'srcDigitalCardId_required',
        },
      })
      return
    }

    startTiming()
    visibleC2pCheckoutDrawer.value = true
    Object.assign(nativeParams, { rememberMe: rememberMe.value })
    await nextTick()
    const checkoutRes = await checkoutWithCard(nativeParams)

    visibleC2pCheckoutDrawer.value = false

    if (checkoutRes?.status === 'success') {
      // console.log('c2p-log---handleStartCheckoutWithCard--success===', checkoutRes)
    }

    endTiming()

    handleStartCheckoutWithCardIsTimeout.value = isTimeout.value || checkoutRes?.status !== 'success'
    return {
      checkoutRes,
      isTimeout: isTimeout.value,
      duration: duration.value,
    }
  }

  const showOtpValidateDrawer = () => {
    isShowOtpValidateDrawer.value = true
  }

  const closeOtpValidateDrawer = () => {
    isShowOtpValidateDrawer.value = false
  }

  const setRememberMe = (isRememberMe) => {
    rememberMe.value = isRememberMe
  }

  const setC2pTokenList = (tokenList) => {
    c2pTokenList.value = tokenList || []
  }

  const setIsShowBSrcRequestTimeOutDialog = (isShow) => {
    isShowBSrcRequestTimeOutDialog.value = isShow
  }

  const setHandlerC2pTokenCheck = (handlerFn) => {
    tempHandlerC2pTokenCheck.value = handlerFn
  }

  const handleC2pTokenCheck = () => {
    // 将原本默认选中的路由卡支付方式替换为c2p 返回的第一个token
    if (c2pTokenList.value?.length > 0) {
      tempHandlerC2pTokenCheck.value?.(c2pTokenList.value[0])
    }
  }

  const getIsHadSheinToken = ({ checkout }) => {
    return (checkout?.payment_info?.payments || [])?.find(item =>
      item.code === 'routepay-card' &&
      item?.card_token_list?.length > 0
    )
  }

  const setHandleStartCheckoutWithCardIsTimeout = (val) => {
    handleStartCheckoutWithCardIsTimeout.value = val
  }

  const setPayOrderInfo = (res) => {
    tempOrderInfo.value = res
  }

  const toOrderDetail = () => {
    window.location.href =
      host + langPath + '/user/orders/detail/' + tempOrderInfo.value?.billno
  }

  const getAllCountryList = async (cb) => {
    console.log('getAllCountryList >>>>', getAllCountryList)
    const result = await schttp({
      method: 'get',
      useBffApi: true,
      url: '/user/address/get_country_site_list'
    })
    console.log('@@@ getAllCountryList>>>>', result)
    if (result?.code == 0) {
      cb && cb(result?.info?.country?.item_cates || [])
    }
  }

  const phoneValidateFn = async (nowParams, cb) => {
    console.log('@@@ phoneValidateFn>>>>', nowParams)
    if (Object.keys(nowParams || {}).length === 0) return
    const result = await schttp({
      method: 'post',
      url: '/api/auth/phoneValidate/get',
      data: nowParams
    })
    console.log('@@@ phoneValidateFn>>>>', nowParams, result)
    cb && cb(result)
  }

  const handlePhoneEmailConfirmSubmit = ({
    val,
    cb
  }) => {
    console.log('@@@ [handlePhoneEmailConfirmSubmit] val>>>', val)
    assignPaymentState({ isShowBSrcPhoneEmailConfirmDialog: false })
    assignPaymentState({ c2pPhoneAndEmailInfo: {
      phone: val?.phone || '',
      email: val?.email || '',
      area_code: val?.area_code || ''
    } })
    cb?.()
  }

  const closeBSrcPhoneEmailConfirmDialog = () => {
    assignPaymentState({ isShowBSrcPhoneEmailConfirmDialog: false })
  }

  const handleStartCheckoutWithNewCard = async nativeParams => {
    console.log(
      'c2p-log---handleStartCheckoutWithNewCard--nativeParams===',
      nativeParams
    )
    startTiming()
    visibleC2pCheckoutDrawer.value = true
    await nextTick()
    const checkoutRes = await checkoutWithNewCard(nativeParams)

    visibleC2pCheckoutDrawer.value = false

    if (checkoutRes?.status === 'success') {
      console.log(
        'c2p-log---handleStartCheckoutWithCard--success===',
        checkoutRes
      )
    }

    endTiming()
    // handleStartCheckoutWithCardIsTimeout.value = isTimeout.value
    return {
      checkoutRes,
      isTimeout: isTimeout.value,
      duration: duration.value
    }
  }

  const handleStartFrontBindingCard = (paymentCode) => {
    appEventCenter?.emit?.('handle-start-front-binding-card', paymentCode) // 重置支付方式
  }

  return {
    c2pIframeRef,
    visibleC2pCheckoutDrawer,
    initC2p,
    getCardTokenList,
    checkoutWithCard,
    checkoutWithNewCard,
    handleStartCheckoutWithCard,
    initiateValidation,
    validate,
    idLookup,
    signOut,
    resetInitC2P,
    isShowOtpValidateDrawer,
    closeOtpValidateDrawer,
    showOtpValidateDrawer,
    setRememberMe,
    setC2pTokenList,
    c2pTokenList,
    getInitUserInfo,
    isShowBSrcRequestTimeOutDialog,
    setIsShowBSrcRequestTimeOutDialog,
    setHandlerC2pTokenCheck,
    handleC2pTokenCheck,
    getIsHadSheinToken,
    handleNotYou,
    signOutFetchLoading,
    handleStartCheckoutWithCardIsTimeout,
    setHandleStartCheckoutWithCardIsTimeout,
    tempOrderInfo,
    setPayOrderInfo,
    toOrderDetail,
    handlerValidateCb,
    isInitC2pSuccess,
    c2pAvailableCardBrands,
    getAllCountryList,
    phoneValidateFn,
    handlePhoneEmailConfirmSubmit,
    closeBSrcPhoneEmailConfirmDialog,
    handleStartCheckoutWithNewCard,
    handleStartFrontBindingCard,
    isNoShowBSrcTokenHeader,
  }
}
