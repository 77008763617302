<template>
  <!--付费会员优惠信息-->
  <div
    v-if="autoUseCouponTip || primeFreeTip"
    :class="prefixCls('prime__wrapper')"
  >
    <div
      v-if="autoUseCouponTip"
      :class="prefixCls('prime__free-ship')"
    >
      <span>
        {{ autoUseCouponTip }}
      </span>
      <span
        @click="goCouponPage"
      >
        {{ language.SHEIN_KEY_PWA_22734 }}
      </span>
    </div>

    <!--引导加入付费会员-->
    <div
      v-else-if="primeFreeTip"
      v-expose="{
        id: 'expose_prime_entry:simple',
        data: saObj
      }"
      :class="prefixCls('prime__join-member')"
    >
      <span :class="prefixCls('prime__join-member__label')">
        <div :class="prefixCls('prime__join-member__img-box')">
          <img
            :class="prefixCls('prime__join-member__img')"
            :src="primeFreeTip.prime_icon_url"
            alt=""
          />
        </div>
        <span v-html="primeFreeTip.free_shipping_tip"></span>
      </span>
      <span
        v-tap="{
          id: 'click_prime_entry:simple',
          data: saObj
        }"
        role="button"
        :class="prefixCls('prime__join-member__btn-group')"
        @click="onClickJoinPrime"
      >
        <span>
          {{ primeFreeTip.button_text }}
        </span>
      </span>
    </div>
  </div>
</template>

<script setup name="ShippingPrime">
import { vExpose, vTap } from 'public/src/pages/common/analysis/directive'
import { prefixCls } from '../../utils/index'
import useShippingPrime from '../../hooks/useShippingPrime.js'

const props = defineProps({
  mallCode: {
    type: String,
    default: ''
  },
  autoUseCouponTip: {
    type: String,
    default: ''
  },
  primeFreeTip: {
    type: Object,
    default: null
  },
  language: {
    type: Object,
    default: () => ({})
  }
})

const {
  saObj,
  onClickJoinPrime,
  goCouponPage
} = useShippingPrime(props)

</script>


<style lang="less">
@import "public/src/pages/checkout_new/pages/shipping_method/variables";

.@{prefixCls} {
  &prime {
    &__wrapper {
      margin-top: 12/75rem;
    }

    &__free-ship {
      margin: 0 @g_ship_pad_hor_offset;
      background: @sui_color_promo_bg;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20/75rem;
      .font-dpr(24px);

      > :first-child {
        color: @sui_color_club_rosegold_dark1;
      }

      > :last-child {
        color: @sui_color_link;
        .margin-l(24/75rem);
      }
    }

    &__join-member {
      margin: 0.16rem @g_ship_pad_hor_offset 0.16rem @g_ship_pad_hor_offset;
      display: flex;
      padding: 20/75rem;
      justify-content: space-between;
      align-items: center;
      .font-dpr(24px);
      background: #FFF2E7;
      color: @sui_color_club_rosegold_dark1;

      &__label {
        flex: 1;
        display: inline-flex;
        align-items: center;
        i {
          .padding-r(16/75rem);
        }
      }


      &__img-box {
        width: 30/75rem;
        height: 30/75rem;
        margin-right: .21333333rem;
      }

      &__img {
        width: 30/75rem;
      }

      &__btn-group {
        padding: 10/75rem 16/75rem;
        border-radius: 34px;
        flex-shrink: 0;
        min-width: 80/75rem;
        max-width: 208/75rem;
        .margin-l(24/75rem);
        border: 1px solid @sui_color_club_rosegold_dark1;
        text-align: center;

        > span {
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
          text-overflow: ellipsis;
          word-break: break-all;
        }
      }
    }
  }
}
</style>
