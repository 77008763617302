<template>
  <div class="rounded-triangle">
    <template v-if="position == 'left'">
      <svg
        class="title-icon-coupon"
        width="9"
        height="20"
        viewBox="0 0 9 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M0 0H9V17H8.532C6.28717 17 4.3175 15.5039 3.71528 13.3414L0 0Z"
          :fill="fillColor"
        />
      </svg>
    </template>
    <template v-else>
      <svg
        class="title-icon-coupon"
        width="9"
        height="20"
        viewBox="0 0 9 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M9 0H0V17H0.467999C2.71283 17 4.6825 15.5039 5.28472 13.3414L9 0Z"
          :fill="fillColor"
        />
      </svg>
    </template>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'RoundedTriangle',
  props: {
    fillColor: {
      type: String,
      default: '#FFD6F9',
    },
    position: {
      type: String,
      default: 'left',
    },
  },
})
</script>

<style lang="less">
.title-icon-coupon {
  transform: scaleY(1.3);
}
</style>
