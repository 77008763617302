<template>
  <div :class="prefixCls('sp-footer_wrapper')">
    <s-alert
      v-if="customContentData.visible"
      v-tap="{
        id: 'click_custom_content:simple',
        data: customContentTap,
      }"
      :class="prefixCls('sp-footer_alert')"
      type="info"
      jumpable
      @click.stop="openPreviewDialog"
    >
      {{ customContentData.text }}
    </s-alert>
  </div>
</template>

<script setup name="customContent">
import { vTap } from 'public/src/pages/common/analysis/directive'
import { computed } from 'vue'
import { SAlert } from '@shein-aidc/sui-alert/mobile'
import { prefixCls } from '@/public/src/pages/checkout_new/pages/shopping_bags/utils'
import customDetailDialog from 'public/src/pages/common/customDetailDialogBff/index.js'

const props = defineProps({
  data: {
    type: Object,
    default: () => ({}),
  },
})

// ----------- method -----------
const openPreviewDialog = () => {
  customDetailDialog.open({
    ...customContentData.value.customizationPopInfo,
  })
}

// ---------- computed ----------

/**
 * @description 渲染数据
 * */
const customContentData = computed(() => {
  const { aggregateProductBusiness } = props.data
  // mock数据 商家商品定制
  // const mock = {
  //   "customContent": "Customization",
  //   "customizationContentTitle": "Customization",
  //   "customizedNotSupportTip": "Customized products cannot be modified.",
  //   "effectsTip": "Preview",
  //   "effects": [
  //     "https://img.ltwebstatic.com/v4/p/ccst2c/default/2025/03/18/1742285578ed5e5bc4bd335d09a88e706c604e41e9.png"
  //   ],
  //   "textsTip": "Text",
  //   "texts": [
  //     "q we"
  //   ],
  //   "imagesTip": "Image",
  //   "images": [],
  //   "okTip": "OK",
  //   "imageCompressSuffix": "?x-oss-process=image/resize,w_222",
  //   "originImages": [],
  //   "resizeImages": [],
  //   "originEffects": [
  //     "https://img.ltwebstatic.com/v4/p/ccst2c/default/2025/03/18/1742285578ed5e5bc4bd335d09a88e706c604e41e9.png"
  //   ],
  //   "resizeEffects": [
  //     "https://img.ltwebstatic.com/v4/p/ccst2c/default/2025/03/18/1742285578ed5e5bc4bd335d09a88e706c604e41e9_thumbnail_x222.png"
  //   ]
  // }
  
  const customizationPopInfo = aggregateProductBusiness?.customizationPopInfo || {}

  return {
    visible: !!customizationPopInfo?.customContent,
    text: customizationPopInfo?.customContent ?? '',
    customizationPopInfo
  }
})
/**
 * @description 埋点数据集
 * */
const customContentTap = computed(() => {
  const { product } = props.data
  const preview = product?.customization_info?.preview

  return {
    images: preview?.images?.length || 0,
    preview: preview?.effects?.length || 0,
    texts: preview?.texts?.length || 0,
  }
})
</script>

<style lang="less">
@import '../../../variables.less';

.@{prefixCls}sp-footer {
  &_alert {
    background: #f6f6f6!important;
    padding: 0.16rem;
    margin-top: 0.21333333rem;
    width: 100%;
  }
}
</style>
