<template>
  <div
    ref="BtnContainerRef"
    :class="[prefixCls('btn-container'), cls]"
    :style="`--container-width:${width}`"
  >
    <!-- 扫光动效 -->
    <div
      v-if="isShowLightShaftsAnimation"
      :class="[
        GB_cssRight
          ? prefixCls('bc__light-shafts-ar')
          : prefixCls('bc__light-shafts')
      ]"
    ></div>

    <!-- 按钮区 -->
    <ButtonGroup
      :class="[prefixCls('group-btn__wrapper'), animationCls]"
      :isOnlyRenderNormalBtn="isOnlyRenderNormalBtn"
      :c2pInfo="c2pInfo"
      @handleClickNormalBtn="handleClickNormalBtn"
    />

    <!-- 免邮提示 -->
    <FreeShippingTips
      v-if="visibleFsTips && !isOnlyRenderNormalBtn && !isSimple || forceShowFsTips"
    />
  </div>
</template>

<script setup name="BtnContainer">
import { ref, computed, watch } from 'vue'

import FreeShippingTips from './FreeShippingTips.vue'
import ButtonGroup from './btn_group/Index.vue'

import {
  useMapState,
  useMapGetters
} from '@/public/src/pages/checkout_new/hooks/store'
import { prefixCls } from '@/public/src/pages/checkout_new/pages/footer/utils'
import useFreeShipping from '@/public/src/pages/checkout_new/pages/footer/hooks/useFreeShipping.js'

const isShowLightShaftsAnimation = ref(false)
const BtnContainerRef = ref(null)
const animationCls = ref('')
// 下单页面是否使用新版本按钮布局样式【此处写死即可，现在abt放量，全部使用new style】
const isNewLayoutStyle = true

const { visibleFsTips } = useFreeShipping()

const props = defineProps({
  // 强制展示角标利诱点
  forceShowFsTips: {
    type: Boolean,
    default: false
  },
  // 仅仅展示 Btn 按钮，不展示浮窗那些
  isSimple: {
    type: Boolean,
    default: false
  },
  // 仅仅展示NormalBtn，不展示其他
  isOnlyRenderNormalBtn: {
    type: Boolean,
    default: false
  },
  c2pInfo: {
    type: Object,
    default: () => ({})
  }
})

const emit = defineEmits(['handleClickNormalBtn'])

// -------- useMap_**** --------
const {
  isPagePriceTotalShowInView,
  footerState,
  GB_cssRight,
  checkout,
  abtInfo,
  paymentState
} = useMapState([
  'isPagePriceTotalShowInView',
  'footerState',
  'GB_cssRight',
  'checkout',
  'abtInfo',
  'paymentState'
])

const { isHasAddress } = useMapGetters(['isHasAddress'])

// ---------- computed ----------
const cls = computed(() => {
  return isNewLayoutStyle
    ? //普通的按钮样式
    prefixCls('btn-container__new')
    : // 新版本的按钮样式
    prefixCls('btn-container__normal')
})

const isShippingMethod = computed(() => {
  return checkout.value?.shipping_methods_mall?.some(
    item => item?.shipping_methods?.length
  )
})

const width = computed(() => {
  return footerState.value.btnContainerWidth || '100%'
})

const isMoveNeedStretch = computed(() => {
  return (
    abtInfo.value?.placeorderStatusmove?.param?.placeorderStatusmoveStatus == 1
  )
})

// ----------- watch ------------
watch(
  () => isPagePriceTotalShowInView.value,
  visible => {
    animationCls.value = props.isOnlyRenderNormalBtn
      ? ''
      : visible && isMoveNeedStretch.value
        ? prefixCls('group-btn__wrapper_full')
        : prefixCls('group-btn__wrapper_reset')
  }
)

watch(
  [
    () => isShippingMethod.value,
    () => isHasAddress.value,
    () => paymentState.value.selectedPaymentInfo
  ],
  ([newVal1, newVal2, newVal3]) => {
    if (newVal1 && newVal2 && !!newVal3) {
      const isShowedLightShaftsAnimation =
        typeof sessionStorage.getItem('isShowedLightShaftsAnimation') ===
        'string'
          ? sessionStorage.getItem('isShowedLightShaftsAnimation')
          : ''
      if (!isShowedLightShaftsAnimation) {
        sessionStorage.setItem('isShowedLightShaftsAnimation', 1)
        isShowLightShaftsAnimation.value = true
        setTimeout(() => {
          isShowLightShaftsAnimation.value = false
        }, 900)
      }
    }
  }
)
// ----------- method -----------
const handleClickNormalBtn = () => {
  emit('handleClickNormalBtn')
}
</script>

<style lang="less">
@import '../../../variables.less';

.@{prefixCls}btn-container {
  position: relative;
  //普通的按钮样式
  &__normal {
    width: 240/75rem;
    height: 80/75rem;
    max-width: 300/75rem;
    min-width: 240/75rem;
  }

  // 新版本的按钮样式
  &__new {
    flex: 1;
    min-width: 0; // 设置flex是1的时候，消除子元素超过父元素的宽度
    height: 80/75rem;
  }

  .@{prefixCls}bc__light-shafts {
    height: 100%;
    position: absolute;
    top: 0;
    left: -2rem;
    z-index: 2;
    background: url('https://img.ltwebstatic.com/images3_ccc/2024/06/28/2a/17195441661b972a9333a54fb4ca3c83c2efb55f59.png')
      no-repeat top/center;
    width: 100/37.5rem;
    animation: strip-move_left 1s 1;
    will-change: transform;
  }

  .@{prefixCls}bc__light-shafts-ar {
    height: 100%;
    position: absolute;
    top: 0;
    right: -2rem;
    z-index: 2;
    background: url('https://img.ltwebstatic.com/images3_ccc/2024/06/28/2a/17195441661b972a9333a54fb4ca3c83c2efb55f59.png')
      no-repeat top/center;
    width: 100/37.5rem;
    animation: strip-move_right 1s 1;
    transform: rotate(180deg);
  }

  // 擦亮动画
  @keyframes strip-move_left {
    0% {
      left: -1rem;
      transform: translateX(-1rem);
    }
    100% {
      transform: translateX(8rem);
    }
  }
  // 擦亮动画
  @keyframes strip-move_right {
    0% {
      right: -1rem;
      transform: translateX(-1rem);
    }
    100% {
      right: 100%;
      transform: translateX(8rem);
    }
  }

  // 按钮横向伸缩动画
  .@{prefixCls}group-btn__wrapper {
    position: absolute;
    right: 0;
    top: 0;
    width: var(--container-width);
    transition: all 0.5s ease-in-out;
    &_full {
      width: calc(10rem - 0.64rem);
    }

    &_reset {
      width: var(--container-width);
    }
  }
}
</style>
