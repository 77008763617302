<template>
  <div v-if="shippingMethods && shippingMethods.length">
    <!--水平滑动-->
    <ShippingMethodSwiper
      v-if="isSlideByAbt"
      :shipping-methods="shippingMethods"
      :mall-code="mallCode"
    />

    <!--垂直排列-->
    <ShippingMethodVertical
      v-else
      :shipping-methods="shippingMethods"
      :mall-code="mallCode"
    />
  </div>
</template>

<script setup name="ShippingMethodList">
import { computed } from 'vue'
import ShippingMethodSwiper from './ShippingMethodSwiper.vue'
import ShippingMethodVertical from './ShippingMethodVertical.vue'
import { useMapState } from '@/public/src/pages/checkout_new/hooks/store'

defineProps({
  mallCode: {
    type: String,
    default: ''
  },
  shippingMethods: {
    type: Array,
    default: () => []
  }
})

const { abtInfo } = useMapState(['abtInfo'])

const isSlideByAbt = computed(() => {
  return abtInfo.value.shippingVersion?.param?.isShippingVersion === 'new'
})

</script>
