<template>
  <!-- 多商品购物袋内容区域 -->
  <MultiProduct
    :class="prefixCls('store-product-group')"
    :data="storeGroupList"
  />
</template>

<script setup name="ProductsGroup">
/**
 * 主库
 * */
import { inject, computed } from 'vue'

/**
 * 当前业务组件
 * */
import MultiProduct from '../../multi_product/Index.vue'

/**
 * 当前业务函数
 * */
import { useMapState } from '@/public/src/pages/checkout_new/hooks/store'
import { prefixCls } from '@/public/src/pages/checkout_new/pages/shopping_bags/utils'
const mallIndex = inject('mallIndex')

const props = defineProps({
  groupIndex: {
    type: Number,
    default: 0,
  },
})
// --------- useMap_**** --------
const { shoppingBagsState, buyTogetherState } = useMapState([
  'shoppingBagsState',
  'buyTogetherState',
])

// ----------- computed ---------

const storeGroupList = computed(() => {
  const currentMall = shoppingBagsState.value?.bagsInfo?.mall?.[mallIndex] || {}
  const storeGroupList = currentMall.storeGroupList
  const rowStoreGroupList = storeGroupList?.[props.groupIndex]

  const AddedCartsIds =
    buyTogetherState.value?.AddedCartsIdsFromBuyTogether || []
  const currentId = AddedCartsIds?.[0]
  const renderList = rowStoreGroupList?.goods || []
  if (currentId && !shoppingBagsState.value?.isBlockingSort) {
    // 自定义排序，将目标元素放在数组最前面
    renderList.sort((a, b) => {
      if (a.id === currentId) return -1 // a 是目标元素，前置
      if (b.id === currentId) return 1 // b 是目标元素，后置
      return 0 // 保持原顺序
    })
  }
  return renderList
})
</script>

<style lang="less">
@import '../../../variables.less';
.@{prefixCls}store-product-group{
  background: #fff;
  padding: .16rem 0 0 0;
}
</style>
