<template>
  <div
    v-if="prime_member_benefit"
    class="checkout-prime-benefit__wrap"
    :class="{'checkout-prime-benefit__wrap--ar': GB_cssRight}"
  >
    <div
      class="checkout-prime-benefit__left"
      :class="{'checkout-prime-benefit__left--ar': GB_cssRight}"
    >
      <div class="checkout-prime-benefit__left__img-wrap">
        <img
          :src="prime_member_benefit.prime_img_url"
          alt=""
        />
      </div>
    </div>

    <div class="checkout-prime-benefit__right">
      <div>
        {{ prime_member_benefit.save_tip }}
      </div>
      <div>
        {{ prime_member_benefit.prime_club_tip }}
      </div>
    </div>
  </div>
</template>

<script setup name="PrimeBenefit">
import { useMapState } from '@/public/src/pages/checkout_new/hooks/store'

const { prime_member_benefit, GB_cssRight } = useMapState(['checkout.prime_member_benefit', 'GB_cssRight'])
</script>

<style lang="less" scoped>
.checkout-prime-benefit {
  &__wrap {
    width: 100%;
    height: auto;
    margin-top: 16/75rem;
    display: flex;
    align-items: stretch;
    background: url('/pwa_dist/images/checkout/sui_img_paidmember_savebg-c58bac1834.png');
    background-size: 100% 100%;

    &--ar {
      background: url('/pwa_dist/images/checkout/sui_img_paidmember_savebg_ar-847b3157bf.png');
      background-size: 100% 100%;
    }
  }

  &__left {
    background: url('/pwa_dist/images/checkout/sui_img_paidmember_logobg-04c62e09bc.png');
    background-size: 100% 100%;
    width: 230/75rem;
    padding: 20/75rem;
    flex-shrink: 0;

    &--ar {
      background: url('/pwa_dist/images/checkout/sui_img_paidmember_logobg_ar-f3fdba35fa.png');
      background-size: 100% 100%;
    }

    &__img-wrap {
      display: inline-flex;
      height: 100%;
      align-items: center;

      > img {
        width: 180/75rem;
        height: auto;
      }
    }
  }

  &__right {
    padding: 20/75rem;
    .padding-l(30/75rem);
    color: @sui_color_club_rosegold_dark1;

    > :first-child {
      .font-dpr(32px);
      font-weight: 700;
    }

    > :last-child {
      .font-dpr(24px);
      font-weight: 400;
    }
  }
}

</style>
