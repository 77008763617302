import { computed, ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useMapState } from '@/public/src/pages/checkout_new/hooks/store'
import useAttribute from 'public/src/pages/checkout_new/pages/shopping_bags/hooks/useAttribute.js'

/**
 * @description (3)巴西50美金税费弹窗数据
 * return {jumpAble} 是否允许弹窗
 * return {renderData} 弹窗渲染数据
 * */
export default function useBr50DollarsTax(mallIndex) {
  const cacheAllCartsInfo = ref([])
  const cacheGoodsIdList = ref([])
  const store = useStore()
  // --------- useMap_**** --------
  const { shoppingBagsState, checkout } = useMapState([
    'shoppingBagsState',
    'checkout',
  ])

  const allList = computed(() => {
    // mallIndex存在，则逻辑在购物袋内，否则在全局；全局则需要所有的数据，即all
    const all = shoppingBagsState?.value?.bagsInfo?.all || []
    const mallAll =
      shoppingBagsState?.value?.bagsInfo?.mall?.[mallIndex]?.all || []
    const list = mallIndex !== undefined ? mallAll : all

    return list
  })

  const filterData = computed(() => {
    // 筛选去掉赠品，附属品，目的是：展示纯购买商品
    return cacheAllCartsInfo.value?.filter(item => {
      const { isGift, isAdditionGood } = useAttribute(item, store)
      return !isGift.value && !isAdditionGood.value
    })
  })

  // 缓存数据，用于弹窗列表的数据的展示
  const renderData = computed(() => {
    const res = filterData.value?.filter(item => {
      return cacheGoodsIdList.value.includes(item.id)
    })
    return res
  })

  // 是否可弹窗
  const isAble = computed(() => {
    return allList.value?.length > 1
  })

  // 缓存allList
  watch(
    () => allList.value,
    list => {
      if (list?.length && !cacheAllCartsInfo.value.length) {
        cacheAllCartsInfo.value = list
      }
    },
    {
      immediate: true,
    },
  )

  // 缓存goodsIdList
  watch(
    () => checkout.value?.mallHintInfo,
    info => {
      if (info && !cacheGoodsIdList.value.length) {
        const mallCode =
          shoppingBagsState?.value?.bagsInfo?.mall?.[mallIndex]?.mall_code
        const goodsIdList =
          info?.find(item => item.mallCode === mallCode)?.goodsIdList || []
        cacheGoodsIdList.value = goodsIdList
      }
    },
    {
      immediate: true,
    },
  )
  // 数据重置
  watch(
    () => shoppingBagsState.value.visibleBr50DollarsTaxDrawer,
    () => {
      cacheAllCartsInfo.value = []
      cacheGoodsIdList.value = []
    },
  )

  return { isAble, renderData, allList }
}
