<template>
  <div :class="prefixCls('store-row-group')">
    <!-- QuickShip All Items -->
    <ShippingLabel v-if="currentMallCurrentStore?.quick_shipping?.allQs" />
    <!-- SFS商品提示 -->
    <SFSProductsTips :class="[cls]" />
    <div :class="[prefixCls('store-row-group_section'), cls]">
      <!-- 氛围头 -->
      <PromotionHeader
        v-if="shoppingBagHeadInfo.visible"
        :class="prefixCls('store-row-group_header')"
        :data="shoppingBagHeadInfo.data"
      />
      <!-- 产品组 Rows -->
      <ProductsGroup :groupIndex="groupIndex" />
    </div>
  </div>
</template>
<script>
export default {
  name: 'StoreProductRowGroup',
}
</script>
<script setup name="StoreProductRowGroup">
/**
 * 主库
 * */
import { computed } from 'vue'

/**
 * 当前业务组件
 * */
import ProductsGroup from './components/ProductsGroup.vue'
import PromotionHeader from 'public/src/pages/checkout_new/pages/shopping_bags/components/promotion_header/Header.vue'
import SFSProductsTips from '@/public/src/pages/checkout_new/pages/shopping_bags/components/sfs_products_tips/Index.vue'
import ShippingLabel from '@/public/src/pages/checkout_new/pages/shopping_bags/components/product_shipping/components/ShippingLabel.vue'

/**
 * 当前业务函数
 * */
import {
  useMapState,
} from '@/public/src/pages/checkout_new/hooks/store'
import { prefixCls } from '@/public/src/pages/checkout_new/pages/shopping_bags/utils'
import useHandleMallAndStoreGroup from 'public/src/pages/checkout_new/pages/shopping_bags/hooks/useHandleMallAndStoreGroup.js'

defineProps({
  groupIndex: {
    type: Number,
    default: 0,
  },
})

// --------- useMap_**** --------
const { isMultiMall } = useMapState([
  'isMultiMall',
])
const { shoppingBagHeadInfo, currentMallCurrentStore } =
  useHandleMallAndStoreGroup()
// ----------- computed ---------
const cls = computed(() => {
  const ambianceHeader = shoppingBagHeadInfo.value.visible
    ? 'ambiance-header'
    : ''
  const multiMallCls = isMultiMall.value ? 'multi-mall' : ''
  return [ambianceHeader, multiMallCls]
})
</script>

<style lang="less">
@import '../../variables.less';

.@{prefixCls}store-row-group {
  > .ambiance-header {
    padding-left: 0;
  }
  .@{prefixCls}common {
    &__shipping-label {
      padding-top: 0;
    }
  }
  &_section {
    .@{prefixCls}store-product-group {
      padding: 0;
    }
    &.ambiance-header {
      border-radius: 4px;
      padding: 0 2/37.5rem 2/37.5rem;
      margin-left: -0.32rem;
      background: linear-gradient(129deg, #fdc830 -4.38%, #f37335 28.14%);

      .@{prefixCls}store-product-group {
        background: #fff;
        padding: 0.16rem 0 0.16rem 0.26666667rem;
      }
      &.multi-mall {
        margin-top: 0;
      }
    }
  }

  &_header {
    padding: 8/37.5rem 10/37.5rem;
  }
}
</style>
