<template>
  <CommonInputNumber
    :id="inputId"
    :quantity="quantityValue"
    :min="min"
    :max="max"
    :disable-inc="isDisableInc"
    :disable-dec="isDisableDec"
    :disable="disable"
    @focus="focus"
    @blur="blur"
    @change="change"
  />
</template>

<script setup name="ProductQuantityInput">
// thrid-party components from 'npm'
import {
  ref,
  watch,
  computed,
  nextTick,
  inject,
  onMounted,
  onUnmounted
} from 'vue'
import { template } from '@shein/common-function'
import { SToast } from '@shein-aidc/sui-toast/mobile'
import { daEventCenter } from '@shein-aidc/basis-sa-event-center'

// common components from 'checkout common components'
import CommonInputNumber from '@/public/src/pages/checkout_new/components/CommonInputNumber.vue'

// store/utils function and so on
import {
  useMapState,
  useMapMutations
} from '@/public/src/pages/checkout_new/hooks/store'
import { RealQuickShipStatus } from '@/public/src/pages/checkout_new/utils/constant'
import { Operation } from '@/public/src/pages/checkout_new/pages/shopping_bags/utils/constant'
import useUpdateQuantity from 'public/src/pages/checkout_new/pages/shopping_bags/hooks/useUpdateQuantity.js'
import useAttribute from 'public/src/pages/checkout_new/pages/shopping_bags/hooks/useAttribute.js'

const emit = defineEmits(['emitInputCurrent'])

// 存储上次埋点的key，如果异常【数量上限~~】，可以使用当前key
let markPointKey = ''

// 默认弹窗
const isDefaultDoubleConfirm = inject('isDefaultDoubleConfirm', false)

const props = defineProps({
  // 商品数据，from cartsInfo
  data: {
    type: Object,
    default: () => ({})
  },
  // 当前组件脱离购物袋做为公共组件时的attr属性
  commonAttr: {
    type: Object,
    default: () => ({
      isUsedInShippingMethod: false
    })
  }
})

// 响应性value
const quantityValue = ref(+props.data?.quantity || 0)

// 当前更改对应的上一次的数据，用于reRender，重置，接口报错等等的数据回显
const oldQuantityValue = ref(+props.data?.quantity || 0)

// input的id，用于控制dom的原生事件
const inputId = ref('')

// 更改购物袋商品数量的hooks === 提升复用能力
const useUpdateQuantityParams = {
  isUsedInShippingMethod: props.commonAttr.isUsedInShippingMethod
}
const { updateShoppingBagsQuantity } = useUpdateQuantity(
  useUpdateQuantityParams
)
const { isOutStock, isClubGift, isGift, isAdditionGood, seaLandBelt } = useAttribute(
  props?.data
)


// 约定本单可购最大商品数默认为99，具体看适用对象和逻辑
const defaultMax = 99

// -------- useMap_**** --------

const {
  //
  shoppingBagsState,
  language
} = useMapState([
  //
  'shoppingBagsState',
  'language'
])
const {
  updateShoppingBagsItemDeleteState,
  resetShoppingBagsItemValue,
  updateShoppingBagsItemOverStockDialog
} = useMapMutations([
  'updateShoppingBagsItemDeleteState',
  'resetShoppingBagsItemValue',
  'updateShoppingBagsItemOverStockDialog'
])

const isUsedInShippingMethod = inject('isUsedInShippingMethod', false)

// —————————— watch ————————————

// 数据重置，恢复旧数据
watch(
  () => shoppingBagsState.value.isResetShoppingBagsItemValue,
  val => {
    if (val && oldQuantityValue.value !== 0) {
      quantityValue.value = oldQuantityValue.value
    }
  }
)

// 表单数据变更，实时渲染
watch(
  () => props.data,
  val => {
    const value = +val?.quantity || 0
    // 界面数据重置 === 视觉上的反馈
    quantityValue.value = value

    // 更新旧数据 === oldQuantityValue永远跟中间层的数据同步
    oldQuantityValue.value = value
  },
  { deep: true }
)

// ———————— computed ———————————

// max【可购最大值】
const max = computed(() => {
  return isClubGift.value ? quantityValue.value : defaultMax
})

//mix【可购最小值】
const min = computed(() => {
  return isOnlyOneProduct.value ? (isClubGift.value ? 0 : 1) : 0
})

// 最小值控制【禁止 — 】
const isDisableDec = computed(() => {
  return (
    isOnlyOneProduct.value ||
    isClubGift.value ||
    shoppingBagsState.value?.isDisabledInput
  )
})

// 当前商品数量大于库存
const isOverStock = computed(() => {
  const {quantity = '', product = {}} = props.data || {}
  return +quantity >= +product?.stock
})

// 最大值控制【禁止 + 】
const isDisableInc = computed(() => {
  return (
    isOutStock.value ||
    isClubGift.value ||
    maximumLimit.value ||
    shoppingBagsState.value?.isDisabledInput ||
    isOverStock.value
  )
})

// 最大限制
const maximumLimit = computed(() => {
  let max = defaultMax

  const {
    maxLimitPurchaseQuantity = undefined,
    maximumQuantityOfGoods = undefined
  } = props.data?.aggregateProductBusiness ?? {}

  // "最大可编辑数量" 取maxLimitPurchaseQuantity、maximumQuantityOfGoods、99的最小值
  if (maxLimitPurchaseQuantity !== undefined) {
    max = Math.min(max, maxLimitPurchaseQuantity)
  }
  if (maximumQuantityOfGoods !== undefined) {
    max = Math.min(max, maximumQuantityOfGoods)
  }
  return quantityValue.value >= max
})

// 当前订单只有一个商品
const isOnlyOneProduct = computed(() => {
  const num = 1
  const allProduct = shoppingBagsState.value?.bagsInfo?.all ?? []
  const isOnlyOne = allProduct.length === num
  const quantity = +allProduct?.[0]?.quantity
  return isOnlyOne ? quantity === num : false
})

// 依条件 disable input的 + -
const disable = computed(() => {
  // 只要是会员赠品且不是无库存，可编辑-号按钮
  if (!isOutStock.value && isClubGift.value) {
    return false
  }
  return isGift.value || isAdditionGood.value || isOutStock.value
})

// 当前mall
const currentMall = computed(() => {
  return shoppingBagsState.value?.bagsInfo?.mall?.find(item =>
    item?.all?.some(i => i?.id === props?.data?.id)
  )
})

// 当前商品不仅存在QS运输，还存在非QS运输
const isDoubleNormalAndQuickShip = computed(() => {
  const { id: cartId, quantity = 0 } = props.data
  const normalProduct = currentMall.value?.normal || []
  const quickShipProduct = currentMall.value?.quickShip || []

  // 当前商品来自于Normal
  const currentProductOfNormal = normalProduct.find(item => item.id === cartId)

  // 当前商品来自于QS
  const currentProductOfQuickShip = quickShipProduct.find(
    item => item.id === cartId
  )

  let res = {
    quantity: +quantity,
    isDouble: false
  }

  // 当前商品不仅存在QS运输，还存在非QS运输
  if (
    currentProductOfNormal !== undefined &&
    currentProductOfQuickShip !== undefined
  ) {
    const normalQuantity = +currentProductOfNormal.quantity
    const quickShipQuantity = +currentProductOfQuickShip.quantity
    res.quantity = normalQuantity + quickShipQuantity
    res.isDouble = true
  }
  return res
})

// ————————— method ————————————

// 获取焦点后，禁止点击其他地方
const focus = (e) => {
  markPointKey = 'focus'
  sendMarkPoint()
  document.body.classList.toggle('disabled-checkout__body-click', true)
}

// 失去焦点后，可点击其他地方
const blur = () => {
  document.body.classList.toggle('disabled-checkout__body-click', false)
}

// inputNumber的change事件，crud
const change = (currentValue, oldValue, type) => {
  markPointKey = type
  // 状态重置，有助于下一次状态的变更
  resetShoppingBagsItemValue(false)

  // 设定input的value,是否成功还得看后面逻辑，但是界面上需要反馈用户的操作，所以拥抱当前的更改
  setInputValue(currentValue, oldValue)

  // inputNumber事件外抛
  emitInputNumberData(currentValue, oldValue, type)

  const inputNumberBoolean = controlDecrease(type, currentValue)
  // 控制 "减少商品数量" 的逻辑 step.1 value重置
  if (inputNumberBoolean) {
    currentValue = 1
  }

  // 控制 "增加商品数量" 的逻辑
  if (controlIncrease(type, currentValue)) {
    sendMarkPoint(true)
    return
  }
  sendMarkPoint()
  // 润色currentValue
  const { resultValue, oldResultValue } = handleCurrentValue(currentValue)

  // 控制 "弹出确认删除框" 的逻辑
  if (controlConfirmDelete(resultValue)) return

  // block输入为0的api请求 step.2 阻止请求
  if (inputNumberBoolean && oldValue === 1) return
  // 数据提交，更新数据
  submitCurrentProductData(type, resultValue, oldResultValue)
}

// 设定input的value
const setInputValue = (currentValue, oldValue) => {
  // 使得数据具有响应性
  quantityValue.value = currentValue
}

const emitInputNumberData = ( currentValue, oldValue, type ) => {
  emit('emitInputCurrent', currentValue, oldValue, type)
}

// 控制减少商品数量
const controlDecrease = (type, currentValue) => {
  // 输入小于等于0则自动重置为1
  if (type === 'input' && currentValue <= 0) {
    quantityValue.value = currentValue
    nextTick(() => {
      // 数据重置
      const resetValue = 1
      quantityValue.value = resetValue
    })
    return true
  }
}

// 控制增加商品数量
const controlIncrease = (type, currentValue) => {
  if (type === 'input' && currentValue === max.value) {
    quantityValue.value = currentValue
    nextTick(() => {
      // 数据重置
      const resetValue = oldQuantityValue.value
      quantityValue.value = resetValue
    })
    // 这种产品最多可以购买 n 件
    SToast(template(max.value, language.value?.SHEIN_KEY_PWA_28828))
    return true
  }
}

// 控制弹出确认删除框
const controlConfirmDelete = (resultValue) => {
  if (resultValue === 0) {
    // 弹出确认删除框
    updateShoppingBagsItemDeleteState({
      visible: true,
      cart_id: props.data.id,
      isDefaultDoubleConfirm,
      isUsedInShippingMethod
    })
    return true
  }
}

// 提交数据，更新checkout数据
const submitCurrentProductData = async (type, resultValue, oldResultValue) => {
  const params = {
    cart_id: props.data?.id,
    final_quantity: resultValue,
    operation: Operation.UPDATE,
    origin_quantity: oldResultValue
  }
  // 控制展示操作产品数量与库存数量关系的弹窗提醒
  if (controlOverStockDialog(resultValue, params)) return

  // api
  const res = await updateShoppingBagsQuantity(params)
  if (!res.status) {
    // 购物袋商品项value重置
    resetShoppingBagsItemValue(true)
    sendMarkPoint(true)
  }
}

// 润色currentValue
const handleCurrentValue = currentValue => {
  let resultValue = currentValue
  const { quantity, isDouble } = isDoubleNormalAndQuickShip.value

  if (isDouble) {
    const diffValue = currentValue - oldQuantityValue.value
    resultValue = quantity + diffValue
  }
  return { resultValue, oldResultValue: quantity }
}

// 控制展示操作产品数量与库存数量关系的弹窗提醒
const controlOverStockDialog = (resultValue, params) => {
  const isQs = props.data?.real_quick_ship === RealQuickShipStatus.PartQuick
  const isMoreStock = resultValue > +props.data?.product?.qs_stock
  const id = props.data.id
  // 仅弹出一次，所以使用session缓存
  const goods_list =
    typeof sessionStorage.getItem('is_showed_over_stock_ids') === 'string'
      ? JSON.parse(sessionStorage.getItem('is_showed_over_stock_ids'))
      : []

  if (isQs && isMoreStock && !goods_list.includes(id)) {
    goods_list.push(id)
    sessionStorage.setItem(
      'is_showed_over_stock_ids',
      JSON.stringify(goods_list)
    )

    updateShoppingBagsItemOverStockDialog({
      visible: true,
      params,
      stock: props.data?.product?.qs_stock
    })
    return true
  }
}

// 告知用户，为什么不可以点击
const handleDisableToast = () => {
  isOnlyOneProduct.value && SToast(language.value?.SHEIN_KEY_PWA_28660)
}

const sendMarkPoint = (isExceptionTips) => {
  // 大件海陆运
  const isSeaLand = seaLandBelt.value.visible
  const source = {
    'focus': 'click_cart_quantity_edit:simple',
    'input': 'click_cart_quantity_edit_confirm:simple',
    'add': 'click_goods_add_qty:simple',
    'lose': 'click_goods_sub_qty:simple'
  }

  // type: 1, shipping bag; 2、异常提示; 3、海陆运
  const params = {
    type: isExceptionTips ? 2 : isSeaLand ? 3 : 1
  }
  const currentSource = source?.[markPointKey]
  daEventCenter.triggerNotice({
    daId: currentSource,
    extraData: {
      ...params
    }
  })
}

// ———————————————— *_Mounted ———————————————
onMounted(() => {
  const id = props.data?.id || ''
  const domId = `commonInputNumber_${id}_${new Date().getTime()}`
  inputId.value = domId
  nextTick(() => {
    const cls = `#${domId} .sui-input-number__prepend.sui-input-number__disabled-btn`
    const dom = document.querySelector(cls)

    dom?.addEventListener('click', handleDisableToast)
  })
})

onUnmounted(() => {
  document
    .querySelector(
      `#${inputId.value} .sui-input-number__prepend.sui-input-number__disabled-btn`,
    )
    ?.removeEventListener('click', handleDisableToast)
})
</script>
