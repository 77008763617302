import { parseQueryString, getLocalStorage } from '@shein/common-function'
import { abtservice } from 'public/src/services/abt'
import {
  useMapState,
  useMapGetters,
} from '@/public/src/pages/checkout_new/hooks/store'
import { judgeActivityIsExist } from '@/public/src/pages/checkout_new/utils/index.js'



export default () => {
  const {
    abtInfo,
    cartsInfo,
    shoppingBagsState,
    checkout,
  } = useMapState([
    'abtInfo',
    'cartsInfo',
    'shoppingBagsState',
    'checkout',
  ])

  const { 
    paymentInfo,
  } = useMapGetters([
    'paymentInfo',
  ])


  const reportPvAnalysis = async(from = {}) => {
    window.PageGroup = '下单页'
    window.PageGroupOverview = '购物流程'
  
  
    let page_from = ''
    if( document.referrer ){
      if( document.referrer.indexOf('/user/login') > -1 ){
        page_from = 'login'
      }
      if (document.referrer.indexOf('/cart') > -1) {
        page_from = 'page_cart'
      }
    }
    if (from.path?.indexOf('/cart') > -1) {
      page_from = 'page_cart'
    }
    const { page_from: pageName } = parseQueryString(window.location.search)
    if(!!pageName) {
      page_from = pageName
    }
  
    // 路由进入手动刷新数据
    let SaPageInfo = {
      page_id: 11,
      page_name: 'page_checkout',
      page_param: {
        page_from,
        is_combine: getLocalStorage('is_combine') == '0' ? 0 : 1,
        mall_code: cartsInfo.value?.good_by_mall?.map(_ => _.mall_code)?.join(','),
        activity_method: judgeActivityIsExist(shoppingBagsState.value?.bagsInfo?.all || [], [3, 10]),
      },
      start_time: new Date().getTime()
    }
  
    let posKeys = []
    const paymentMethods = paymentInfo.value?.payments || []
    const PaymentABT = abtInfo.value?.['PaymentABT'] || {}
    if (PaymentABT && paymentMethods?.length) {
      const paymentOffAbtValue =
        PaymentABT?.p?.indexOf?.('paymentoff=') > -1
          ? PaymentABT.p.split('=')[1]
          : ''
      if (paymentOffAbtValue) {
        posKeys.push('SMPaymentABT')
      }
    }
  
    // eslint-disable-next-line @shein-aidc/abt/abt
    const abtestVal = abtservice.getUserAbtResultForAnalysis({
      posKeys: posKeys.join(','),
      abtInfo: abtInfo.value
    }).sa
    if (SaPageInfo.page_param.abtest) {
      SaPageInfo.page_param.abtest += ',' + abtestVal
    } else {
      SaPageInfo.page_param.abtest = abtestVal
    }

    if(!!shoppingBagsState.value?.bagsInfo?.all?.length) {
      SaPageInfo.page_param.is_shop = shoppingBagsState.value.isShopType
    }
  
    SaPageInfo.page_param.buy_tp = checkout.value?.prime_info?.prime_products?.length
      ? '2'
      : '0'


    //活动id和是否满足
    let promotion_method = ''
    let page_fromcart = ''
    let checkout_from = ''
    let checkout_total = ''
    let mall_shipping_method = ''
    let mall_origin_price = ''
    let mall_is_fullshippingactivity = ''
    let mall_step_fee = ''
    let mall_charging_type = ''
    let mall_num_price = ''
    if (window.sessionStorage?.getItem) {
      try {
        let cartPoints = window.sessionStorage.getItem('cartPoints')
        if (cartPoints) {
          cartPoints = JSON.parse(cartPoints)
          let cartSa = cartPoints.sa
          if (cartSa) {
            promotion_method = cartSa.promotion
            page_fromcart = cartSa.page_from

            checkout_from = cartSa.click_from
              ? `${cartSa.click_from}\`${cartSa.button_name}`
              : ''
            checkout_total = cartSa.checkout_total

            mall_shipping_method = cartSa.mall_shipping_method || '',
            mall_origin_price = cartSa.mall_origin_price || '',
            mall_is_fullshippingactivity = cartSa.mall_is_fullshippingactivity || '',
            mall_step_fee = cartSa.mall_step_fee || '',
            mall_charging_type = cartSa.mall_charging_type || '',
            mall_num_price = cartSa.mall_num_price || ''
          }
        }
        if(!!cartPoints?.checkout_from) checkout_from = cartPoints.checkout_from
      } catch (e) {
        // eslint-disable-next-line no-console
        console.log(e)
      }
    }

    let pageParam = {
      promotion: promotion_method,
      //取购物车的page_from
      page_fromcart,
      checkout_total,
      checkout_from,
      mall_shipping_method,
      mall_origin_price,
      mall_is_fullshippingactivity,
      mall_step_fee,
      mall_charging_type,
      mall_num_price
    }

    Object.assign(SaPageInfo.page_param, pageParam)

    window.SaPageInfo = SaPageInfo
    appEventCenter?.emit('pageOnload')
    window.onbeforeunload = function() {
      sa('send', 'pageLeave', { end_time: new Date().getTime() })
    }
  }


  return {
    reportPvAnalysis
  }
}
