<template>
  <div
    v-expose="analysisData"
    :class="prefixCls('promotion-header')"
  >
    <img
      v-if="titleUrl"
      :src="titleUrl"
      alt=""
      :class="prefixCls('promotion-header__title')"
      :style="{
        height: titleHeight
      }"
    />
    <template v-else>
      <img
        v-if="data.icon_url"
        :src="data.icon_url"
        alt=""
        :class="prefixCls('promotion-header__icon')"
      />
      <div 
        v-if="data.left_tip"
        :class="prefixCls('promotion-header__left-tip')"
      >
        <SAdapterText
          min-size="10"
          :text="data.left_tip"
        />
      </div>
    </template>
    <span
      v-if="(data.icon_url || data.left_tip) && (data.count_down_icon_url || data.right_tip || data.end_time)"
      :class="prefixCls('promotion-header__divider')"
    ></span>
    <ClientOnly>
      <CountDown
        :data="data"
      />
    </ClientOnly>
  </div>
</template>

<script setup>
import { vExpose } from 'public/src/pages/common/analysis/directive'
import { computed } from 'vue'
import { SAdapterText } from '@shein-aidc/sui-adapter-text/mobile'
import { ClientOnly } from '@sheinfe/vue-client-only'
import CountDown from './CountDown.vue'
import { prefixCls } from '@/public/src/pages/checkout_new/pages/shopping_bags/utils'

const props = defineProps({
  data: {
    type: Object,
    default: () => ({})
  }
})

const analysisData = computed(() => {
  return {
    id: 'expose_shoppingbag_header:simple',
    data: {
      type: props.data.type || '-'
    }
  }
})

const titleUrl = computed(() => {
  return props.data.icon_url_info?.url
})
const titleHeight = computed(() => {
  return props.data.icon_url_info?.height ? `${(props.data.icon_url_info.height / 3) / 37.5}rem` : `${32 / 75}rem`
})
</script>

<style lang="less">
@import '../../variables.less';

.@{prefixCls}promotion-header {
  display: flex;
  align-items: center;
  width: 100%;
  font-size: 14px;
  font-weight: 700;
  color: #fff;
  line-height: normal;
  &__title {
    flex-shrink: 0;
    margin-right: 8/37.5rem;
    height: 32/75rem;
  }
  &__icon {
    flex-shrink: 0;
    width: 16/37.5rem;
    height: 16/37.5rem;
    margin-right: 2/37.5rem;
  }
  &__left-tip {
    min-width: 0;
  }
  &__divider {
    flex-shrink: 0;
    margin: 0 8/37.5rem 0 4/37.5rem;
    width: 1/37.5rem;
    height: 17/37.5rem;
    background: #fff;
    opacity: 0.3;
  }
}
</style>
