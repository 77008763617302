<template>
  <!-- 以店铺维度的商品行信息.doc= https://arc.sheincorp.cn/docs/2743204757322610688 -->
  <StoreProductRowGroup
    v-expose="getExposedData()"
    :groupIndex="groupIndex"
    @click="handleVisibleDetail"
  />
</template>

<script setup>
import {
  provide,
  toRef,
  computed,
  inject,
  watch,
  onMounted,
  nextTick,
} from 'vue'

import StoreProductRowGroup from 'public/src/pages/checkout_new/pages/shopping_bags/components/store_product_row_group/Index.vue'
import useShoppingBagReport from 'public/src/pages/checkout_new/pages/shopping_bags/hooks/useShoppingBagReport.js'
import { useMapState } from '@/public/src/pages/checkout_new/hooks/store'
import { vExpose } from 'public/src/pages/common/analysis/directive'

const props = defineProps({
  groupIndex: {
    type: Number,
    default: 0,
  },
})
const storeGroupIndex = toRef(props, 'groupIndex')
provide('storeGroupIndex', storeGroupIndex)

// mall 索引
const mallIndex = inject('mallIndex', 0)
const emit = defineEmits(['handleVisibleBagsDetail'])

// --------- useMap_**** --------
const { shoppingBagsState } = useMapState(['shoppingBagsState'])

const {
  getEvoluTagExposedData,
  handleBeltExposed,
  handleShoppingBagExposed,
  handleRankTagSceneReport,
} = useShoppingBagReport()

// ----------- computed ---------
const currentMall = computed(
  () => shoppingBagsState.value?.bagsInfo?.mall?.[mallIndex] || {},
)

// ------------ method ----------
const handleVisibleDetail = () => {
  emit('handleVisibleBagsDetail', {
    event,
    source: 'button',
    groupIndex: props.groupIndex,
  })
}

const getExposedData = () => {
  if (currentMall.value.isRenderSingleProduct) {
    return getEvoluTagExposedData(currentMall.value.all)
  }
}

// ------------- watch ----------
watch(
  () => currentMall.value,
  mallInfo => {
    handleBeltExposed(mallInfo.all)
    handleShoppingBagExposed(mallInfo)
  },
  {
    deep: true,
  },
)

onMounted(() => {
  handleRankTagSceneReport()
  nextTick(() => {
    handleBeltExposed(currentMall.value.all)
    handleShoppingBagExposed(currentMall.value)
  })
})
</script>
