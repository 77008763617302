<template>
  <s-popover
    :class="activePopoverClassName"
    v-bind="activePopoverDomKey"
    :model-value="showActivityPopover"
    fixDisappearPosition
    :prop-style="{
      ...activePopoverStyle,
      '--footer-lure-popover-top': getActivityPopoverTop,
    }"
    @opened="openActivityPopover"
    @closed="closeActivityPopover"
    @icon-close="handlePopoverCloseIconClick"
  >
    <!-- popover弹出内容 -->
    <ActivityPopover
      v-if="!isSimple && !isOnlyRenderNormalBtn"
      class="not-fsp-element"
      :showLurePointPopoverData="footerState.showLurePointPopoverData"
      :activityPopoverType="activityPopoverType"
    />

    <template #reference>
      <BtnContainer
        :isSimple="isSimple"
        :isOnlyRenderNormalBtn="isOnlyRenderNormalBtn"
        :forceShowFsTips="forceShowFsTips"
        :c2pInfo="c2pInfo"
        @handleClickNormalBtn="handleClickNormalBtn"
      />
    </template>
  </s-popover>
</template>

<script setup name="ButtonWrapper">
import { ref, computed, watch, nextTick, onMounted } from 'vue'
import { SPopover } from '@shein-aidc/sui-popover/mobile'
import { daEventCenter } from '@shein-aidc/basis-sa-event-center'
import { prefixCls } from '@/public/src/pages/checkout_new/pages/footer/utils'
import { useMapState, useMapGetters, useMapMutations } from '@/public/src/pages/checkout_new/hooks/store'
import useFreeShipping from '@/public/src/pages/checkout_new/pages/footer/hooks/useFreeShipping.js'
import { exposeScenesAbtAggregatorBff as exposeScenesAbtAggregator } from '@/public/src/pages/checkout_new/utils/EventAggregator.js'
import { useLastLureBubble } from '@/public/src/pages/product_app/footer/cart/hooks/useLastLureBubble.js'

import {
  ActivityPopoverTypeMap,
  analyzeBenefitTypeMap
} from '@/public/src/pages/checkout_new/pages/footer/utils/constant'
import BtnContainer from './btn_container/Index.vue'
import ActivityPopover from './activity_popover/Index.vue'


const props = defineProps({
  // 强制展示角标利诱点
  forceShowFsTips: {
    type: Boolean,
    default: false
  },
  // 仅仅展示 Btn 按钮，不展示浮窗那些
  isSimple: {
    type: Boolean,
    default: false
  },
  // 仅仅展示NormalBtn，不展示其他
  isOnlyRenderNormalBtn: {
    type: Boolean,
    default: false
  },
  c2pInfo: {
    type: Object,
    default: () => ({})
  }
})

const emit = defineEmits(['handleClickNormalBtn'])

// 是否展示“免邮提示”
const { visibleFsTips } = useFreeShipping()

const activePopoverDomKey = {
  'show-close-icon': true,
  'placemen': 'top-end',
  'trigger': 'unset',
  'outside-close': false,
  'append-to-body': false
}

const activePopoverStyle = {
  minWidth: '132px',
  background: `rgba(0, 0, 0, 0.8)`,
  maxWidth: '300px'
}

// ---------- ref ----------
const isMounted = ref(false)
const isShowActivityPopover = ref(false) // 控制气泡的展示开关
const isManualClosePopover = ref(false) // 是否手动关闭气泡
const activePopoverTimer = ref(null) // 隐藏
const activityPopoverList = ref([]) // 活动类型集合
const activePopoverShowDuration = ref(8000) // 气泡的展示时间，默认展示的时间是8s
const activityPopoverType = ref('') // 当前气泡要展示的气泡类型
const activityPopoverCache = ref([]) // 当前气泡展示过的类型，存储在sessionStorage
const alreadyExposeScene = ref(false) // 控制埋点展示的时机
const activeTypeCollectInfo = ref({})
const activityPopoverCount = ref(0) // 气泡展示次数
const activePopoverTimerShow = ref(null) // 显示
const activityPopoverSwitch = ref(false) // 气泡总开关, 只受定时器控制
const dialogObserver = ref(null)
const isDialogShow = ref(false) // 是否有弹窗


// -------- useMap_**** --------
const { footerState, checkoutParams, GB_cssRight, abtState, checkout } = useMapState(['footerState', 'checkoutParams', 'GB_cssRight', 'abtState', 'checkout'])
const { getActivityPopoverData } = useMapGetters(['getActivityPopoverData'])
const { updateFooterState, updateFloatingInfo } = useMapMutations(['updateFooterState', 'updateFloatingInfo'])

// --------- computed ----------
const activePopoverClassName = computed(() => {
  return [
    'not-fsp-element',
    prefixCls('btn-wrapper'),
    prefixCls('btn-wrapper_popover-layout'),
    GB_cssRight.value && prefixCls('btn-wrapper_ar-layout')
  ]
})

// 获取气泡的top值，由于按钮上方【free shipping】标签动态显隐，则这里的top不能是固定的值
const getActivityPopoverTop = computed(() => {
  return !!visibleFsTips.value ? `${-154 / 75}rem` : `${-136 / 75}rem`
})

// 返回当前利诱点
const activeLurePoint = computed(() => {
  return getActivityPopoverData.value.map(_ => _.type)
})

// 控制是否打开当前气泡
const showActivityPopover = computed(() => {
  return isMounted.value && !props.isSimple && !props.isOnlyRenderNormalBtn && isShowActivityPopover.value && !isManualClosePopover.value && !!activityPopoverType.value
})

// 打开活动的多个条件
const showActivityMultipleConditions = computed(() => {
  return [footerState.value.floatingInfo.initDone, footerState.value.floatingInfo.alreadyShow, activeLurePoint.value, isMounted.value]
})

// 气泡展示策略
const getPlaceOrderLurePointInfo = computed(() => {
  return checkout.value?.placeOrderLurePointInfo || {}
})

// 重置倒计时标志位
const getResetActivePopoverTime = computed(() => {
  return footerState.value.floatingInfo?.isResetActivePopoverTime || false
})

// ---------- method -----------
/**
 * @description: 设置缓存
 * @return {*}
 */
const setActivityPopoverCache = () => {
  if (!activityPopoverType.value) {
    return
  }

  let cacheData = {}
  const popoverList = typeof sessionStorage.getItem('activity_popover_show_histories') === 'string' ? JSON.parse(sessionStorage.getItem('activity_popover_show_histories')) : []
  const { priority, status, abtName, commonCacheKey } = activeTypeCollectInfo.value || {}

  if (!!status && priority === 2 && abtName === 'different') {
    const index = popoverList.findIndex(item => item.type === commonCacheKey)
    index > -1 && popoverList.splice(index, 1)

    cacheData = {
      page: 'checkoutPage',
      activityType: activityPopoverType.value, // 活动类型，比如 coupon
      type: commonCacheKey // 活动对应的数字缓存
    }
  } else if (!popoverList.some(item => item.page === 'checkoutPage' && item.activityType === activityPopoverType.value)) {
    cacheData = {
      page: 'checkoutPage',
      activityType: activityPopoverType.value, // 活动类型，比如 coupon
      type: commonCacheKey // 活动对应的数字缓存
    }
  }
  if (cacheData?.activityType) {
    popoverList.push(cacheData)
    sessionStorage.setItem('activity_popover_show_histories', JSON.stringify(popoverList))
    activityPopoverCache.value = popoverList
    activeTypeCollectInfo.value = {
      status: false, commonCacheKey: '', abtName: '', priority: -1
    }
  }
}

/**
 * @description: 获取缓存
 * @return {*}
 */
const getActivityPopoverCache = () => {
  if (!isMounted.value) return
  const popoverList = typeof sessionStorage.getItem('activity_popover_show_histories') === 'string' ? JSON.parse(sessionStorage.getItem('activity_popover_show_histories')) : []
  activityPopoverCache.value = popoverList
}

/**
 * @description: 处理首次的曝光埋点
 * @return {*}
 */
const handleExposeScene = () => {
  if (!isMounted.value || alreadyExposeScene.value) {
    return
  }
  if (!!activityPopoverType.value) {
    exposeScenesAbtAggregator.addEvent({
      id: 'expose_scenesabt:simple',
      data: {
        scenes: 'placeorderBenefits',
        benefits_type: analyzeBenefitTypeMap[activityPopoverType.value]
      }
    })
    alreadyExposeScene.value = true
  }
}

/**
 * @description: 控制气泡是否是打开还是关闭
 * @param {Boolean} flag 标志位，用于及时关闭气泡，清除定时器
 * @return {*}
 */
const handleActivityPopoverBehavior = (flag = false) => {
  if (flag) {
    isShowActivityPopover.value = false
    clearTimeout(activePopoverTimer.value)
    return
  }
  if (!activityPopoverCache.value?.some(item => item.page === 'checkoutPage' && item.activityType === activityPopoverType.value)){
    isShowActivityPopover.value = true
  }
}

/**
 * @description: 利诱点气泡显示后的操作
 * @return {*}
 */
const openActivityPopover = () => {
  const { promotionType, commentTagLureInfo } = footerState.value.showLurePointPopoverData || {}
  const isCommentsTagType = activityPopoverType.value === ActivityPopoverTypeMap.CommentsTag

  let strategy_type = '-'
  const lastCart = activityPopoverCache.value.filter(item => item.page === 'cartPage')?.at(-1)
  const currentCommonCacheKey = getActivityPopoverData.value?.find(i => i.type === activityPopoverType.value)?.commonCacheKey

  if (lastCart && lastCart.type === currentCommonCacheKey) {
    strategy_type = 'same'
  } else if (!activityPopoverCache.value.some(item => item.type === currentCommonCacheKey)) {
    strategy_type = 'diff'
  }

  const data = {
    strategy_type,
    benefits_type: analyzeBenefitTypeMap[activityPopoverType.value],
    promotiontype: promotionType ? `promotion_${promotionType}` : 'promotion_25'
  }

  if (isCommentsTagType && commentTagLureInfo) {
    delete data.promotiontype
    data.comments_tag = commentTagLureInfo.appBuriedPoint
  }
  daEventCenter.triggerNotice({
    id: 'expose_placeorderBenefits:simple',
    data
  })
  setActivityPopoverCache()

  activePopoverTimer.value = setTimeout(() => {
    isShowActivityPopover.value = false
    activityPopoverSwitch.value = false
  }, activePopoverShowDuration.value)
}

/**
 * @description: 手动关闭气泡
 * @return {*}
 */
const handlePopoverCloseIconClick = () => {
  isManualClosePopover.value = true
  isShowActivityPopover.value = false
  const { commentTagLureInfo } = footerState.value.showLurePointPopoverData || {}
  const isCommentsTagType = activityPopoverType.value === ActivityPopoverTypeMap.CommentsTag
  const data = {}

  if (isCommentsTagType && commentTagLureInfo) {
    data.comments_tag = commentTagLureInfo.appBuriedPoint
  }
  daEventCenter.triggerNotice({
    id: 'click_placeorderBenefits:simple',
    data
  })
}

/**
 * @description: 关闭气泡后的操作，如定时器自动关闭
 * @return {*}
 */
const closeActivityPopover = () => {
  isShowActivityPopover.value = false
  const { commentTagLureInfo } = footerState.value.showLurePointPopoverData || {}
  const isCommentsTagType = activityPopoverType.value === ActivityPopoverTypeMap.CommentsTag
  const data = {}

  if (isCommentsTagType && commentTagLureInfo) {
    data.comments_tag = commentTagLureInfo.appBuriedPoint
  }
  daEventCenter.triggerNotice({
    id: 'click_placeorderBenefits:simple',
    data
  })
}

const handleClickNormalBtn = () => {
  emit('handleClickNormalBtn')
}

/**
 * @description: 获取气泡组件、控制打开气泡等
 * @param {String} type 气泡的类型 promotion｜lowStock｜coupon｜TaskPoints｜lowestPrice｜SHEINClubPromotion｜SaverPromotion
 * @return {*}
 */
const handleActivityPopoverType = (type) => {
  if (!activityPopoverList.value?.includes(type) || isManualClosePopover.value) {
    return
  }

  // 当前气泡如果打开的话，需要关闭气泡，重新打开
  if (showActivityPopover.value && type !== activityPopoverType.value) {
    const specialLureLists = [ActivityPopoverTypeMap.SHEINClubPromotion, ActivityPopoverTypeMap.SaverPromotion]
    const newTypeInSpecialLureLists = specialLureLists.includes(type)
    const oldTypeInSpecialLureLists = specialLureLists.includes(activityPopoverType.value)

    if ((newTypeInSpecialLureLists && !oldTypeInSpecialLureLists) || (!newTypeInSpecialLureLists && oldTypeInSpecialLureLists)) {
      handleActivityPopoverBehavior(true)
    } else if (!newTypeInSpecialLureLists && !oldTypeInSpecialLureLists) {
      return
    }
  }

  const showLurePointPopoverData = getActivityPopoverData.value.find(_ => _.type === type) || {}
  
  
  updateFooterState({ showLurePointPopoverData })
  activePopoverShowDuration.value = (showLurePointPopoverData?.durationSecond || 8) * 1000
  nextTick(() => {
    activityPopoverType.value = type
    handleActivityPopoverBehavior()
    handleExposeScene()
  })
}

/**
 * @description: 寻找当前合适的type
 * @param {Array} activeData activeLurePoint数据
 * @return {*}
 */
const handleFindActivityType = (activeData) => {
  let type = ''
  const primeClub = ActivityPopoverTypeMap.PrimeClub
  const saverCard = ActivityPopoverTypeMap.SaverCard
  getActivityPopoverCache()

  if (activeData.includes(primeClub) && !!checkoutParams.value?.prime_product_code && !activityPopoverCache.value?.some(item => item.page === 'checkoutPage' && item.activityType === primeClub)) {
    type = primeClub
  } else if (activeData.includes(saverCard) && !!checkoutParams.value?.save_card_product_code && !activityPopoverCache.value?.some(item => item.page === 'checkoutPage' && item.activityType === saverCard)) {
    type = saverCard
  } else {
    if (abtState.value?.footerBtnBenefitLinkage === 'same') {
      // 展示与购物车中最后一次展示的气泡类型相同的气泡
      const { getLastLureBubble } = useLastLureBubble()
      const lastCartKey = getLastLureBubble()
      const lastSameType = getActivityPopoverData.value?.find(i => lastCartKey?.type == i?.commonCacheKey)

      if (lastSameType?.type) {
        activeTypeCollectInfo.value = {
          abtName: 'same',
          status: true,
          priority: 1,
          commonCacheKey: lastSameType?.commonCacheKey,
          type: lastSameType?.type
        }
        type = lastSameType.type
      } else {
        type = activeData.find(element => !activityPopoverCache.value?.some(item => item.page === 'checkoutPage' && item.activityType === element))
      }
    } else if (abtState.value?.footerBtnBenefitLinkage === 'different') {
      // 优先级一：展示链路上没有展示过的且当前页面可以展示的利诱点
      const currentCanShowType = getActivityPopoverData.value?.find(i => !activityPopoverCache.value?.some(obj => obj?.type === i?.commonCacheKey))

      if (currentCanShowType?.type) {
        activeTypeCollectInfo.value = {
          abtName: 'different',
          status: true,
          priority: 1,
          commonCacheKey: currentCanShowType?.commonCacheKey,
          type: currentCanShowType.type
        }
        type = currentCanShowType?.type
      } else {
        // 优先级二：从当前页面可展示的利诱点中，选择在整个链路上最早展示过的利诱点
        const showEarliestType = getActivityPopoverData.value?.find(i => activityPopoverCache.value?.some(obj => obj?.type === i?.commonCacheKey))

        if (showEarliestType?.type) {
          activeTypeCollectInfo.value = {
            abtName: 'different',
            status: true,
            priority: 2,
            commonCacheKey: showEarliestType?.commonCacheKey,
            type: showEarliestType.type
          }
          type = showEarliestType.type
        }
      }
    } else {
      type = activeData?.find(element => !activityPopoverCache.value?.some(item => item.page === 'checkoutPage' && item.activityType === element))
    }
  }

  if (!activeTypeCollectInfo.value?.status && type) {
    activeTypeCollectInfo.value = {
      abtName: '',
      status: true,
      priority: -1,
      commonCacheKey: getActivityPopoverData.value?.find(i => i.type === type)?.commonCacheKey,
      type: type
    }
  }
  // console.log('🐬🐬🐬 es: type', type)
  handleActivityPopoverType(type)
}

// S-drawer
const observerDialog = () => {
  return new Promise((resolve) => {
    const doms = document.getElementsByClassName('sui-drawer__container')
    if (dialogObserver.value) {
      dialogObserver.value?.disconnect()
    }
    dialogObserver.value = new IntersectionObserver((entries) => {
      isDialogShow.value = entries.some(item => item?.isIntersecting || false)
      resolve(isDialogShow.value)
    }, {
      threshold: 1
    })
    if (doms.length > 0) {
      Array.from(doms).forEach(item => dialogObserver.value.observe(item))
    } else {
      resolve(false)
    }
  })
}

// ---------- watch -----------
// 新逻辑
watch(() => [showActivityMultipleConditions.value, activityPopoverSwitch.value, isDialogShow.value], ([[initDone, alreadyShow, activeLurePoint, isMounted], isPopoverSwitch, isDialogShow]) => {
  if (!abtState.value?.onCheckoutBubbleStrategy || props?.isSimple) return
  const type = footerState.value.floatingInfo.type
  if (!isMounted || ['autoUse'].includes(type) || !isPopoverSwitch || isDialogShow) {
    // console.log('👹👹👹 es: error')
    return
  }
  // console.log('🐬🐬🐬 ✅✅✅ es: run', isDialogShow, !isPopoverSwitch)
  const isHaveDisplayedFloatInfo = !!initDone ? (!type || (!!type && !!alreadyShow)) : false
  // 只有在底部悬浮区域（如自动用券提醒等等）处理完成后，或者显示关闭过后才会唤醒此利诱点气泡
  if (isHaveDisplayedFloatInfo && activeLurePoint && !!activeLurePoint.length) {
    const isSatisfyFindActivityType = !props.isSimple && !props.isOnlyRenderNormalBtn
    if (isSatisfyFindActivityType) {
      handleFindActivityType(activeLurePoint)
    }
  }
})


watch(() => activityPopoverSwitch.value, (isShow) => {
  if (!abtState.value?.onCheckoutBubbleStrategy || props?.isSimple) return
  nextTick(() => {
    if (isShow) return
    // console.log('🐬🐬🐬 es: watch 2 已经弹窗', 3 - activityPopoverCount.value)
    const timeout = getPlaceOrderLurePointInfo.value?.inactivityThreshold || 8
    // 疲劳值 > 0 + 不能有弹窗
    if (activityPopoverCount.value > 0) {
      activePopoverTimerShow.value = setTimeout(async() => {
        await observerDialog()
        // console.log('🐬🐬🐬 es: isShowDialog', isShow)
        activityPopoverSwitch.value = true
        activityPopoverCount.value -= 1
      }, timeout * 1000)
    }
  })
}, {
  immediate: true
})

watch(() => getResetActivePopoverTime.value, (val) => {
  if (!abtState.value?.onCheckoutBubbleStrategy || props?.isSimple) return
  if (val) {
    // 只有计价才会取消定时器
    clearTimeout(activePopoverTimerShow.value)
    isShowActivityPopover.value = false
    activityPopoverSwitch.value = false
    updateFloatingInfo({
      isResetActivePopoverTime: false
    })
  }
})

// 旧逻辑
watch(() => showActivityMultipleConditions.value, ([initDone, alreadyShow, activeLurePoint, isMounted]) => {
  if (abtState.value?.onCheckoutBubbleStrategy) return
  if (!isMounted && abtState.value?.onCheckoutBubbleStrategy) {
    return
  }
  const type = footerState.value.floatingInfo.type
  const isHaveDisplayedFloatInfo = !!initDone ? (!type || (!!type && !!alreadyShow)) : false
  
  // 只有在底部悬浮区域（如自动用券提醒等等）处理完成后，或者显示关闭过后才会唤醒此利诱点气泡
  if (isHaveDisplayedFloatInfo && activeLurePoint && !!activeLurePoint.length) {
    const isSatisfyFindActivityType = !props.isSimple && !props.isOnlyRenderNormalBtn
    if (isSatisfyFindActivityType) {
      handleFindActivityType(activeLurePoint)
    }
  }
}, {
  immediate: true
})


onMounted(() => {
  if(props?.isSimple) return // 礼品卡也会用到 footer 组件, 气泡又会监听礼品卡, 因此相互受影响了, 在 simple 场景不走弹窗
  isMounted.value = true
  if (abtState.value?.onCheckoutBubbleStrategy) {
    // 新逻辑
    activityPopoverCount.value = getPlaceOrderLurePointInfo.value?.checkoutMaxShowCount || 3  // 设置疲劳值 
    if(!['same', 'different'].includes(abtState.value?.footerBtnBenefitLinkage) ) {
      sessionStorage.clear('activity_popover_show_histories')
    }
  }
  
  activityPopoverList.value = Object.entries(ActivityPopoverTypeMap)?.map(([, v]) => v)
  
  getActivityPopoverCache()
  if (!!activityPopoverCache.value?.length) {
    isShowActivityPopover.value = false
  }
})


</script>

<style lang="less">
@import '../../variables.less';

.@{prefixCls}btn-wrapper {
  flex: 1;
  position: relative;
  background: #fff;

  &_popover-layout {
    flex: 1;
    min-width: 0;

    .sui-popover__content {
      border-radius: 4/75rem;
      top: var(--footer-lure-popover-top) !important;
    }

    .sui-popover__content-body {
      background: unset;
      padding: 12/75rem 32/75rem 12/75rem 12/75rem;
    }

    .sui-popover__content-arrow {
      right: 16px !important;
    }
    .sui-popover__close-arrow {
      display: flex;
      align-items: center;
      height: 32/75rem;
      /* stylelint-disable-next-line declaration-no-important */
      font-size: 16px !important;
    }
  }
}
</style>
