<template>
  <TransitionGroup
    :id="isRemoveTargetId ? '' : orderSectionId(data?.[0], storeGroupIndex)"
    tag="div"
    :name="transitionGroupName"
    :class="[prefixCls('multiple-product_wrapper')]"
  >
    <ProductItem
      v-for="(item, index) in data.slice(0, isClient ? data.length : 5)"
      :key="`${item?.id}_${index}`"
      :data="item"
      :index="index"
    />
  </TransitionGroup>
</template>
<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'MultiProduct',
})
</script>
<script setup>
import { provide, onMounted, inject } from 'vue'
import ProductItem from './components/ProductItem.vue'
import {
  orderSectionId,
  prefixCls,
} from '@/public/src/pages/checkout_new/pages/shopping_bags/utils'
import useIsClient from '@/public/src/pages/checkout_new/hooks/useIsClient.js'
const { isClient } = useIsClient()

// 避免首次渲染时购物袋元素参与动画效果
let transitionGroupName = ''

const props = defineProps({
  data: {
    type: Array,
    default: () => [],
  },
  // 是否展示默认弹窗
  isDefaultDoubleConfirm: {
    type: Boolean,
    default: false,
  },
  // 是否移除id
  isRemoveTargetId: {
    type: Boolean,
    default: false,
  },
})

provide('isDefaultDoubleConfirm', props.isDefaultDoubleConfirm)
const storeGroupIndex = inject('storeGroupIndex', undefined)

onMounted(() => {
  transitionGroupName = 'sbs_multi_product_list'
})
</script>
<style lang="less">
@import '../../variables.less';

.@{prefixCls}multiple-product_wrapper {
  display: flex;
  align-items: stretch;
  position: relative;
  padding-right: 0;
  overflow-x: scroll;
  // overflow-y: hidden;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}

.shopping-bag-dynamic__body {
  .checkout-sbs__mp__item:first-child {
    width: 0;
    opacity: 0;
    // height: 0;
  }

  .goodsli-out-mask {
    opacity: 0;
  }
}
.shopping-bag-dynamic__body-mask {
  .goodsli-out-mask {
    opacity: 1;
  }
}

.shopping-bag-dynamic__body-item {
  width: 0;
  opacity: 0;
}

/* 1. 声明过渡效果 */
.sbs_multi_product_list-move,
.sbs_multi_product_list-enter-active,
.sbs_multi_product_list-leave-active {
  transition: all 0.3s cubic-bezier(0.55, 0, 0.1, 1) 0.3s;
}

/* 2. 声明进入和离开的状态 */
.sbs_multi_product_list-enter-from,
.sbs_multi_product_list-leave-to {
  opacity: 0;
}

/* 3. 确保离开的项目被移除出了布局流
      以便正确地计算移动时的动画效果。 */
.sbs_multi_product_list-leave-active {
  position: absolute;
  z-index: -1;
}
</style>
