<template>
  <div :class="['xtra-radio-selected', { 'single-selected-symble': showSymbleStyle }]">
    <!-- 简化样式 -->
    <template v-if="showSymbleStyle">
      <div
        :class="['single-selected-wrapper']"
        @click.stop.prevent="handleXtraItemClick(selectedList[0])"
      >
        <s-radio
          :model-value="currentCode"
          :toggle="true"
          :disabled="notSupport"
          :label="selectedList[0]?.productCode"
          :gap="16/75 + 'rem'"
        />
      </div>
      <div :class="['single-selected-wrapper__right', {'right-wrapper-disabled': notSupport}]">
        <span :class="['special-price', 'special-price-highlight']">{{ selectedList[0]?.specialPrice }}</span>
        <del
          v-if="selectedList[0].isShowDiscount && !selectedList[0].labelText" 
          class="del-price" 
        >{{ selectedList[0].originPrice }}</del>
        <span
          v-if="selectedList[0].labelText" 
          :class="['discount-label', 'discount-symble']"
          @click="clickDiscountTips($event, selectedList[0].productCode)"
        >
          {{ selectedList[0].labelText }}
        </span>
      </div>
    </template>

    <!-- 正常样式 -->
    <template v-else>
      <!-- 外页展示两个产品包的选项 -->
      <template v-if="isHasAutoRenewedBag">
        <swiper-container class="j-mutiple-selected-radio mutiple-selected-wrapper">
          <swiper-slide
            v-for="(item, index) in selectedList"
            :key="index"
            class="item-selected"
            :class="showLabel(item) ? 'item-selected-with-tab': ''"
            @click.stop.prevent="handleXtraItemClick(item)"
          >
            <div
              class="item-selected"
              :class="showLabel(item) ? 'item-selected-with-tab': ''"
              :style="labelStyle(item)"
            >
              <!-- 自动续费包引导切换快捷支付方式 -->
              <AutoPayBubble
                v-show="item.autoRenewalBubble.isShowAutoRenewdDubble && !bubbleDown"
                ref="autoPaymenodsGuideRef"
                :productCode="item.productCode"
                :logoList="item.autoRenewalBubble.logoList"
                :bubbleText="item.autoRenewalBubble.bubbleText"

                :style="{
                  'position': 'absolute',
                  'bottom': '0.2rem',
                  'opacity': notSupport ? '0.3' : 1
                }"
                @bubbleClose="() => { bubbleDown = true }"
                @handlePayMethodsListDrawerShow="autoRenewdDrawerShow"
              />
              <s-radio
                :model-value="currentCode"
                :label="item.productCode"
                :gap="8/75 + 'rem'"
                :disabled="notSupport"
              />
              <div :class="notSupport ? 'item-selected__right-wrapper-disabled': ''">
                <div class="item-selected__right-price-wrapper">
                  <span :class="['special-price', { 'special-price-highlight': item.isShowSpecialPriceHighColor }]">{{ item.specialPrice }}</span>
                  <del
                    v-if="item.isShowDiscount" 
                    class="del-price" 
                  >{{ item.originPrice }}</del>
                </div>
                <div
                  v-if="showLabel(item)" 
                  :class="['discount-label', {'active-label': virCouponTipsShow(item)}]"
                  @click="clickDiscountTips($event, item.productCode)"
                >
                  {{ item.labelText }}
                </div>
              </div>
            </div>
          </swiper-slide>
        </swiper-container>
      </template>
      <!-- 单个产品包的选项，新旧样式都有用到 -->
      <div
        v-else
        :class="['single-selected-wrapper', 'saver-new-style']"
        :style="labelStyle(selectedList[0])"
        @click.stop.prevent="handleXtraItemClick(selectedList[0])"
      >
        <s-radio
          :model-value="currentCode"
          :toggle="true"
          :disabled="notSupport"
          :label="selectedList[0]?.productCode"
          :gap="16/75 + 'rem'"
        />
        <div :class="['single-selected-wrapper__right', {'right-wrapper-disabled': notSupport}]">
          <span :class="['special-price', { 'special-price-highlight': selectedList[0]?.isShowSpecialPriceHighColor }]">{{ selectedList[0]?.specialPrice }}</span>
          <del
            v-if="selectedList[0].isShowDiscount" 
            class="del-price" 
          >{{ selectedList[0].originPrice }}</del>
          <span
            v-if="showLabel(selectedList[0])" 
            :class="['discount-label', {'active-label': virCouponTipsShow(selectedList[0]) }]"
            @click="clickDiscountTips($event, selectedList[0].productCode)"
          >
            {{ selectedList[0].labelText }}
          </span>
        </div>
      </div>
    </template>
  </div>
</template>


<script setup>
import { SRadio } from '@shein-aidc/sui-radio/mobile'
import { computed, onMounted, nextTick, ref, watch } from 'vue'
import { template } from '@shein/common-function'
import AutoPayBubble from './AutoPayBubble.vue'
const SiteUID = ref(gbCommonInfo?.SiteUID || '')
import { transformProudctPackageStyle } from './shared/utils.js'

const emit = defineEmits(['clickDiscountTips', 'changeBagEvent', 'autoRenewdDrawerShow'])

const GB_cssRight = ref(gbCommonInfo?.GB_cssRight)

const props = defineProps({
  notSupport: {
    type: Boolean,
    default: false,
  },
  selectedCode: {
    type: String,
    default: ''
  },
  productPackageList: {
    type: Array,
    default: () => []
  },
  language: {
    type: Object,
    default: () => {}
  },
  styleConfig: {
    type: Object,
    default: () => {}
  },
  uiType: {
    type: String,
    default: ''
  },
  showSymbleStyle: {
    type: Boolean,
    default: false
  }
})

const bubbleDown = ref(false)

const currentCode = ref('')

// 需要做合规处理的国家
const isSpecialCountry = computed(() => SiteUID.value == 'pwfr' || ['mpl', 'pwde'].includes(SiteUID.value))

const labelText = (text, config) => {
  if (!!config?.action && !text.includes('>')) {
    return GB_cssRight.value ? '< ' + text : text + ' >' 
  }
  return text
}


// 产品包选项卡列表
// 价格、划线价、标签文案、是否展示标签、是否展示划线价、是否展示特殊价格高亮
const selectedList = computed(() => {
  const list = props.productPackageList.map(v => {
    const product = v.saveCardProductInfo
    const autoRenewal = product?.auto_renewal

    const text = labelText(product.reducePriceLabel?.text, product.reducePriceLabel)
    const symbleText = labelText(product.reducePriceLabelEntry?.text, product.reducePriceLabelEntry)

    return {
      productCode: product.saveCardProductCode,
      originPrice: product.priceLocalWithSymbol,
      specialPrice: product.specialPriceWithSymbol,
      styleConfig: product.style_info,
      labelText: props.showSymbleStyle ? symbleText : text,
      isShowSpecialPriceHighColor: isSpecialCountry.value ? false : +product.priceLocal > +product.specialPrice, // 是否到手价需要高亮
      isAutoRenewProduct: product.isAutoRenewProduct,
      reducePriceLabel: product.reducePriceLabel,
      isShowDiscount: (product.style_info?.showLinePrice || product.style_info?.showLinePrice == null) && +product.priceLocal > +product.specialPrice,
      autoRenewalBubble: autoRenewal?.pick_popup_show ? {
        bubbleText: template(+autoRenewal?.filteredPaymentList?.length, autoRenewal?.pick_popup_tip) || '',
        logoList: autoRenewal?.filteredPaymentList?.map(v => v.icon) || [],
        isShowAutoRenewdDubble: autoRenewal?.pick_popup_show,
      } : {
        isShowAutoRenewdDubble: false,
      }
    }
  })
  // 自动续费产品包排在前面
  return list.sort((a, b) => b.isAutoRenewProduct - a.isAutoRenewProduct)
})

const isHasAutoRenewedBag = computed(() => {  
  return props.productPackageList.findIndex(item => item.saveCardProductInfo.isAutoRenewProduct === '1') > -1
})


const itemStyleConfig = (styleInfo) => {
  return transformProudctPackageStyle(styleInfo)
}

const virCouponTipsShow = (item) => {
  return item.reducePriceLabel?.action == 'couponList'
}

const showLabel = (item) => {
  // 配置为 null 则默认展示
  // 配置为 false 则不展示
  return item.labelText && (item.styleConfig.showLabel || item.styleConfig.showLabel == null)
}

const labelStyle = (item) => {
  // 会费券 or 自动续费包不读取样式配置

  return virCouponTipsShow(item) || item.isAutoRenewProduct == 1 ? {
    '--price-color': itemStyleConfig(item?.styleConfig)?.priceColor,
    '--line-price-color': itemStyleConfig(item?.styleConfig)?.linePriceColor,
  } : {
    '--price-color': itemStyleConfig(item?.styleConfig)?.priceColor,
    '--line-price-color': itemStyleConfig(item?.styleConfig)?.linePriceColor,
    '--label-bg-color': itemStyleConfig(item?.styleConfig)?.labelBgColor,
    '--label-border-color': itemStyleConfig(item?.styleConfig)?.labelBorderColor,
    '--label-color': itemStyleConfig(item?.styleConfig)?.labelColor,
  }
}

/**
 * @description: 初始化swiper，如果不初始化，增加初始化属性，会导致滑动计算的时候，出现不符合预期的问题
 * @return {*}
 */
const initSwiper = () => {
  if (isHasAutoRenewedBag.value) {
    const xtraMutipleRadioSelectSwiper = document.querySelector('.j-mutiple-selected-radio')
    if (xtraMutipleRadioSelectSwiper) {
      Object.assign(xtraMutipleRadioSelectSwiper, {
        direction: 'horizontal',
        slidesPerView: 'auto',
      })
      xtraMutipleRadioSelectSwiper.initialize()
    }
  }
}

// 点击标签事件
const clickDiscountTips = (event, productCode) => {
  event.stopPropagation()  
  emit('clickDiscountTips', event, productCode)
}

// 自动续费弹窗事件
const autoRenewdDrawerShow = (productCode, isOnCouponDrawer) => {
  emit('autoRenewdDrawerShow', productCode, isOnCouponDrawer)
}

// 切包事件
const handleXtraItemClick = (item) => {
  const productCode = currentCode.value == item.productCode ? '' : item.productCode  
  if (!Boolean(productCode)) {
    // 组件内部处理 产品包取消状态，不依赖数据源
    // 包选中状态由外部传入，不依赖内部状态
    currentCode.value = ''
  }
  emit('changeBagEvent', productCode)
}

watch(() => props.selectedCode, (val) => {
  currentCode.value = val
})

onMounted(() => {
  nextTick(() => {
    initSwiper()
  })
})

</script>

<style lang="less" scoped>
.xtra-radio-selected {
  line-height: normal;
  .special-price {
    margin-right: 4/75rem;
    color: @sui_color_gray_dark1;
    .font-dpr(32px);
    font-weight: 700;
  }
  .special-price-highlight {
    color: var(--price-color, #F82854);
  }
  .del-price {
    margin-right: 8/75rem;
    color: var(--line-price-color, #959595);
    .font-dpr(28px);
  }
  .discount-label {
    display: inline-block;
    padding: 4/75rem 12/75rem;
    border-radius: 6px 0px;
    max-width: 100%;
    color: var(--label-color, #FFF);
    .font-dpr(20px);
    font-weight: 600;
    border: 1px solid;
    // border-color: var(--label-border-color);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    background: var(--label-bg-color, linear-gradient(270deg, #F82854 0%, #DE00F1 100%));
  }
  .discount-symble {
    background: linear-gradient(265deg, #FF3D6A 4.05%, #F437AE 95.95%);
    font-weight: 590;
  }
  .active-label {
    background: #FEE8ED;
    border: 1px solid #F74D81;
    color: #EB3662;
  }
  .right-wrapper-disabled {
    opacity: 0.3;
  }
  .mutiple-selected-wrapper {
    margin-top: 16/75rem;
    margin-right: -24/75rem;
    padding-left: 4/75rem;
    overflow-x: clip;
    z-index: 0;
    .item-selected {
      display: flex;
      align-items: flex-start;
      max-width: 574/75rem;
      /* stylelint-disable-next-line declaration-no-important */
      width: auto !important; // swiper需要定义一个宽度，覆盖原来swiper单项的宽度
      margin-right: 24/75rem;
      position: relative;
      &__right-wrapper-disabled {
        opacity: 0.3;
      }
      &__right-price-wrapper {
        margin-bottom: 6/75rem;
      }
    }
    .discount-label {
      max-width: 494/75rem;
    }
  }
  .single-selected-wrapper {
    display: flex;

    :deep(.sui-radio__check) {
      border: 1.5px solid #ccc;
    }
    &__right {
      display: flex;
      align-items: center;
      max-width: calc(100% - 0.6rem);
    }
  }
  .saver-new-style {
    margin-top: 16/75rem;
  }
  .select-xtra-mode {
    padding: 40/75rem 24/75rem 12/75rem;
    line-height: normal;
    &__item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
      padding: 16/75rem 24/75rem;
      border-radius: 8/75rem;
      border: 2/75rem solid var(---sui_color_gray_light3, #CCC);
      margin-bottom: 16/75rem;
      background: #FFF;
      width: 100%;
      box-sizing: border-box;
    }
    & > :last-child {
      margin-bottom: 0;
    }
    &__selected-item {
      border: 2/75rem solid #F82854;
      background: #FFEEF3;
    }
    &__item-disabled {
      opacity: 0.3;
    }
    &__item-fixation-height {
      height: 106/75rem;
    }
    &__item-tips {
      position: absolute;
      right: 0;
      top: -30/75rem;
      border-radius: 8/75rem;
      border: 2/75rem solid #F7B432;
      padding: 8/75rem 12/75rem;
      text-align: center;
      font-size: 12px;
      font-weight: 600;
      background: var(--label-bg-color, #FFF1BE);
      // border-color: var(--label-border-color, #F7B432);
      color: var(--label-color, #421609);

      .triangle-border {
        position: absolute;
        width: 100%;
        height: 120%;
        top: 0;
        left: 0;
        z-index: 0;
      }
      .triangle-line {
        position: absolute;
        top: 2/75rem;
        width: 100%;
        height: 100%;
        background: var(--label-border-color, #F7B432);
        clip-path: polygon(calc(100% - 26px) 83%, calc(100% - 14px) 83%, calc(100% - 20px) calc(100% + 1px), calc(100% - 20px) calc(100% + 1px));
        z-index: -1;
      }
      .triangle {
        width: 0;
        height: 0;
        width: 100%;
        height: 100%;
        background: var(--label-bg-color, #FFF1BE);
        clip-path: polygon(calc(100% - 25px) 83%, calc(100% - 15px) 83%, calc(100% - 20px) 100%, calc(100% - 20px) 100%);
      }
    }
  }
  .mode-item-left {
    // max-width: 452/75rem;
    text-align: left;
    margin-right: 26/75rem;
    flex-grow: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &__explain {
      color: #111;
      font-size: 16px;
      font-weight: 600;
    }
    &__subhead {
      margin-top: 4/75rem;
      color: var(---sui_color_gray_dark3, #767676);
      font-size: 10px;
      font-weight: 400;
    }
  }
  .mode-item-right {
    display: flex;
    flex-shrink: 0;
    overflow: hidden;
    white-space: nowrap;
    
    &__price-wrap {
      text-align: left;
      margin-right: 20/75rem;
    }
    &__arrive-price {
      font-size: 18px;
      font-weight: 700;
      color: var(--price-color, #F82854);
    }
    &__origin-price {
      margin-top: 4/75rem;
      color: var(--line-price-color, #959595);
      font-size: 12px;
    }
  }
}
.single-selected-symble {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 22/75rem;

  .del-price {
    .font-dpr(20px);
  }
}
.j-mutiple-selected-radio::part(container){
  overflow: visible !important;
}
</style>
