import { useMapActions, useMapState } from '@/public/src/pages/checkout_new/hooks/store'
import { SToast } from '@shein-aidc/sui-toast/mobile'
import { daEventCenter } from '@shein-aidc/basis-sa-event-center'


export const useAssetApply = () => {
  const { language } = useMapState(['language'])
  const { updateCheckoutData } = useMapActions(['updateCheckoutData'])
  const onApplyPoint = async ({ value, isClickApplyBtn, cb, scene }) => {
    try {
      const res = await updateCheckoutData({
        opts: {
          points: +value,
        },
        scene: 'point',
        conf: {
          loading: true
        }
      })
      const code = +res.code
      const realUsedValue = +res.info?.point.used_point
      typeof cb === 'function' && cb(true, res, realUsedValue)
  
      daEventCenter.triggerNotice({
        id: 'click_points_apply:simple',
        data: {
          result: code === 0 ? '1' : '0'
        }
      })
  
      if(code === 0) {
        if(realUsedValue > 0 && isClickApplyBtn) {
          SToast(scene === 'retainDialog' ? (res.info?.use_point_success_tip || '') : language.value.SHEIN_KEY_PWA_16456)
        }
      }
    } catch (e) {
      typeof cb === 'function' && cb(false, e, 0)
    }
  }

  const onApplyWallet = async ({ value, isClickApplyBtn, cb, scene }) => {
    try {
      const res = await updateCheckoutData({
        opts: {
          use_wallet: isClickApplyBtn && +value > 0 ? 1 : 0,
          use_wallet_amount: +value
        },
        conf: {
          loading: true
        }
      })
      const code = +res.code
      const realUsedValue = +res.info?.wallet_balance?.walletPrice.amount
      typeof cb === 'function' && cb(true, res, realUsedValue)
  
      if(isClickApplyBtn) {
        daEventCenter.triggerNotice({
          id: 'click_popup_edit_wallet_apply:simple',
          data: {
            result: realUsedValue > 0 ? '1' : '0'
          }
        })
      }
  
      if(code === 0) {
        if(realUsedValue > 0 && isClickApplyBtn) {
          SToast(scene === 'retainDialog' ? (res.info?.use_wallet_success_tip || '') : language.value.SHEIN_KEY_PWA_16490)
        }
      }
    } catch (e) {
      typeof cb === 'function' && cb(false, e, 0)
    }
  }

  const onApplyGiftCard = ({ value }) => {
    appEventCenter.emit('giftcard-use', value)
  }

  const onApplyInstallment = ({ value }) => {
    appEventCenter.emit('scroll-to-payment-by-code', value)
  }
  return {
    onApplyPoint,
    onApplyWallet,
    onApplyGiftCard,
    onApplyInstallment
  }
}


