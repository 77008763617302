<template>
  <component
    :is="getComponentInfo.comp"
    :key="getComponentInfo.name"
    :index="getComponentInfo.name"
    :class="prefixCls('activity-popover')"
    :description="description"
    :activityPopoverType="activityPopoverType"
    :lureInfo="lureInfo"
    @handleActivityPopover="handleActivityPopover"
  />
</template>
<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'ActivityPopover'
})
</script>
<script setup name="ActivityPopover">
import { computed, defineAsyncComponent } from 'vue'
import { template } from '@shein/common-function'
import { prefixCls } from '@/public/src/pages/checkout_new/pages/footer/utils'
import { ActivityPopoverTypeMap } from '@/public/src/pages/checkout_new/pages/footer/utils/constant'

const NormalLayout = defineAsyncComponent(() =>
  import(/* webpackChunkName: "checkoutv2-activity-popover-normal-layout" */ './product_info/NormalLayout.vue')
)

const Coupon = defineAsyncComponent(() => import(/* webpackChunkName: "checkoutv2-activity-popover-coupon" */'./product_info/Coupon.vue'))

const SaverCard = defineAsyncComponent(() =>
  import(/* webpackChunkName: "checkoutv2-activity-popover-saver-card" */ './product_info/SaverCard.vue')
)

const TaskIntegral = defineAsyncComponent(() =>
  import(/* webpackChunkName: "checkoutv2-activity-popover-task-integral" */ './product_info/TaskIntegral.vue')
)

const lureInfoTypeMap = {
  [ActivityPopoverTypeMap.Promotion]: 'promotionLureInfo',
  [ActivityPopoverTypeMap.LowStock]: 'lowStockLureInfo',
  [ActivityPopoverTypeMap.LowStockA]: 'lowStockLureInfo',
  [ActivityPopoverTypeMap.LowStockB]: 'lowStockLureInfo',
  [ActivityPopoverTypeMap.Coupon]: 'couponLureInfo',
  [ActivityPopoverTypeMap.TaskIntegral]: 'taskPointInfo',
  [ActivityPopoverTypeMap.LowestPrice]: 'lowestPriceLureInfo',
  [ActivityPopoverTypeMap.PrimeClub]: 'sheinClubPromotionLureInfo',
  [ActivityPopoverTypeMap.SaverCard]: 'saverPromotionLureInfo',
  [ActivityPopoverTypeMap.CommentsTag]: 'commentTagLureInfo',
  [ActivityPopoverTypeMap.CheaperThanAddedCart]: 'cheaperLureInfo',
}

const ActivityPopoverCompName = {
  COUPON: 'Coupon',               // 优惠券
  TASKINTEGRAL: 'TaskIntegral',   // 任务积分利诱点
  SAVERCARD: 'SaverCard',         // 随单购超省卡
  NORMAL: 'NormalLayout',          // 其他，正常布局
}

const props = defineProps({
  activityPopoverType: {
    type: String,
    default: ''
  },
  showLurePointPopoverData: {
    type: Object,
    default: () => ({})
  }
})

const emit = defineEmits(['handleActivityPopoverBehavior'])

/* ---------- computed ---------- */
const lureInfo = computed(() => {
  return props.showLurePointPopoverData?.[lureInfoTypeMap[props.activityPopoverType]]
})

const getComponentInfo = computed(() => {
  if (ActivityPopoverTypeMap.Coupon === props.activityPopoverType) {
    return {
      name: ActivityPopoverCompName.COUPON,
      comp: Coupon
    }
  }
  if (ActivityPopoverTypeMap.SaverCard === props.activityPopoverType) {
    return {
      name: ActivityPopoverCompName.SAVERCARD,
      comp: SaverCard
    }
  }
  if (ActivityPopoverTypeMap.TaskIntegral === props.activityPopoverType) {
    return {
      name: ActivityPopoverCompName.TASKINTEGRAL,
      comp: TaskIntegral
    }
  }
  return {
    name: ActivityPopoverCompName.NORMAL,
    comp: NormalLayout
  }
})

const description = computed(() => {
  const guideTip = lureInfo.value?.guideTip || {}
  const replaceText = guideTip?.replaceText?.map(element => {
    const { color, strong, text = '' } = element || {}
    return `<span style="color: ${color};font-weight: ${Number(strong) == 1 ? 700 : 400}">${text}</span> `
  }) || []

  return template(...replaceText, guideTip?.tip)
})

// ----------- method -----------
const handleActivityPopover = options => {
  emit('handleActivityPopoverBehavior', options)
}
</script>

<style lang="less">
@import '../../../variables.less';

.@{prefixCls}activity-popover {
  display: flex;
  align-items: center;
}

.@{prefixCls}btn-wrapper {
  .sui-popover__content-body {
    background: unset;
    padding-top: 24/75rem;
    padding-bottom: 24/75rem;
  }

  .sui-popover__theme-dark::before {
    background: unset;
    border: 5px solid rgba(0, 0, 0, 0.8);
    border-left-color: transparent;
    border-top-color: transparent;
  }
}
</style>
