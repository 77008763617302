<template>
  <div
    class="checkout-prime-new__wrap"
    :style="{
      backgroundImage: customStyle.bgImg && `url(${customStyle.bgImg})`,
      '--club-title-color': customStyle.selectedRightColor,
      '--club-subtitle-color': customStyle.subTitleColor,
      '--club-title-place-color': customStyle.selectedRightPlaceColor,
      '--club-protocol-color': customStyle.protocolColor,
      '--club-protocol-hightlight-color': customStyle.protocolHightlightColor
    }"
  >
    <div class="checkout-prime__wrap-header">
      <div
        ref="primeLogoRef"
        class="checkout-prime__logo_new"
      >
        <img
          :src="customStyle.logoImg || primeLogoImg"
          width="96"
          height="18"
        />
      </div>

      <div class="checkout-prime__wrap-tips">
        {{ primeTips.top_right_tip }}
      </div>
    </div>

    <div
      class="checkout-prime__wrap-des_new"
      :class="{
        'no-support': notSupport,
        'single-product': primeProductList.length <= 1
      }"
      @click="viewMore"
      v-html="primeDeduceText"
    ></div>

    <RightPackageList
      :notSupport="notSupport"
      :primeProductList="primeProductList"
      :onPrimeCardClick="onPrimeCardClick"
      :closedPayBubbleIndexList="closedPayBubbleIndexList"
      :onBubbleClose="onBubbleClose"
      :onOfferLabelClick="handleOfferLabelClick"
      :selectedPrimeIndex="selectedPrimeIndex"
      :usedPrimeIndex="usedPrimeIndex"
      @changeActiveIndex="changeActiveIndex"
    />

    <div
      class="checkout-prime__wrap-policy"
      :class="{
        'no-support': notSupport,
        'single-product': primeProductList.length <= 1
      }"
      @click="clickTerm"
    >
      <span v-html="agreeText"></span>
      <Icon
        v-if="activePrime.auto_renewal?.is_auto_renew_product === '1'"
        name="sui_icon_doubt_16px_2"
        size="14px"
        @click.stop="openedRenewalTCDialog = true"
      />
    </div>

    <div
      v-if="notSupport"
      class="checkout-prime__wrap-tip"
    >
      <i class="suiiconfont sui_icon_caution_12px_2"></i>
      {{ notSupportTip }}
    </div>

    <RightPackageDetailDrawer
      :opened="detailDrawerProps.opened"
      :setOpened="detailDrawerProps.setOpened"
      :primeProductList="primeProductList"
      :clickTerm="clickTerm"
      :onClose="detailDrawerProps.onClose"
      :onOk="detailDrawerProps.onOk"
      :onOfferLabelClick="handleOfferLabelClick"
      :onProductSelect="detailDrawerProps.onProductSelect"
      :onCloseFromIcon="detailDrawerProps.onCloseFromIcon"
      :selectedPrimeIndex="selectedPrimeIndex"
      :usedPrimeIndex="usedPrimeIndex"
      :onBubbleClick="detailDrawerProps.onBubbleClick"
    />

    <!-- 自动续费协议弹窗 -->
    <RenewalTCDialog
      :visible="openedRenewalTCDialog"
      :content="activePrime?.auto_renewal?.auto_renew_content_tip"
      :activePrime="activePrime"
      @closeDialog="openedRenewalTCDialog = false"
    />

    <s-dialog
      v-if="discountDialogProps.discountDetail"
      v-model:visible="discountDialogProps.opened"
      show-close
      append-to-body
      lock-scroll
      @close="closeDiscountDetailDialog"
    >
      <template #top>
        <img
          :src="discountDialogHeaderBg"
          class="header-bg"
        />
      </template>
      <template #title>
        <div class="header-bg-container">
          {{ language.SHEIN_KEY_PWA_28751 }}
          <div class="pickColor_prime header-num">
            {{ discountDialogProps.discountDetail.totalSavePrice }}
          </div>
        </div>
      </template>
      <template #footer>
        <div
          v-if="discountDialogProps.discountDetail.discountList?.length"
          class="virXrt_container"
        >
          <div
            v-for="item in discountDialogProps.discountDetail.discountList"
            :key="item.name"
            class="virXrt_line"
          >
            <div class="virXrt-line_left">
              <span class="virXrt-line_leftTips">{{ item.name }}</span>
              <Icon
                v-if="item.tip"
                name="sui_icon_doubt_16px_2"
                size="16px"
                @click="clickDiscountTips(item.tip)"
              />
            </div>
            <div
              v-if="+item.isDiscount"
              class="pickColor_prime"
            >
              - {{ item.value }}
            </div>
            <div v-else>
              {{ item.value }}
            </div>
          </div>
        </div>
        <s-button
          :type="['primary']"
          width="100%"
          @click="closeDiscountDetailDialog"
        >
          {{ language.SHEIN_KEY_PWA_15312 }}
        </s-button>
      </template>
    </s-dialog>

    <SLazyMount>
      <s-dialog
        :visible="textTipDialogProps.opened"
        :show-close="true"
        append-to-body
        lock-scroll
        class="prime-limit__pop"
        @close="closeTextTipDialog"
      >
        <div>
          {{ textTipDialogProps.content }}
        </div>
        <template #footer>
          <s-button
            :type="['H72PX', 'primary']"
            :width="'100%'"
            @click="closeTextTipDialog"
          >
            {{ language.SHEIN_KEY_PWA_15312 }}
          </s-button>
        </template>
      </s-dialog>
    </SLazyMount>
  </div>
</template>

<script>
import { defineComponent, defineAsyncComponent, nextTick, onBeforeUnmount } from 'vue'
import { SLazyMount } from '@shein-aidc/sui-lazy-mount/mobile'
import { SButton } from '@shein-aidc/sui-button/mobile'
import { SDialog } from '@shein-aidc/sui-dialog/mobile'
import { template as _commonTemplate } from '@shein/common-function'
import RightPackageList from './RightPackageList.vue'
import { Icon } from '@shein-aidc/icon-vue3'
import RenewalTCDialog from './RenewalTCDialog.vue'
import { daEventCenter } from 'public/src/services/eventCenter/index'
import { transformProudctPackageStyle } from './utils'
import { handleTotalPriceAnimate } from '../../shared/utils.js'

const { PUBLIC_CDN, GB_cssRight, host, langPath, currency, currencies, lang } =
  gbCommonInfo

export default defineComponent({
  name: 'BFFClubProductPackage',
  components: {
    SLazyMount,
    SButton,
    SDialog,
    RightPackageDetailDrawer: defineAsyncComponent(() =>
      import(
        /* webpackChunkName: "bff-club-right-package-detail" */ './RightPackageDetailDrawer.vue'
      )),
    RightPackageList,
    Icon,
    RenewalTCDialog
  },
  inheritAttrs: false,
  props: {
    notSupportTip: {
      type: String,
      default: ''
    },
    primeProductList: {
      type: Array,
      default: () => []
    },
    onPrimeCardClick: {
      type: Function,
      default: () => {}
    },
    viewMore: {
      type: Function,
      default: () => {}
    },
    language: {
      type: Object,
      default: () => ({})
    },
    detailDrawerProps: {
      type: Object,
      default: () => ({
        opened: false,
        /** @type {(value: boolean) => void} */
        setOpened: () => {},
        onOk: () => {},
        onClose: () => {},
        onProductSelect: () => {},
        onCloseFromIcon: () => {},
        onBubbleClick: () => {}
      })
    },
    closedPayBubbleIndexList: {
      type: Array,
      default: () => []
    },
    onBubbleClose: {
      type: Function,
      default: () => {}
    },
    usedPrimeIndex: {
      type: Number,
      default: -1
    },
    selectedPrimeIndex: {
      type: Number,
      default: 0
    },
    styleInfo: {
      type: Object,
      default: () => ({})
    },
    styleType: {
      type: String,
      default: 'old'
    },
    primeTips: {
      type: Object,
      default: () => ({})
    },
    freeGiftInfo: {
      type: Object,
      default: null
    },
    gotoCouponList: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      PUBLIC_CDN,
      GB_cssRight,
      host,
      langPath,
      discountDialogHeaderBg: `${PUBLIC_CDN}/pwa_dist/images/checkout/prime_header_bg-33ca141c5d.png`,
      openedRenewalTCDialog: false,
      // 当前主屏幕上的产品包
      activePrime: {},
      isAnimationPlayed: false,
      discountDialogProps: {
        opened: false,
        discountDetail: null
      },
      textTipDialogProps: {
        opened: false,
        content: ''
      }
    }
  },
  provide() {
    return {
      language: this.language,
      styleType: this.styleType,
      primeTips: this.primeTips,
      styleInfo: this.styleInfo || {},
      freeGiftInfo: this.freeGiftInfo
    }
  },
  computed: {
    notSupport() {
      return !!this.notSupportTip
    },
    primeLogoImg() {
      return this.GB_cssRight
        ? 'https://img.ltwebstatic.com/images3_ccc/2024/06/13/a9/1718269813b098aeac5ed901365e244b55f1d6a668.webp'
        : 'https://img.ltwebstatic.com/images3_ccc/2024/06/13/54/171826806807a1e3dbaa9aec956261587581a39dee.webp'
    },
    primeDeduceText() {
      return this.primeTips.selected_right_tip?.replace(
        '<highlightPrice/>',
        `<i class="prime-deduce${
          this.primeTips.show_title_price_effect == 1
            ? ' prime-deduce-animation'
            : ''
        }">${this.primeTips.selected_right_tip_price_variable}</i>`
      )
    },
    agreeText() {
      const arcId = this.activePrime?.article_page_id
      let agreeText = this.template(
        `${host}${langPath}/SHEIN-CLUB-TERMS-AND-CONDITIONS-a-${arcId}.html`,
        this.language.SHEIN_KEY_PWA_26434?.replace(/color:\s?#2D68A8;/, '')
      )
      return agreeText
    },
    customStyle() {
      return transformProudctPackageStyle(
        this.styleInfo?.entrance_style,
        'base'
      )
    }
  },
  watch: {
    primeDeduceText(newVal) {
      if (newVal.includes('prime-deduce-animation')) {
        this.startPriceRolling()
      }
    }
  },
  mounted() {
    window?.addEventListener('scroll', this.handleScroll)

    onBeforeUnmount(() => {
      window.removeEventListener('scroll', this.handleScroll)
    })
  },
  methods: {
    template: _commonTemplate,
    closeDiscountDetailDialog() {
      this.discountDialogProps = {
        opened: false,
        discountDetail: null
      }
    },
    closeTextTipDialog() {
      this.textTipDialogProps = {
        opened: false,
        content: ''
      }
    },
    handleOfferLabelClick(productInfo, position) {
      if (this.notSupport || !productInfo.reducePriceLabel.action) return

      if (productInfo.reducePriceLabel.action === 'couponList') {
        // 用券列表
        daEventCenter.triggerNotice({
          daId: '1-11-1-187',
          extraData: {
            type: 'club',
            position: position || 'popup'
          }
        })
        this.gotoCouponList(productInfo)
      } else if (productInfo.reducePriceLabel.action === 'discountDetail') {
        // 优惠明细
        this.discountDialogProps = {
          discountDetail: productInfo.reducePriceLabel.discountDetail,
          opened: true
        }
      } else if (productInfo.reducePriceLabel.action === 'textDialog') {
        this.textTipDialogProps = {
          opened: true,
          content: productInfo.reducePriceLabel.textDialogContext
        }
      }
    },
    clickDiscountTips(tip) {
      this.textTipDialogProps = {
        opened: true,
        content: tip
      }
    },
    changeActiveIndex(index) {
      this.activePrime = this.primeProductList[index]
    },
    playAnimation() {
      const logo = this.$refs.primeLogoRef
      const logoHeight = logo.getBoundingClientRect().height
      // 白色的动效条需要倾斜15度
      const radian = 15 * (Math.PI / 180)
      const stripHeight = logoHeight / Math.cos(radian)
      const strip = document.createElement('span')
      strip.className = 'logo-strip'
      strip.style.height = `${stripHeight}px`
      logo.appendChild(strip)

      setTimeout(() => {
        document.querySelector('.logo-strip')?.remove()
      }, 1500)
    },
    handleScroll() {
      if (this.isAnimationPlayed) return
      const element = document.getElementsByClassName(
        'checkout-prime-new__wrap'
      )?.[0]
      if (!element) return
      const { top = 0, height } = element.getBoundingClientRect() || {}
      const windowHeight = window.innerHeight
      if (top + height / 2 <= windowHeight / 2) {
        this.isAnimationPlayed = true
        nextTick(() => {
          this.playAnimation()
        })
      }
    },
    clickTerm(e) {
      if (this.notSupport) {
        e.preventDefault()
      }
      if (e.target?.tagName == 'A') {
        daEventCenter.triggerNotice({
          daId: '1-11-1-85'
        })
      }
    },
    startPriceRolling() {
      nextTick(() => {
        const el = document.querySelector('.prime-deduce-animation')
        const priceWithSymbol = this.primeTips.selected_right_tip_price_variable
        const regx = /\D*([\d.,]+)/
        const price = priceWithSymbol.match(regx)[1]
        const currencyRule = window.getCurrencyRule()

        if (!currencyRule) return

        const amount = price
          .replaceAll(currencyRule.thousands_sep_symbol, '')
          .replace(currencyRule.dec_point_symbol, '.')

        handleTotalPriceAnimate(
          {
            amount,
            amountWithSymbol: priceWithSymbol
          },
          { amount: '0.00' },
          newTotalPrice => {
            if (el) {
              el.textContent = newTotalPrice?.amountWithSymbol
            }
          },
          currencies,
          currency,
          lang
        )
      })
    },
  }
})
</script>

<style lang="less">
.pickColor_prime {
  color: #fa6338;
}

// 擦亮动画
@keyframes prime-strip-move {
  0% {
    left: 0;
  }
  100% {
    left: calc(100% + 0.2667rem);
  }
}

@keyframes prime-strip-move-rtl {
  0% {
    right: 0;
  }
  100% {
    right: calc(100% + 0.2667rem);
  }
}

.logo-strip {
  width: 0.2133rem;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(
    95deg,
    rgba(255, 255, 255, 0.1),
    rgba(255, 255, 255, 0.5),
    rgba(255, 255, 255, 0.1)
  );
  transform: rotate(15deg);
  animation: prime-strip-move 0.5s 3;
}

.checkout-prime-new__wrap {
  background: #fff7ed;
  margin-top: 20/75rem;
  padding-bottom: 24/75rem;
  background-size: 100% 100%;
  overflow-x: hidden;
  overflow-y: auto;

  .checkout-prime__wrap-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .checkout-prime__logo_new {
    position: relative;
    width: calc(96px - 0.2667rem);
    font-size: 0;
  }

  .checkout-prime__wrap-tips {
    color: var(--club-subtitle-color, @sui_color_club_rosegold_dark1);
    text-align: right;
    font-family: 'SF UI Text';
    font-size: 12px;
    margin: 0 24/75rem 0 0;
    white-space: nowrap;
    max-width: 504/75rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .high-light {
    padding-top: 0;
  }

  .checkout-prime__wrap-des_new {
    max-width: calc(100% - 0.64rem);
    padding: 0 24/75rem;
    margin: 4/75rem 0 -12/75rem;
    cursor: pointer;
    font-size: 16px;
    font-weight: 800;
    color: var(--club-title-color, #d17000);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    .prime-deduce {
      color: var(--club-title-place-color, #fb4e1d);
      font-style: normal;
    }

    &.single-product {
      padding-left: 48/75rem;
    }
  }

  .checkout-prime__wrap-policy {
    padding: 0 24/75rem;
    color: var(--club-protocol-color, @sui_color_gray_light1);
    .font-dpr(20px);
    font-weight: 400;

    a {
      text-decoration: none;
      color: var(--club-protocol-hightlight-color, #2d68a8);
    }

    &.single-product {
      padding-left: 48/75rem;
    }
  }

  .checkout-prime__wrap-tip {
    color: @sui_color_unusual;
    .font-dpr(24px);
    font-weight: 400;
    padding: 16/75rem 24/75rem 0;
  }

  .no-support {
    opacity: 0.3;
  }
}

.prime-limit__pop {
  text-align: center;
}
.header-bg-container {
  position: absolute;
  top: 1rem;
  // left: 19%;
  text-align: center;
  width: 100%;
  font-weight: 600;
  .header-num {
    font-size: 24px;
    font-weight: 600;
  }
}
.virXrt_container {
  margin-top: -0.8rem;
  margin-bottom: 0.7rem;
  font-size: 14px;
  .virXrt_line {
    display: flex;
    justify-content: space-between;
    margin-top: 10/70rem;
  }
  .virXrt-line_left {
    display: flex;
    justify-content: center;
    align-items: center;
    .virXrt-line_leftTips {
      margin-right: 5/70rem;
    }
  }
  .virXrt_take-price {
    font-weight: 600;
  }
}
</style>
