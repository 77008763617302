<template>
  <s-sticky
    :type="'fixed'"
    :offset="1.17"
    :fixedWork="work => (isFixedWork = !!work)"
    class="checkout-sticky-wrapper"
  >
    <div class="checkout-sticky__wrap">
      <!-- 税费减免公告 -->
      <TaxReliefNotice v-if="hasTaxNotice" />
      <!-- 免邮权益公告 -->
      <FreeShippingNotice v-else-if="+checkout.free_shipping_policy_banner_info?.is_display" />
      <!-- 公告 -->
      <Notice v-if="noticeVisible" />

      <ClientOnly>
        <!-- 无网公告提示 -->
        <s-network-error-alert :description="language.SHEIN_KEY_PWA_31762" />
      </ClientOnly>

      <!-- 当没有地址的时候，引导用户填写地址模块 -->
      <NoAddressAdd
        v-if="!isHasAddress"
        :isFixedWork="isFixedWork"
      />
    </div>
  </s-sticky>
</template>

<script setup name="CheckoutSticky">
import { ref, computed } from 'vue'
import { ClientOnly } from '@sheinfe/vue-client-only'
import { SNetworkErrorAlert } from '@shein-aidc/sui-network-error-alert/mobile'
import { SSticky } from '@shein-aidc/sui-sticky/mobile'

import Notice from '@/public/src/pages/checkout_new/pages/notice/Index.vue'
import NoAddressAdd from 'public/src/pages/checkout_new/pages/address_bar/components/NoAddressAdd.vue'
import TaxReliefNotice from './TaxReliefNotice.vue'
import FreeShippingNotice from './FreeShippingNotice.vue'

import {
  useMapGetters,
  useMapState
} from '@/public/src/pages/checkout_new/hooks/store'


/*---- useMap_*** ----*/
const { language, checkoutNotice, checkout } = useMapState(['language', 'checkoutNotice', 'checkout'])
const { isHasAddress, hasTaxNotice } = useMapGetters([
  'isHasAddress',
  'hasTaxNotice'
])

/*------ state ------*/
const isFixedWork = ref(false)

const noticeVisible = computed(() => {
  return !!checkoutNotice.value?.content
})
</script>
<style lang="less">
.checkout-sticky-wrapper {
  z-index: 9;
  padding-bottom: 16/75rem;
}
</style>
