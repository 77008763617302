import { ref, onMounted } from 'vue'
import schttp from 'public/src/services/schttp'

const INIT_TIME_OBJ = {
  'C2P_REQUESR_LIMITE_TIME': 2000,
  'C2P_CHECKOUT_WITH_CARD_LIMITE_TIME': 60000,
}

const getInitTime = (key) => {
  return INIT_TIME_OBJ[key] || 2000
}

export function useRequestTime({
  apolloKeys = 'C2P_REQUESR_LIMITE_TIME'
} = {}) {
  const requestStartTime = ref(0)
  const requestEndTime = ref(0)
  const isTimeout = ref(false)
  const duration = ref(0)
  const nowTimeLimit = ref(getInitTime(apolloKeys))

  // 开始计时
  const startTiming = () => {
    requestStartTime.value = Date.now()
    isTimeout.value = false
    duration.value = 0
  }

  // 结束计时
  const endTiming = () => {
    requestEndTime.value = Date.now()
    duration.value = requestEndTime.value - requestStartTime.value
    isTimeout.value = duration.value > nowTimeLimit.value
  }

  const getApolloConfig = async () => {
    const res = await schttp({
      method: 'POST',
      url: '/api/config/apolloConfig/query',
      data: { apolloKeys: apolloKeys }
    })
    console.log('getApolloConfig >>>>', res, res?.info?.[apolloKeys])
    if (res?.code == 0 && res?.info?.[apolloKeys]) {
      nowTimeLimit.value = res.info?.[apolloKeys]
    }
  }

  onMounted(() => {
    getApolloConfig()
  })

  return {
    startTiming,
    endTiming,
    isTimeout,
    duration
  }
}
