<template>
  <!-- 以店铺维度的购物袋和运输方式的组合.doc= https://arc.sheincorp.cn/docs/2743204757322610688 -->
  <CommonHeader
    class="store-group_header"
    :class="[shoppingBagHeadInfo.visible && 'ambiance-header']"
    :isMultiMallStore="isMultiMallStore"
    :groupIndex="groupIndex"
    da-event-expose="expose_shipping_group.page_checkout"
    :data-group-id="groupId"
    @emiClickLayoutArea="handleVisibleDetail"
  />
</template>

<script setup>
/**
 * 主库
 * */
import { provide, toRef, onMounted, nextTick } from 'vue'
import { daEventCenter } from '@shein-aidc/basis-sa-event-center'

/**
 * 当前业务组件
 * */
import CommonHeader from 'public/src/pages/checkout_new/pages/shopping_bags/components/header/Index.vue'
import useHandleMallAndStoreGroup from 'public/src/pages/checkout_new/pages/shopping_bags/hooks/useHandleMallAndStoreGroup.js'

/**
 * 当前业务函数
 * */

const props = defineProps({
  isMultiMallStore: {
    type: Boolean,
    default: false,
  },
  groupIndex: {
    type: Number,
    default: 0,
  },
  groupId: {
    type: String,
    default: '',
  },
})
const emit = defineEmits(['handleVisibleBagsDetail'])
const storeGroupIndex = toRef(props, 'groupIndex')
provide('storeGroupIndex', storeGroupIndex)

// --------- useMap_**** --------
const { shoppingBagHeadInfo } = useHandleMallAndStoreGroup()

// ----------- computed ---------
// ------------ method ----------
const handleVisibleDetail = () => {
  emit('handleVisibleBagsDetail', {
    event,
    source: 'button',
    groupIndex: props.groupIndex,
  })
}

const initExpose = () => {
  nextTick(() => {
    const daEventExpose = daEventCenter.getExposeInstance()
    const code = '`expose_shipping_group.page_checkout'
    daEventExpose?.subscribe?.({
      keycode: `checkout-store-group-code${code}`,
      type: 'list'
    })
  })
}

onMounted(() => {
  initExpose()
})
</script>

<style lang="less">
.store-group_shopping-shipping__wrapper {
  .store-group_header {
    padding-right: 0.32rem;
    padding-top: 0.25rem;
    padding-bottom: 0.16rem;

    &.ambiance-header {
      &.checkout-sbs__header__bg,
      &.checkout-sbs__header__bg-ar {
        margin-left: -0.32rem;
      }
    }

    &:first-child {
      border-top: none;
    }
  }
}
</style>
