<template>
  <!-- 自动续费协议弹窗 -->
  <s-dialog
    v-model:visible="currentValue"
    :show-close="false"
    append-to-body
    class="renewal-tc-dialog"
    @close="closeAutoRenewalTCDialog"
  >
    <Icon
      class="renewal-tc-dialog__close"
      name="sui_icon_close_24px"
      size="20px"
      color="#FFFFFF"
      @click="closeAutoRenewalTCDialog"
    />
    <div class="renewal-tc-dialog__title">
      {{ subtitleText }}
    </div>
    <ul class="renewal-tc-dialog__content">
      <li
        v-for="(text, index) in contentList"
        :key="index"
      >
        {{ index + 1 }}. {{ text }}
      </li>
    </ul>
    <s-button
      class="renewal-tc-dialog__button"
      :type="['H72PX', 'primary']"
      :width="'100%'"
      @click="closeAutoRenewalTCDialog"
    >
      {{ language.SHEIN_KEY_PWA_30136 }}
    </s-button>
  </s-dialog>
</template>
<script>
import { defineComponent } from 'vue'
import { Icon } from '@shein-aidc/icon-vue3'
import { SDialog } from '@shein-aidc/sui-dialog/mobile'
import { SButton } from '@shein-aidc/sui-button/mobile'

// renewal_tc_dialog.vue
export default defineComponent({
  components: {
    Icon,
    SDialog,
    SButton,
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    content: {
      type: String,
      default: ''
    },
    activePrime: {
      type: Object,
      default: () => {}
    }
  },
  inject: ['language'],
  computed: {
    contentList() {
      const list = this.content
        .split('<br />')
        .filter(item => !!item)
        .map(item => item.match(/[a-zA-Z0-9].*$/)[0])
      return list
    },
    subtitleText() {
      return this.activePrime?.auto_renewal?.auto_renew_title_tip
    },
    currentValue: {
      get() {
        return this.visible
      },
      set(value) {
        this.$emit('update:visible', value)
      }
    },
  },
  methods: {
    closeAutoRenewalTCDialog() {
      this.$emit('closeDialog')
    }
  },
  emits: ['closeDialog', 'update:visible'],
})
</script>
<style lang="less" scoped>
.renewal-tc-dialog {
  :deep(.sui-dialog__wrapper) {
    width: 582/75rem;
    background: none;
    box-shadow: none;
    padding: 86/75rem 0 0;
  }
  :deep(.sui-dialog__top) {
    display: none;
  }
  :deep(.sui-dialog__body) {
    background-image: url(/pwa_dist/images/checkout/auto_renewal_prime_tc_bg-630aa4b452.png);
    background-size: 100% 100%;
    padding: 66/75rem 58/75rem 54/75rem;
  }

  &__close {
    position: absolute;
    top: 0;
    right: 10/75rem;
  }
  &__title {
    color: #5b2800;
    text-shadow: 1px 1px 0px rgba(255, 252, 244, 0.72);
    font-family: 'SF Pro';
    .font-dpr(32px);
    font-weight: 860;
    text-align: center;
  }
  &__subtitle {
    color: #5b2800;
    font-family: Inter;
    .font-dpr(26px);
    font-weight: 500;
    text-align: center;
    margin-top: 20/75rem;
  }
  &__content {
    color: #873c00;
    font-family: 'SF Pro';
    .font-dpr(22px);
    font-weight: 400;
    line-height: 1.45;
    margin-top: 24/75rem;
    text-align: left;
    > li {
      margin-top: 10/75rem;
      &:first-child {
        margin-top: 0;
      }
    }
  }
  &__button {
    background-color: @sui_color_club_rosegold_dark1;
    border: none;
    border-radius: 100/75rem;
    color: @sui_color_club_rosegold_light1;
    margin-top: 36/75rem;
  }
}
</style>
