<template>
  <div 
    v-if="warningInfo.visible"
    :class="prefixCls('br50-dollars_tax-wrapper')"
  >
    <Warning
      :text="warningInfo.text"
    />
    <ClientOnly>
      <Drawer v-if="shoppingBagsState.visibleBr50DollarsTaxDrawer" />
    </ClientOnly>
  </div>
</template>

<script setup>
import { computed, inject, defineAsyncComponent } from 'vue'
import { ClientOnly } from '@sheinfe/vue-client-only'

import Warning from './components/Warning.vue'

import { useMapState } from '@/public/src/pages/checkout_new/hooks/store'
import { prefixCls } from '@/public/src/pages/checkout_new/pages/shopping_bags/utils'

const Drawer = defineAsyncComponent(() => import(/* webpackChunkName: "checkoutv2-br50-dollars-tax-drawer" */ './components/Drawer.vue'))

const mallIndex = inject('mallIndex')

// --------- useMap_**** --------
const { checkout, shoppingBagsState } = useMapState([
  'checkout',
  'shoppingBagsState'
])

// ---------- computed ----------

/**
 * @description (2)巴西50美金税费提示入口信息
 * @returns {visible} 是否展示
 * @returns {text} 展示文本内容
 */
const warningInfo = computed(() => {
  const mallHintInfo = checkout?.value?.mallHintInfo
  const currentMallInfo = mallHintInfo?.[mallIndex]
  return {
    visible: currentMallInfo?.hintMsg,
    text: currentMallInfo?.hintMsg
  }
})
</script>
