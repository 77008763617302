<template>
  <s-input-number
    v-model="localValue"
    class="checkout-component__input-number"
    pattern="[0-9]*"
    :min="min"
    :max="max"
    :disabled-inc="disableInc"
    :disabled-dec="disableDec"
    :disabled="disable"
    @focus="focus"
    @blur="blur"
    @change="change"
    @increase="increase"
    @decrease="decrease"
    @reachToMax="reachToMax"
    @reachToMin="reachToMin"
    @click="onCLick"
  />
</template>
<script setup name="CommonInputNumber">
import { defineModel } from 'vue'
import { SInputNumber } from '@shein-aidc/sui-input-number/mobile'

defineProps({
  quantity: {
    type: Number,
    default: 0
  },
  min: {
    type: Number,
    default: 0
  },
  max: {
    type: Number,
    default: 99
  },
  disableInc: {
    type: Boolean,
    default: false
  },
  disableDec: {
    type: Boolean,
    default: false
  },
  disable: {
    type: Boolean,
    default: false
  }
})

const emit = defineEmits([
  'focus',
  'blur',
  'change',
  'increase',
  'decrease',
  'reachToMax',
  'reachToMin',
  'update:quantity'
])

// ————————————————— watch ——————————————————
// ———————————————— computed ————————————————
const localValue = defineModel('quantity');
// ————————————————— method —————————————————
const focus = e => {
  emit('focus', e)
}
const blur = e => {
  emit('blur', e)
}
const change = (currentValue, oldValue, type) => {
  emit('change', currentValue, oldValue, type)
}
const increase = (currentValue, oldValue) => {
  emit('increase', currentValue, oldValue)
}
const decrease = (currentValue, oldValue) => {
  emit('decrease', currentValue, oldValue)
}
const reachToMax = e => {
  emit('reachToMax', e)
}
const reachToMin = e => {
  emit('reachToMin', e)
}
const onCLick = e => {
  // 阻止事件冒泡
  e.stopPropagation()
}
</script>

<style lang="less">
.checkout-component__input-number {
  .sui-input-number__prepend {
    width: 44/75rem;
    box-sizing: border-box;
  }

  .sui-input-number__suffix {
    width: 44/75rem;
    box-sizing: border-box;
  }

  .sui-input-number__inner {
    width: 60/75rem;
    box-sizing: border-box;
  }
}
</style>
