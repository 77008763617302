<template>
  <div class="prime-list">
    <swiper-container
      ref="swiperRef"
      class="prime-list__container"
      :class="{
        'prime-list__container_single': !isMultStyle
      }"
      init="false"
    >
      <swiper-slide
        v-for="(item, index) in primeProductList"
        :key="item.product_code"
        class="prime-list__item swiper-item"
        :class="{
          'prime-list__item_scale': isReachedEnd,
          'prime-list__item_orange': showNewStyle,
          'prime-list__item_renewal':
            item.auto_renewal?.is_auto_renew_product === '1'
        }"
        :style="{
          '--club-product-name-color':
            customProductStyleList[index]?.productNameColor,
          '--club-right-color': customProductStyleList[index]?.rightColor,
          '--club-right-icon-color':
            customProductStyleList[index]?.rightIconColor,
          '--club-selling-point-color':
            customProductStyleList[index]?.sellingPointColor,
          '--club-count-down-color':
            customProductStyleList[index]?.countDownColor,
          '--club-count-down-bg-color':
            customProductStyleList[index]?.countDownBgColor,
          '--club-checkbox-color': customProductStyleList[index]?.checkboxColor,
          '--club-selected-card-border-color':
            customProductStyleList[index]?.cardCheckedBorderColor,
          '--club-price-color': customProductStyleList[index]?.priceColor,
          '--club-line-price-color':
            customProductStyleList[index]?.linePriceColor,
          '--club-product-name-bg-color':
            customProductStyleList[index]?.productNameBgColor,
          '--club-discount-label-bg-color':
            customProductStyleList[index]?.discountLabelBgColor,
          '--club-product-name-corner-bg-color':
            customProductStyleList[index]?.productNameCornerBgColor,
          '--club-history-saved-label-bg-color':
            customProductStyleList[index]?.historySavedLabelBgColor,
          '--club-discount-label-color':
            customProductStyleList[index]?.discountLabelColor,
          '--club-right-label-bg-color':
            customProductStyleList[index]?.rightLabelBgColor,
          '--club-right-label-text-color':
            customProductStyleList[index]?.rightLabelTextColor
        }"
        @click="handlePrimeCardClick(index)"
      >
        <RightPackageCard
          :item="item"
          :is-zoom-in="activeIndex === index"
          :is-zoom-out="lastActiveIndex === index"
          :is-selected="usedPrimeIndex === index"
          :is-auto-renewal="item.auto_renewal?.is_auto_renew_product === '1'"
          :customStyle="customProductStyleList[index]"
          :scale="scale"
          :freeGiftActivity="freeGiftActivity"
        />
        <div
          class="swiper-item__price"
          :class="{
            'no-support': notSupport,
            'swiper-item__price-scale': activeIndex === index
          }"
        >
          <s-radio
            :model-value="usedPrimeIndex"
            :label="index"
            class="swiper-item__radio"
            theme="icon"
            :disabled="notSupport"
          />
          <div
            v-if="
              activeIndex === index &&
                !closedPayBubbleIndexList.includes(index) &&
                item.auto_renewal?.pick_popup_show &&
                item.auto_renewal?.filteredPaymentList?.length
            "
            class="swiper-item__bubble bubble-wrapper"
          >
            <Icon
              class="bubble-wrapper__close-btn"
              name="sui_icon_close_12px_2"
              size="10px"
              @click.stop="onBubbleClose(index)"
            />
            <RenewalPayMethod
              :logoList="item.auto_renewal.filteredPaymentList.map(m => m.icon)"
              :text="
                template(
                  item.auto_renewal.filteredPaymentList.length,
                  item.auto_renewal.pick_popup_tip
                )
              "
              :onClick="() => onPrimeCardClick(index)"
            />
          </div>
          <strong class="swiper-item__price-discount">
            {{ item.product_price_info.special_price_with_symbol }}
          </strong>
          <del
            v-if="+item.product_price_info.is_display_origin_price"
            class="swiper-item__price-local"
          >
            {{ item.product_price_info.price_local_with_symbol }}
          </del>
          <div
            v-if="item.reducePriceLabel"
            :class="[
              'swiper-item__limited',
              customProductStyleList[index]?.discountLableBgColor
                ? undefined
                : {
                  'swiper-item__limitedToCoupon':
                    item.reducePriceLabel.action === 'couponList',
                  'swiper-item__limited_only-renewal':
                    item.auto_renewal?.is_auto_renew_product === '1'
                }
            ]"
            @click.stop="onOfferLabelClick(item, 'page')"
          >
            <div class="prime-textWhiteSpace">
              <span v-if="!GB_cssRight">
                &#x202A;<Icon
                  v-if="item.auto_renewal?.is_auto_renew_product === '1'"
                  name="sui_icon_random_discount_16px"
                  size="12px"
                  color="#fff"
                />
                <span>{{ item.reducePriceLabel.text }}</span>
              </span>
              <span v-else>
                <span>{{ item.reducePriceLabel.text }}</span>
                <Icon
                  v-if="item.auto_renewal?.is_auto_renew_product === '1'"
                  name="sui_icon_random_discount_16px"
                  size="12px"
                  color="#fff"
                />
              </span>
            </div>
            <span
              v-if="GB_cssRight"
              class="prime-textWhiteSpace_pice"
            >{{ item.discountValue }}
            </span>
            <span
              v-if="item.reducePriceLabel.action"
              class="mr-5"
            >
              >
            </span>
          </div>
        </div>
      </swiper-slide>
    </swiper-container>
  </div>
</template>

<script>
import { SRadio } from '@shein-aidc/sui-radio/mobile'
import { defineComponent, nextTick } from 'vue'
import { register } from 'swiper/element'
import { template, CountDown } from '@shein/common-function'
import { Icon } from '@shein-aidc/icon-vue3'
import RightPackageCard from './RightPackageCard.vue'
import RenewalPayMethod from '../../shared/RenewalPayMethod.vue'
import { transformProudctPackageStyle } from './utils'

typeof window !== 'undefined' && register()

const { GB_cssRight } = gbCommonInfo

export default defineComponent({
  components: {
    SRadio,
    Icon,
    RightPackageCard,
    RenewalPayMethod
  },
  props: {
    notSupport: {
      type: Boolean,
      default: false
    },
    primeProductList: {
      type: Array,
      default: () => []
    },
    onOfferLabelClick: {
      type: Function,
      default: () => {}
    },
    onPrimeCardClick: {
      type: Function,
      default: () => {}
    },
    closedPayBubbleIndexList: {
      type: Array,
      default: () => []
    },
    onBubbleClose: {
      type: Function,
      default: () => {}
    },
    usedPrimeIndex: {
      type: Number,
      default: -1
    },
    selectedPrimeIndex: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      GB_cssRight,
      primeSwiper: null,
      activeIndex: 0,
      lastActiveIndex: -1,
      isReachedEnd: false,
      scale: 1,
      freeGiftTimer: null
    }
  },
  inject: ['language', 'styleInfo', 'styleType', 'freeGiftInfo'],
  computed: {
    isMultStyle() {
      return this.primeProductList?.length > 1
    },
    showNewStyle() {
      return this.styleType === 'new'
    },
    customProductStyleList() {
      const defaultStyle = transformProudctPackageStyle(
        this.styleInfo.entrance_style,
        'base'
      )

      return this.primeProductList.map(m => {
        return {
          ...defaultStyle,
          ...transformProudctPackageStyle(
            m.style_info?.entrance_style,
            'product'
          )
        }
      })
    },
    freeGiftActivity() {
      if (this.freeGiftInfo) {
        return {
          text: this.freeGiftInfo.text,
          countdown: this.freeGiftTimer?.timeObj
        }
      }

      return null
    }
  },
  watch: {
    usedPrimeIndex: {
      handler(n) {
        if (typeof n === 'number' && n > -1) {
          // 滚动
          if (this.isMultStyle) {
            this.primeSwiper?.swiper?.slideTo(n)
          } else if (n !== this.activeIndex) {
            this.lastActiveIndex = this.activeIndex
            this.activeIndex = n
          }
        }
      },
      immediate: true
    },
    activeIndex: {
      handler(index) {
        this.$emit('changeActiveIndex', index)
      },
      immediate: true
    }
  },
  mounted() {
    this.initTimer()
    const rootFontSize = parseFloat(
      getComputedStyle(document.documentElement).fontSize
    )
    this.scale = rootFontSize / 37.5
    nextTick(() => {
      this.initIntersectionObserver()
    })
  },
  methods: {
    template,
    initIntersectionObserver() {
      const io = new IntersectionObserver(
        entries => {
          const intersectionRatio = entries[0].intersectionRatio

          if (intersectionRatio > 0) {
            this.initSwiper()
            this.initPrime()
            io.unobserve(this.$refs.swiperRef)
          }
        },
        {
          thresholds: [0, 1]
        }
      )

      io.observe(this.$refs.swiperRef)
    },
    initTimer() {
      if (this.freeGiftInfo?.endTime) {
        this.freeGiftTimer = new CountDown()
        this.freeGiftTimer.start({
          seconds: Math.floor(this.freeGiftInfo.endTime - Date.now() / 1000),
          dayMode: true
        })
      }
    },
    initSwiper() {
      if (!this.isMultStyle) return
      const self = this
      // swiper的参数属性
      const swiperParams = {
        slidesPerView: 'auto',
        slidesPerGroup: 1,
        slidesOffsetAfter: 12,
        normalizeSlideIndex: false,
        injectStyles: [
          `.swiper {
            overflow: visible;
          }
          `
        ],
        on: {
          slideChange: function (event) {
            if (self.isReachedEnd && event.isEnd) return

            const primeProductListLen = self.primeProductList.length
            let currentIndex = event?.activeIndex

            if (currentIndex >= primeProductListLen) {
              currentIndex = primeProductListLen - 1
              self.lastActiveIndex = currentIndex - 1
            } else if (currentIndex !== self.activeIndex) {
              self.lastActiveIndex = self.activeIndex
            }
            self.activeIndex = currentIndex
            self.isReachedEnd =
              self.activeIndex === self.primeProductList.length - 1
          }
        }
      }
      this.primeSwiper = this.$refs.swiperRef

      Object.assign(this.primeSwiper, swiperParams)
      this.primeSwiper.initialize()
    },
    initPrime() {
      if (this.usedPrimeIndex < 0) {
        this.primeSwiper &&
          this.primeSwiper.swiper?.slideTo(this.selectedPrimeIndex)
      }
    },
    handlePrimeCardClick(index) {
      if (this.notSupport) return
      this.onPrimeCardClick(index)
    }
  },
  emits: ['changeActiveIndex']
})
</script>

<style lang="less" scoped>
.prime-list {
  width: 100%;
  width: 100%;
  display: flex;
  justify-content: center;

  &__container {
    width: 100%;
    display: flex;
    flex-wrap: nowrap;
    margin-bottom: 16/75rem;
    padding: 50/75rem 0 0 24/75rem;
    z-index: @zindex-zero;
  }

  &__container_single {
    padding-left: 0;
    width: 88%;

    .prime-list__item {
      width: 86.9%;
      margin-right: 0;
    }
  }

  .swiper-item {
    &__price {
      display: flex;
      align-items: center;
      position: relative;
      height: 48/75rem;
      margin-top: 24/75rem;
    }
    &__price-scale {
      width: 115%;
    }
    &__radio {
      display: flex;
      align-items: center;
      margin: 0;
      margin-left: 4/75rem;
      margin-right: var(--sui-radio-gap, 0.4rem);
    }
    &__bubble {
      position: absolute;
      left: 0;
      bottom: 58/75rem;
    }
    &__price-discount {
      .margin-r(8/75rem);
      .font-dpr(32px);
    }
    &__price-local {
      .margin-r(8/75rem);
      .font-dpr(24px);
      color: var(--club-line-price-color, @sui_color_gray_light1);
    }
    &__limited {
      display: flex;
      padding: 0 16/75rem;
      .font-dpr(24px);
      color: var(--club-discount-label-color, #fff);
      font-family: 'SF UI Display';
      background: var(
        --club-discount-label-bg-color,
        linear-gradient(98deg, #c96e3f 48.66%, #eaa27e 100%)
      );
      border-radius: 12/75rem 0;
      font-weight: 700;
      overflow: hidden;
    }
    &__limitedToCoupon {
      border: 1px solid rgba(218, 126, 80, 0.3);
      background: var(
        --club-discount-label-bg-color,
        linear-gradient(0deg, #fee6d7 0%, #fee6d7 100%)
      );
      color: #ca6f40;
    }
    &__limited_only-renewal {
      background-image: none;
      background: var(
        --club-discount-label-bg-color,
        linear-gradient(90deg, #f17e53 5.49%, #ff5656 81.87%)
      );
      border-radius: 12/75rem 0px;
    }
  }

  &__item {
    .margin-r(88/75rem);
    width: 426/75rem;
    cursor: pointer;
  }

  .swiper-item__price-discount {
    color: var(--club-price-color, @sui_color_club_rosegold_dark3);
  }

  &__item_orange,
  &__item_renewal {
    .swiper-item__price-discount {
      color: var(--club-price-color, #fd5b46);
    }
  }

  &__item_orange .swiper-item__limited {
    background: var(
      --club-discount-label-bg-color,
      linear-gradient(270deg, #e9c997 -0.05%, #ffefc9 100.05%)
    );
    color: var(--club-discount-label-color, #d43e2a);
  }

  .bubble-wrapper {
    max-width: 520/75rem;
    padding: 16/75rem;
    border-radius: 4/75rem;
    box-sizing: border-box;
    background: var(---sui_color_black_alpha80, rgba(0, 0, 0, 0.8));
    color: #fff;
    font-size: 10px;
    &::before {
      position: absolute;
      bottom: -8/75rem;
      left: 14/75rem;
      content: '';
      width: 0;
      height: 0;
      border-left: 10/75rem solid transparent;
      border-right: 10/75rem solid transparent;
      border-top: 10/75rem solid rgba(0, 0, 0, 0.8);
    }
    &__close-btn {
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  .prime-textWhiteSpace {
    font-size: 11px;
    line-height: 1.7;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    direction: rtl;
    unicode-bidi: embed;
  }
  .prime-textWhiteSpace_pice {
    font-size: 11px;
    line-height: 1.7;
  }
  .mr-5 {
    margin-left: 5/75rem;
  }
}
.prime-list {
  :deep(.sui-radio__check) {
    border-color: var(--club-checkbox-color, @sui_color_club_rosegold_dark3);
    border-width: 1.5px;
    .margin-r(-0.24rem);
    background-color: transparent;
  }
  :deep(.sui-radio__checked > .sui-radio__check) {
    background-color: var(
      --club-checkbox-color,
      @sui_color_club_rosegold_dark3
    );
    border: none;
  }
  &__item_orange,
  &__item_renewal {
    :deep(.sui-radio__check) {
      border-color: var(--club-checkbox-color, #ff5925);
    }
    :deep(.sui-radio__checked > .sui-radio__check) {
      background-color: var(--club-checkbox-color, #ff5656);
    }
  }
}
</style>
