<template>
  <div class="checkout-address-bar">
    <!-- 这里只是有地址模块展示，无地址引入到了sticky文件中 -->
    <div
      v-if="isHasAddress"
      v-tap="{
        id: 'click_shipping_address:simple',
        data: !isShopTransit ? { address_risk: isRiskAddress } : {},
      }"
    >
      <!-- 店配 -->
      <AddressShop
        v-if="isRealShopTransit"
        @click-address-tips="handleAddressTipsLinkClick"
      />

      <!-- 宅配 -->
      <AddressHome
        v-if="!isRealShopTransit"
        @click-address-list="handleHomeAddressClick"
        @click-address-tips="handleAddressTipsLinkClick"
      />
    </div>

    <ClientOnly>
      <!-- 地址列表 -->
      <BsAddressList
        ref="addressListRef"
        v-model:visible="isShowAddressListDrawer"
        :modal="true"
        :defaultAddressData="addressBarState.addressListDrawer.selectedAddress"
        @on-edit="handleAddressListEdit"
        @on-selected="handleAddressListSelected"
        @on-delete="handleAddressListDeleted"
        @on-add="handleAddressListAdd"
      />

      <!-- 填写地址抽屉 -->
      <AddressWrite
        ref="addressWriteRef"
        @add-home-address="handleAddressWriteAdd"
        @edit-home-address="handleAddressWriteEdit"
      />

      <!-- 引导用户填写地址的提醒弹窗 -->
      <DialogNoAddress @add-address="handleAddressAdd" />

      <!-- 地址弹窗 Tips 添加店配地址提醒、台湾地址补充等弹窗 -->
      <DialogAddressTips @add-address="handleAddressAdd" />
      <DialogTwAddressGuide />
    </ClientOnly>
  </div>
</template>

<script setup name="AddressBar">
import { vTap } from 'public/src/pages/common/analysis/directive'
import { ref, defineAsyncComponent, onMounted, onBeforeUnmount, computed } from 'vue'

import { ClientOnly } from '@sheinfe/vue-client-only'
import { daEventCenter } from '@shein-aidc/basis-sa-event-center'
import BsAddressList from '@shein-aidc/bs-addresslist-mobile'
import { exposeScenesAbtAggregatorBff as exposeScenesAbtAggregator } from 'public/src/pages/checkout_new/utils/EventAggregator.js'

import { useMapState, useMapGetters, useMapActions } from '@/public/src/pages/checkout_new/hooks/store.js'
import { fetchDelAddressBookApi } from 'public/src/services/api/pay/checkout'
import AddressHome from './components/AddressHome.vue'
import AddressShop from './components/AddressShop.vue'

const DialogNoAddress = defineAsyncComponent(() =>
  import(/* webpackChunkName: "checkoutv2-dialog-no-address" */ './components/DialogNoAddress.vue')
)
const DialogAddressTips = defineAsyncComponent(() =>
  import(/* webpackChunkName: "checkoutv2-dialog-address-tips" */ './components/DialogAddressTips.vue')
)
const DialogTwAddressGuide = defineAsyncComponent(() =>
  import(/* webpackChunkName: "checkoutv2-dialog-tw-address-guide" */ './components/DialogTwAddressGuide.vue')
)
const AddressWrite = defineAsyncComponent(() =>
  import(/* webpackChunkName: "checkoutv2-address-write" */ './components/AddressWrite.vue')
)

import { COUNTRY_OR_AREA_CODE } from '@/public/src/pages/checkout_new/utils/constant.js'
import { OPERATE_TYPE, TRANSPORT_TYPE } from './utils/constant'
import useWakeAddressWrite from './hooks/useWakeAddressWrite.js'
import useWakeAddressList from './hooks/useWakeAddressList.js'

const { handleAddressDrawer } = useWakeAddressWrite()
const { handleAddressListDrawer } = useWakeAddressList()

/*------ useMap** ------*/
const { checkout, addressBarState, checkoutParams } = useMapState(['checkout', 'addressBarState', 'checkoutParams'])
const { isHasAddress, isShopTransit, isRealShopTransit, getDefaultAddress, isRiskAddress } = useMapGetters(['isHasAddress', 'isShopTransit', 'isRealShopTransit', 'getDefaultAddress', 'isRiskAddress'])
const { updateCheckoutData, updateCartsInfoData, updateStock } = useMapActions(['updateCheckoutData', 'updateCartsInfoData', 'updateStock'])

/*------ state ------*/
const addressWriteRef = ref(null)
const addressListRef = ref(null)

/*------ computed ------*/
const isShowAddressListDrawer = computed({
  get(){
    return addressBarState.value?.addressListDrawer?.status
  },
  set (val) {
    let options = { status: val }
    // 变为false的时候，需要清空
    if (!val) {
      options = {
        ...options,
        extraData: {},
        callBack: () => {},
        selectedAddress: {}
      }
    }
    handleAddressListDrawer(options)
  }
})


/*------ methods ------*/
/**
 * @description: 地址栏【宅配区域】点击，即进入地址列表
 * @return {*}
 */
const handleHomeAddressClick = () => {
  handleAddressListDrawer({ status: true, selectedAddress: getDefaultAddress.value })
}

/**
 * @description: 地址栏【无地址区域】/【无地址弹窗】/【添加店配地址提醒弹窗】等多处的点击
 * @param {*} options {operateType: 'add/edit/delete', address?: {}, addressType?: 'home/shop'}
 * @return {*}
 */
const handleAddressAdd = (options) => {
  handleAddressDrawer({
    status: true,
    operateType: OPERATE_TYPE.ADD,
    addressType: options?.addressType || (isShopTransit.value ? TRANSPORT_TYPE.SHOP : TRANSPORT_TYPE.HOME)
  })
}

/**
 * @description: 地址栏提示中的关键字链接点击
 * @return {*}
 */
const handleAddressTipsLinkClick = (e) => {
  const { TW } = COUNTRY_OR_AREA_CODE
  if (e.target.tagName == 'A') {
    if (getDefaultAddress.value?.countryId == TW) {
      daEventCenter.triggerNotice({ id: 'click_verifiied_taiwan:simple' })
      return
    } else {
      handleAddressDrawer({
        status: true,
        operateType: OPERATE_TYPE.EDIT,
        addressData: getDefaultAddress.value,
        extraData: {
          specificFunc: () => {
            addressWriteRef.value.$refs?.addressHomeWriteRef?.scrollPassport?.()
          }
        }
      })
      daEventCenter.triggerNotice({
        id: 'expose_address_form:simple',
        data: {
          page_type: 'edit',
          activity_from: 'checkout_active',
          address_type: getDefaultAddress.value ? 1 : 0
        }
      })
    }
  }
}

/**
 * @description: 地址列表编辑事件，唤醒地址填写弹窗
 * @param {*} option 编辑的地址数据
 * @return {*}
 */
const handleAddressListEdit = (option = {}) => {
  handleAddressDrawer({
    status: true,
    operateType: OPERATE_TYPE.EDIT,
    addressType: TRANSPORT_TYPE.HOME, // 地址列表编辑的只有宅配
    addressData: option
  })
}

/**
 * @description: 地址列表增加事件，唤醒地址填写弹窗
 * @return {*}
 */
const handleAddressListAdd = () => {
  handleAddressDrawer({
    status: true,
    operateType: OPERATE_TYPE.ADD,
    addressType: TRANSPORT_TYPE.HOME, // 地址列表添加的只有宅配
  })
}

/**
 * @description: 地址列表删除事件
 * @param {*} option 需要删除的地址数据
 * @return {*}
 */
const handleAddressListDeleted = (option = {}) => {
  fetchDelAddressBookApi({
    addressId: option?.addressId || ''
  }).then(res => {
    if (res.code == 0 && isShowAddressListDrawer.value) {
      addressListRef.value?.getAddressListData(OPERATE_TYPE.DELETE)
    }
  })
}

/**
 * @description: 地址列表选择新的地址
 * @param {*} option 新的地址数据
 * @return {*}
 */
const handleAddressListSelected = ({ address = {} }) => {
  const { addressId, countryId, state, city, postcode } = address
  const { extraData = {}, callBack: addressListOperateCallBack = () => {} } = addressBarState.value.addressListDrawer

  daEventCenter.triggerNotice({ id: 'click_other_address:simple' })

  // 切换地址，需要判断是否有选中运输方式
  let other = {}
  if(checkoutParams.value?.shipping_methods?.length){
    other = {
      current_action: 'switch_address'
    }
  }

  // 更新checkout接口
  updateCheckoutData({
    opts: {
      state, city, postcode,
      address_id: addressId,
      country_id: countryId,
      ...extraData,
      ...other
    },
    cb: (result = {}) => {
      if (result.code == 0) {
        // 更新库存
        updateStock({
          outStockCarts: checkout.value?.outStockCarts,
          outStockCartsTip: checkout.value?.outStockCartsTip
        })

        // 如果最新的地址是风控的，需要上报埋点
        if (!isShopTransit.value && isRiskAddress.value) {
          exposeScenesAbtAggregator.addEvent({
            id: 'expose_scenesabt:simple',
            data: {
              scenes: 'address_risk',
              type: '-'
            }
          })
        }
      }
      addressListOperateCallBack && addressListOperateCallBack(result, address)
    }
  })
  // 更新购物车数据
  updateCartsInfoData({ country_id: countryId })
}

/**
 * @description: 地址弹窗新增事件
 * @param {*} data 新增的地址信息，用于地址列表
 * @return {*}
 */
const handleAddressWriteAdd = ({ address, type = OPERATE_TYPE.ADD }) => {
  if (isShowAddressListDrawer.value) {
    handleAddressListDrawer({ status: true, selectedAddress: address })
    addressListRef.value?.getAddressListData(type)
  }
}

/**
 * @description: 地址弹窗编辑事件
 * @param {*} data 编辑的地址信息，用于地址列表
 * @return {*}
 */
const handleAddressWriteEdit = ({ type = OPERATE_TYPE.EDIT }) => {
  if (isShowAddressListDrawer.value) {
    addressListRef.value?.getAddressListData(type)
  }
}

/*------life cycle ------*/
onMounted(() => {
  // 无宅配地址
  if (!isHasAddress.value && !isShopTransit.value) {
    exposeScenesAbtAggregator.addEvent({
      id: 'expose_scenesabt:simple',
      data: {
        scenes: 'no_address'
      }
    })
  }

  // 首次显示的宅配地址是风控地址，需要上报埋点
  if (!isShopTransit.value && isHasAddress.value && isRiskAddress.value) {
    exposeScenesAbtAggregator.addEvent({
      id: 'expose_scenesabt:simple',
      data: {
        scenes: 'address_risk',
        type: '-'
      }
    })
  }
})

// 单页面应用下，访问地址列表时可能残留 'S-popup-parent__hidden', 未从body清除
onBeforeUnmount(() => {
  const clsName = 'S-popup-parent__hidden'
  if(document.body.classList.contains(clsName)) {
    document.body.classList.remove(clsName)
  }
})
</script>
<style lang="less">
.checkout-address-bar {
  &__tips {
    margin-top: 24 / 75rem;
    padding: 16 / 75rem;
    border-radius: 8 / 75rem;
    border: 1 / 75rem solid #FFE2D3;
    background: #FFF6F3;
    color: #C44A01;
    font-family: "SF UI Text";
    font-size: 24 / 75rem;
    font-weight: 400;
    line-height: normal;
  }
  &__tips-wrap {
    display: -webkit-box;
    // width: 100%;
    -webkit-line-clamp: 3; /* 超出几行省略 */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
  }
}
</style>
