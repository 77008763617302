<template>
  <NewUserDrawer
    v-bind="props"
    :title="attributeName"
    class="new-user-details"
    @close="$emit('update:visible', false)"
  >
    <template #content>
      <div class="new-user-details__content">
        <NewUserCoupon
          v-for="(coupon, index) in list"
          :key="index"
          class="new-user-details__item"
          :coupon="coupon"
          validDaysVisible
          cycleDetailsVisible
          :language="language"
          :config="{
            [XtraCouponType.Product]: { '--newUserBorderColor': '#FFBFE1' },
            [XtraCouponType.Shipping]: { '--newUserBorderColor': '#97DCBF' }
          }"
        />
      </div>
    </template>
  </NewUserDrawer>
</template>

<script setup>
import { XtraCouponType } from './config.js'

import NewUserDrawer from './NewUserDrawer'
import NewUserCoupon from './NewUserCoupon.vue'

const props = defineProps({
  visible: {
    type: Boolean,
    default: false
  },
  attributeName: {
    type: String,
    default: ''
  },
  subtitle: {
    type: String,
    default: ''
  },
  list: {
    type: Array,
    default: () => []
  },
  language: {
    type: Object,
    default: () => {}
  }
})
</script>

<style lang="less" scoped>
.new-user-details {
  &__item {
    height: 160/75rem;
    // overflow: hidden;
    margin-top: 32/75rem;
    &:first-child {
      margin-top: 0;
    }
  }
}
</style>
