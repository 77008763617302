<template>
  <div
    v-if="!!largeShipGoods.length"
    :class="[wrapClassName, prefixCls('large-goods-info')]"
  >
    <div
      v-for="(src, index) in largeShipGoods"
      :key="index"
      :class="prefixCls('large-goods-info__item')"
    >
      <div class="goods-box">
        <img
          class="goods-img"
          :src="transformImg({img: src})"
          alt=""
        />
        <em
          v-if="index === 0 && goodsCount"
          class="goods-count"
        >
          {{ goodsCount }}
        </em>
      </div>
    </div>
  </div>
</template>

<script setup name="GoodsInfo">
import { computed } from 'vue'
import { transformImg } from '@shein/common-function'
import { prefixCls } from '../../../../utils/index'

const props = defineProps({
  largeShipGoods: {
    type: Array,
    default: () => []
  },
  goodsCount: {
    type: String,
    default: ''
  }
})

const wrapClassName = computed(() => {
  const tempList = props.largeShipGoods?.slice(0, 3) || []
  const nameList = ['', 'large-goods-len1', 'large-goods-len2', 'large-goods-len3']

  return nameList[tempList.length]
})
</script>

<style lang="less">
@import "public/src/pages/checkout_new/pages/shipping_method/variables";

.@{prefixCls}large-goods-info {
  height: 64/75rem;
  position: relative;
  margin-right: 16/75rem;

  &.large-goods-len1 {
    width: 72/75rem;
  }
  &.large-goods-len2 {
    width: 96/75rem;
  }
  &.large-goods-len3 {
    width: 104/75rem;
  }

  &__item:first-child {
    width: 64/75rem;
    height: 64/75rem;
    position: absolute;
    .left(0);
    top: 0;
    z-index: 3;
    transform: translateZ(3px);
  }
  &__item:nth-child(2) {
    width: 56/75rem;
    height: 56/75rem;
    position: absolute;
    .left(24/75rem);
    top: 4/75rem;
    z-index: 2;
    transform: translateZ(2px);
  }
  &__item:nth-child(3) {
    position: absolute;
    width: 48/75rem;
    height: 48/75rem;
    .left(40/75rem);
    top: 8/75rem;
    z-index: 1;
    transform: translateZ(1px);
  }

  .goods-count {
    position: absolute;
    bottom: 1px;
    right: 0;
    padding: 0 4px;
    border-radius: 4px 0;
    background: rgba(0, 0, 0, 0.70);
    .font-dpr(16px);
    color: #FFF;
  }

  .goods-box {
    border: .5px solid #fff;
    border-radius: 4px;
    height: 100%;
    overflow: hidden;
  }

  .goods-img {
    width: 100%;
    height: 100%;
    flex-shrink: 0;
    border-radius: 4px;
    border: 0.5px solid #FFF;
    object-fit: cover;
  }
}
</style>
