<template>
  <div
    :class="[
      prefixCls('normal-shipping__wrapper'),
      {
        [prefixCls('normal-shipping__wrapper_new')]:
          shoppingBagHeadInfo.visible,
      },
    ]"
    :style="promotionHeaderStyle"
    @click="handleLayoutArea"
  >
    <PromotionHeader
      v-if="shoppingBagHeadInfo.visible"
      :class="prefixCls('ns__promotion-header')"
      :data="shoppingBagHeadInfo.data"
    />
    <!-- 单商品购物袋内容区域 -->
    <SingleProduct
      v-if="
        shoppingBagsState.bagsInfo?.mall?.[mallIndex]?.isRenderSingleProduct
      "
      :class="prefixCls('ns__single-product')"
      :data="shoppingBagsState.bagsInfo?.mall?.[mallIndex]?.normal?.[0]"
    />

    <!-- 多商品购物袋内容区域 -->
    <MultiProduct
      v-else
      :class="prefixCls('ns__multi-product')"
      :data="shoppingBagsState.bagsInfo?.mall?.[mallIndex]?.normal"
    />
  </div>
</template>

<script setup name="NormalShipping">
import { inject, provide, computed } from 'vue'

import PromotionHeader from '../promotion_header/Header.vue'
import SingleProduct from '../single_product/Index.vue'
import MultiProduct from '../multi_product/Index.vue'

import { prefixCls } from '@/public/src/pages/checkout_new/pages/shopping_bags/utils'
import {
  useMapState,
} from '@/public/src/pages/checkout_new/hooks/store'
import useHandleMallAndStoreGroup from 'public/src/pages/checkout_new/pages/shopping_bags/hooks/useHandleMallAndStoreGroup.js'

// 外页
provide('isOutsidePage', true)
const mallIndex = inject('mallIndex')
const emit = defineEmits(['emiClickLayoutArea'])

// --------- useMap_**** --------
const { shoppingBagsState } = useMapState(['shoppingBagsState'])
const { shoppingBagHeadInfo } = useHandleMallAndStoreGroup()
// --------- computed ---------
const promotionHeaderStyle = computed(() => {
  const colors = shoppingBagHeadInfo.value?.data?.background_colors || []
  if (shoppingBagHeadInfo.value.visible && colors.length) {
    // 如果接口有返回，则使用返回的渐变色
    return {
      background: `linear-gradient(85deg, ${colors[0]} 33.6%, ${colors[1]} 100%)`,
    }
  }
  return {}
})

// ---------- method ----------
const handleLayoutArea = event => {
  emit('emiClickLayoutArea', { event })
}
</script>
<style lang="less">
@import '../../variables.less';

.@{prefixCls}normal-shipping__wrapper {
  position: relative;
  &_new {
    margin-top: 10/37.5rem;
    padding: 0 2/37.5rem 2/37.5rem;
    border-radius: 4px;
    background: linear-gradient(129deg, #fdc830 -4.38%, #f37335 28.14%);
    .@{prefixCls}ns__promotion-header {
      padding: 8/37.5rem 10/37.5rem;
    }
    .@{prefixCls}ns__single-product {
      padding: 6/37.5rem 10/37.5rem;
      background: #fff;
      border-radius: 3px;
    }
    .@{prefixCls}ns__multi-product {
      padding: 6/37.5rem 0 6/37.5rem 10/37.5rem;
      background: #fff;
      border-radius: 3px;
    }
  }
  .@{prefixCls}ns__multi-product {
    padding-top: 0.16rem;
  }
}
</style>
