<!--"购物袋商品组展示逻辑"可阅读-->
<!--【public/src/pages/checkout_new/pages/shopping_bags/README.md】-->
<template>
  <div
    v-expose="getExposedData()"
    class="checkout-shopping-bags__wrapper"
    :class="cls"
  >
    <!-- 头部 -->
    <CommonHeader @emiClickLayoutArea="handleVisibleDetail" />

    <!-- SFS商品提示 -->
    <SFSProductsTips />

    <!-- 巴西50美金税费 -->
    <Br50DollarsTax />

    <!-- 正常航运 -->
    <NormalShipping
      v-if="status === NormalShip"
      @emiClickLayoutArea="handleVisibleDetail"
    />

    <!-- 全部快速航运 -->
    <AllQuickShipping
      v-if="status === AllQuickShip"
      @emiClickLayoutArea="handleVisibleDetail"
    />

    <!-- 部分快速航运 -->
    <PartQuickShipping
      v-if="status === PartQuickShip"
      @emiClickLayoutArea="handleVisibleDetail"
    />

    <ClientOnly>
      <DoubleConfirmDialog />
      <OrderDetailDrawer />
    </ClientOnly>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'ShoppingBagsIndex'
})
</script>
<script setup name="ShoppingBagsIndex">
import { vExpose } from 'public/src/pages/common/analysis/directive'
import { watch, computed, defineAsyncComponent, provide, onMounted, nextTick } from 'vue'
import { ClientOnly } from '@sheinfe/vue-client-only'

import CommonHeader from './components/header/Index.vue'
import SFSProductsTips from '@/public/src/pages/checkout_new/pages/shopping_bags/components/sfs_products_tips/Index.vue'
import Br50DollarsTax from '@/public/src/pages/checkout_new/pages/shopping_bags/components/br50_dollars_tax/Index.vue'
import NormalShipping from './components/product_shipping/NormalShipping.vue'
import AllQuickShipping from './components/product_shipping/AllQuickShipping.vue'
import PartQuickShipping from './components/product_shipping/PartQuickShipping/Index.vue'

import {
  useMapMutations,
  useMapState,
  useMapGetters
} from '@/public/src/pages/checkout_new/hooks/store'
import useShoppingBagReport from './hooks/useShoppingBagReport.js'
import { CurrentQuickShipStatus } from '@/public/src/pages/checkout_new/utils/constant'

import { getQsStatus } from 'public/src/pages/checkout_new/store/mutations/dataMapping/shoppingBags.js'


const DoubleConfirmDialog = defineAsyncComponent(() =>
  import(/* webpackChunkName: "checkoutv2-double-confirm-dialog" */ './components/double_confirm/Index.vue')
)
const OrderDetailDrawer = defineAsyncComponent(() =>
  import(
    /* webpackChunkName: "checkoutv2-order-detail-drawer" */ 'public/src/pages/checkout_new/pages/shopping_bags/components/order_detail_drawer/Index.vue'
  )
)

const { PartQuickShip, NormalShip, AllQuickShip } = CurrentQuickShipStatus

const props = defineProps({
  // good_by_mall项对应的索引，用于匹配store里面的数据，用于渲染
  mallIndex: {
    type: Number,
    default: 0
  }
})

provide('mallIndex', props.mallIndex || 0)

// --------- useMap_**** --------
const { checkout, shoppingBagsState } = useMapState([
  'checkout',
  'shoppingBagsState'
])
const { shoppingBagHeadInfoMap } = useMapGetters(['shoppingBagHeadInfoMap'])
const { updateVisibleOrderDetailDrawerState } = useMapMutations([
  'updateVisibleOrderDetailDrawerState'
])
const {
  handleBeltExposed,
  handleShoppingBagExposed,
  handleShoppingBagOpen,
  handleShoppingBagDrawerExposed,
  getEvoluTagExposedData,
  handleRankTagSceneReport
} = useShoppingBagReport()
// --------- computed ----------
const currentMall = computed(() => shoppingBagsState.value?.bagsInfo?.mall?.[props.mallIndex] || {})

const cls = computed(() => {
  if (!!shoppingBagHeadInfoMap.value?.[currentMall.value.mall_code]) {
    // 有氛围头的样式
    return 'ambiance-header'
  }
  return currentMall.value.isRenderSingleProduct
    ? 'simple-product-wrapper'
    : 'multi-product-wrapper'
})
// ----------- watch -----------
watch(
  () => shoppingBagsState.value.visibleOrderDetailDrawer,
  visible => {
    const isExistDom = [...document.body.classList].includes(
      'sui-popup-parent__hidden'
    )

    if (!visible[props.mallIndex] && isExistDom)
      document.body.classList.remove('sui-popup-parent__hidden')
  }
)
watch(
  () => currentMall.value,
  mallInfo => {
    handleBeltExposed(mallInfo.all)
    handleShoppingBagExposed(mallInfo)
  },
  {
    deep: true
  }
)

onMounted(() => {
  nextTick(() => {
    handleBeltExposed(currentMall.value.all)
    handleShoppingBagExposed(currentMall.value)
  })
})

// ---------- computed ----------

// 商品航运类型
const status = computed(() => {
  return getQsStatus({ quick_shipping: checkout.value?.quick_shipping, currentMallCode: currentMall.value.mall_code })
})

// ----------- method -----------

const handleVisibleDetail = ({ event, source = 'pictures' }) => {
  event.stopPropagation()
  const isRenderSingleProduct = currentMall.value?.isRenderSingleProduct
  if (isRenderSingleProduct) return
  updateVisibleOrderDetailDrawerState({ [props.mallIndex]: true })
  handleShoppingBagOpen(props.mallIndex, source)
  handleShoppingBagDrawerExposed(props.mallIndex)
}

const getExposedData = () => {
  if (currentMall.value.isRenderSingleProduct) {
    return getEvoluTagExposedData(currentMall.value.all)
  }
}

onMounted(() => {
  handleRankTagSceneReport()
})
</script>

<style lang="less">
@import './variables.less';
.checkout-shopping-bags__wrapper {
  background: #fff;
  // margin-top: 16/75rem;
  padding: 0.32rem;
  padding-top: 0;
  position: relative;
  // overflow: hidden;
  &.simple-product-wrapper {
    padding-right: 0.32rem;
    .@{prefixCls}header__wrapper {
      padding-right: 0;
    }
  }
  &.multi-product-wrapper {
    padding-right: 0;
    .@{prefixCls}header__wrapper {
      padding-right: 0.32rem;
    }
    .@{prefixCls}pqs_quick-label{
      padding-right: 0.32rem;
    }
    .@{prefixCls}pqs_shop_bag_shipping{
      margin-right: 0.32rem;
    }
  }
  // 氛围头个性化样式
  &.ambiance-header {
    padding: 0;
    .@{prefixCls}header__wrapper {
      padding-left: 0.32rem;
      padding-right: 0.32rem;
    }
    .@{prefixCls}pqs_quick-label{
      padding-left: 0.32rem;
      padding-right: 0.32rem;
    }
    .@{prefixCls}pqs_shop_bag_shipping{
      padding-left: 0.32rem;
      margin-right: 0.32rem;
    }
  }

  .dev__component-signet {
    position: absolute;
    top: 0;
    right: 0;
    margin: -20px -15px 0 0;
    transform: rotate(40deg);
    width: 110px;
    height: 110px;
    border-radius: 50%;
    border: 3px double red;
    display: flex;
    align-items: center;
    justify-content: center;
    color: red;
    font-weight: 900;
    opacity: 0.6;
  }
}
</style>
