<template>
  <!--引导加入付费会员-->
  <div
    v-if="primeFreeTip"
    v-expose="primeEntryExposedData"
    :class="[prefixCls('prime-entry'), isModify && prefixCls('prime-entry_modify')]"
    :style="primeEntryStyle"
  >
    <div :class="prefixCls('prime-entry__main')">
      <img
        v-if="primeFreeTip.icon_url"
        :class="prefixCls('prime-entry__main-icon')"
        :src="primeFreeTip.icon_url"
        alt=""
      />
      <div
        :class="prefixCls('prime-entry__main-tips')"
        v-html="primeFreeTip.text"
      ></div>
    </div>
    <div
      :class="prefixCls('prime-entry__btn')"
      :style="{
        borderColor: primeFreeTip?.button_color
      }"
      @click="handleButtonClick"
      v-html="primeFreeTip?.button_text"
    >
    </div>
  </div>
</template>

<script setup name="ShippingPrimeEntry">
import { vExpose } from 'public/src/pages/common/analysis/directive'
import { computed } from 'vue'
import { daEventCenter } from '@shein-aidc/basis-sa-event-center'
import { prefixCls } from '../../utils/index'
import useShippingPrime from '../../hooks/useShippingPrime.js'

const EnumActionType = {
  None: 0,
  // 1: 勾选
  ModifyCoupon: 1,
  // 2: 未勾选
  JoinClub: 2,
}

const props = defineProps({
  mallCode: {
    type: String,
    default: ''
  },
  primeFreeTip: {
    type: Object,
    default: null
  }
})

const isModify = computed(() => +props.primeFreeTip.type === EnumActionType.ModifyCoupon)

const {
  saObj,
  onClickJoinPrime,
  goCouponPage
} = useShippingPrime({ ...props, autoUseCouponTip: isModify.value })

const primeEntryExposedData = computed(() => {
  if (+props.primeFreeTip.type === EnumActionType.JoinClub) {
    return {
      id: 'expose_prime_entry:simple',
      data: saObj.value
    }
  }
  return {}
})

const primeEntryStyle = computed(() => {
  const { background_url } = props.primeFreeTip || {}
  if (background_url) {
    return {
      background: `url(${background_url})`,
      backgroundSize: '100% 100%'
    }
  }
  return {}
})

const handleButtonClick = () => {
  switch (+props.primeFreeTip.type) {
    case EnumActionType.JoinClub:
      onClickJoinPrime()
      daEventCenter.triggerNotice({
        id: 'click_prime_entry:simple',
        data: saObj.value
      })
      break
    case EnumActionType.ModifyCoupon:
      goCouponPage()
      break
  }
}
</script>

<style lang="less">
@import "public/src/pages/checkout_new/pages/shipping_method/variables";

.@{prefixCls}prime-entry {
  margin: 0.16rem @g_ship_pad_hor_offset 0.16rem @g_ship_pad_hor_offset;
  display: flex;
  padding: 24/75rem;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  background: #FFF2E7;
  color: @sui_color_club_rosegold_dark1;
  line-height: 1.2;

  &_modify {
    margin: 0 @g_ship_pad_hor_offset;
    background: #fff6f3;
    padding: 20/75rem;
    color: @sui_color_club_rosegold_dark1;

    .@{prefixCls}prime-entry__btn {
      color: @sui_color_link;
      border: none;
    }
  }

  &__main {
    display: flex;
    align-items: center;
  }

  &__main-icon {
    width: 36/75rem;
    height: 36/75rem;
    margin-right: 20/75rem;
  }

  &__main-tips {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &__btn {
    padding: 10/75rem 16/75rem;
    border-radius: 34px;
    flex-shrink: 0;
    margin-left: 24/75rem;
    border: 1px solid @sui_color_club_rosegold_dark1;
    text-align: center;
  }
}
</style>
