<script setup>
import PointDrawer from './drawer/PointDrawer.vue'
import WalletDrawer from './drawer/WalletDrawer.vue'
import { useAssetApply } from '@/public/src/pages/checkout_new/pages/virtual_assets/hooks/useAssetApply.js'
const { onApplyPoint, onApplyWallet } = useAssetApply()
</script>

<template>
  <div>
    <PointDrawer
      @onSubmit="onApplyPoint"
    />

    <WalletDrawer
      @onSubmit="onApplyWallet"
    />
  </div>
</template>
