<template>
  <div
    v-if="sizingInfo.visible"
    :class="prefixCls('sp__sku-wrapper')"
  >
    <CommonImage
      v-if="!!sizingInfo.colorImage"
      :class="prefixCls('sp__sku-img')"
      :imgUrl="sizingInfo.colorImage"
      :imgDesignWidth="52"
      :isFirstPage="true"
      :isDefaultGrayGb="true"
    />
    <span class="attr"> {{ sizingInfo.goodsAttr }} </span>
  </div>
</template>
<script setup name="ProductSize">
import { computed } from 'vue'
import CommonImage from 'public/src/pages/checkout_new/components/CommonImage.vue'

import { prefixCls } from '@/public/src/pages/checkout_new/pages/shopping_bags/utils'

const props = defineProps({
  colorImage: {
    type: String,
    default: '',
  },
  goodsAttr: {
    type: String,
    default: '',
  },
})

// --------- computed ---------

/**
 * @description 尺码信息
 * @returns {colorImage} 尺码颜色
 * @returns {goodsAttr} 尺码文案
 * @returns {visible} 是否展示
 * */
const sizingInfo = computed(() => {
  const colorImage = props?.colorImage
  const goodsAttr = props?.goodsAttr
  const visible = !!colorImage && !!goodsAttr
  return {
    colorImage,
    goodsAttr,
    visible,
  }
})
</script>

<style lang="less">
@import '../../../variables.less';

.@{prefixCls}sp__sku {
  &-wrapper {
    display: inline-flex;
    align-items: center;
    color: #666666;
    .font-dpr(24px);
    white-space: nowrap;
    // .line-camp(1);
    // width: 100%;

    .attr {
      margin-left: 4/75rem;
    }
  }

  &-img {
    width: 0.32rem !important;
    height: 0.32rem !important;
    border-radius: 50%;
    vertical-align: text-top;
  }
}
</style>
