<template>
  <div :class="prefixCls('promotion-header-count-down')">
    <div
      v-if="data.count_down_icon_url"
      :class="[prefixCls('promotion-header-count-down__lottie'), {[prefixCls('promotion-header-count-down__lottie_ar')]: GB_cssRight }]"
    >
      <ClientOnly>
        <CountDownLottie :data="data" />
      </ClientOnly>
    </div>
    <span
      v-if="data.right_tip || time"
      :class="prefixCls('promotion-header-count-down__right-tip')"
    >
      {{ data.right_tip || time }}
    </span>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, onBeforeUnmount, watch, defineAsyncComponent } from 'vue'
// import { useCountDown } from '../../../../hooks/useCountDown'
import { prefixCls } from '@/public/src/pages/checkout_new/pages/shopping_bags/utils'
import { CountDown } from '@shein/common-function'
import { ClientOnly } from '@sheinfe/vue-client-only'
const CountDownLottie = defineAsyncComponent(() => import('./CountDownLottie.vue'))

const props = defineProps({
  data: {
    type: Object,
    required: true
  }
})

const { GB_cssRight } = gbCommonInfo

const timer = ref(new CountDown())

watch(
  () => props.data?.end_time,
  () => {
    if(props.data?.end_time) {
      const endTime = props.data.end_time.toString().length < 13 ? +props.data.end_time * 1000 : +props.data.end_time
      timer.value.start({
        seconds: Math.floor((endTime - Date.now()) / 1000),
        endFunc: () => {
          timer.value.clear()
        }
      })
    }
  },
  {
    immediate: true
  }
)

// const { current, start } = useCountDown()
const time = computed(() => {
  if (props.data.end_time) {
    const { H, M, S } = timer.value?.timeObj || {}
    return `${H}:${M}:${S}`
    // return `${current.H}:${current.M}:${current.S}`
  }
  return ''
})

const init = () => {
  // if (props.data.end_time) {
  //   start({ timestamp: props.data.end_time })
  // }  
}

onMounted(init)
onBeforeUnmount(() => {
  timer?.value?.clear()
})
</script>

<style lang="less">
@import '../../variables.less';
.@{prefixCls}promotion-header-count-down {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  &__lottie {
    margin-right: 4/37.5rem;
    width: 65/37.5rem;
    height: 16/37.5rem;
    &_ar {
      transform: scaleX(-1);
    }
  }
  &__right-tip {
    font-size: 12px;
    font-weight: 400;
  }
}
</style>
