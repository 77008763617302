<template>
  <div
    class="checkout-shipping-method__wrapper checkout-scroll__shipping"
    :class="`checkout-shipping-method__wrapper-${mallCode}`"
  >
    <div
      :class="[prefixCls('head'), 'fsp-element']"
      :fsp-key="`${prefixCls('head')}_${mallCode}`"
      tabindex="0"
    >
      {{ language.SHEIN_KEY_PWA_15218 }}
    </div>

    <!---付费会员-->
    <ShippingPrime :mallCode="mallCode" />

    <ShippingMethodList
      :mallCode="mallCode"
      :shipping-methods="currShipMall?.shipping_methods"
    />

    <!--运费险-->
    <ShippingInsurance
      :mallCode="mallCode"
    />

    <ShippingTotalPrice
      :mallCode="mallCode"
    />
  </div>
</template>
<script setup name="ShippingMethod">
import { computed, watch } from 'vue'
import ShippingMethodList from './components/shipping_method_list/index.vue'
import ShippingInsurance from './components/ShippingInsurance.vue'
import ShippingPrime from './components/shipping_prime/Index.vue'
import ShippingTotalPrice from './components/ShippingTotalPrice.vue'
import { prefixCls } from './utils/index'
import { useMapState } from '@/public/src/pages/checkout_new/hooks/store'
import { daEventCenter } from '@shein-aidc/basis-sa-event-center'

const props = defineProps({
  mallCode: {
    type: String,
    default: ''
  }
})

const { shipping_methods_mall, language } = useMapState([
  'checkout.shipping_methods_mall',
  'language',
])

const currShipMall = computed(() => {
  return shipping_methods_mall.value?.find(item => +item.mall_code === +props.mallCode)
})

// 运输方式里面是否含有凑单
const hasAddOrderInShipping = computed(() => {
  return shipping_methods_mall.value?.length === 1 && shipping_methods_mall.value?.some(item => {
    return item?.shipping_methods?.some(_ => !!_?.free_shipping_add_item?.show_tip?.tip)
  })
})

watch(() => hasAddOrderInShipping.value, (newVal, oldVal) => {
  if (shipping_methods_mall.value?.length === 1 && newVal !== oldVal) {
    daEventCenter.triggerNotice({
      id: 'expose_scenesabt:simple',
      data: {
        scenes: 'addshipping',
        type: newVal ? 1 : 0
      }
    })
  }
}, {
  immediate: true
})
</script>

<style lang="less">
@import "public/src/pages/checkout_new/pages/shipping_method/variables";

.checkout-shipping-method__wrapper {
  background: #fff;
}

.@{prefixCls} {
  &head {
    margin: 0 @g_ship_pad_hor_offset;
    padding: 0.26666667rem 0 0 0;
    font-weight: 700;
    color: #222;
    text-transform: capitalize;
  }
}
</style>
