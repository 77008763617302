<template>
  <div
    v-if="returnCouponVisible"
    v-expose="analysisData"
    :class="prefixCls('wrap')"
  >
    <MultiReturnCoupon
      v-if="orderReturnCoupons.length > 1"
      :coupon="coupon"
      :language="language"
      @update:isCouponEnd="updateCouponStatus"
    />

    <SingleReturnCoupon
      v-else
      :orderReturnCoupons="orderReturnCoupons"
      :language="language"
      @update:isCouponEnd="updateCouponStatus"
    />
  </div>
</template>
<script setup name="ReturnCoupon">
import { vExpose } from 'public/src/pages/common/analysis/directive'
import { computed, ref } from 'vue'
import MultiReturnCoupon from './MultiReturnCoupon.vue'
import SingleReturnCoupon from './SingleReturnCoupon.vue'
import { prefixCls } from './utils'
import { useMapState, useMapGetters } from '@/public/src/pages/checkout_new/hooks/store'

const {
  promotionDatas,
  orderReturnCoupons,
  language
} = useMapState([
  'cartsInfo.promotionDatas',
  'cartsInfo.orderReturnCoupons',
  'language'
])

const { isShowReturnCoupons, rewardFloorConfig } = useMapGetters([
  'isShowReturnCoupons',
  'rewardFloorConfig'
])

// 21 -》 筛选多券活动
const coupon = computed(() => promotionDatas?.value?.find?.(item => +item.type_id === 21) || {})

const isCouponEnd = ref(false)

const analysisData = computed(() => {
  return {
    id: 'expose_regain_coupon:simple',
    data: {
      is_couponbag: orderReturnCoupons.value.length > 1 ? 1 : 0
    }
  }
})

const returnCouponVisible = computed(() => {
  return !rewardFloorConfig.value.rewardVisible && 
    isShowReturnCoupons.value && 
    !isCouponEnd.value
})

const updateCouponStatus = val => isCouponEnd.value = val
</script>

<style lang="less">
@import './variables';

.@{prefixCls} {
  &wrap {
    // margin-top: 16/75rem;
    padding: 20/75rem;
    background-color: #fff;
  }
}
</style>
