<template>
  <!-- quickShip 标志 -->
  <div :class="prefixCls('common__shipping-label')">
    <!-- label标签 -->
    <LabelTag
      v-if="!!ShippingLabel.productTags.text && ShippingLabel.tagVisible"
      :tag="ShippingLabel.productTags"
      useIcon
    />

    <span :class="prefixCls('common__sl_txt')">{{
      ShippingLabel.itemsLabel
    }}</span>
  </div>
</template>

<script setup name="ShippingLabel">
import { prefixCls } from '@/public/src/pages/checkout_new/pages/shopping_bags/utils'
import LabelTag from '@/public/src/pages/checkout_new/components/QuickShipTag.vue'
import useHandleMallAndStoreGroup from 'public/src/pages/checkout_new/pages/shopping_bags/hooks/useHandleMallAndStoreGroup.js'

// --------- useMap_**** --------

const { ShippingLabel } = useHandleMallAndStoreGroup()


// ---------- computed ----------
</script>

<style lang="less">
@import '../../../variables.less';

.@{prefixCls}common {
  &__shipping-label {
    .font-dpr(24px);
    color: @sui_color_gray_dark1;
    margin-bottom: 12/75rem;
    padding-top: 20/75rem;
    padding-right: 24/75rem;
    display: flex;
    align-items: center;

    .@{prefixCls}common_label-tag {
      border: 0.5px solid rgba(25, 128, 85, 0.2);

      span {
        font-style: italic;
        font-weight: 590;
      }
    }

    .@{prefixCls}common__sl_txt {
      margin-left: 8/75rem;
      font-family: 'SF Pro';
      font-size: 12px;
      font-style: normal;
      font-weight: 590;
      line-height: normal;
    }
  }
}
</style>
