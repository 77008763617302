<template>
  <div
    v-if="!!couponRoundInfo.couponList.length"
    class="xtra-coupon__round"
  >
    <div
      class="xtra-coupon__round-title"
    >
      <h4 class="xtra-coupon__round-name">
        {{ couponRoundInfo.privilegeCycleName }}
      </h4>
      <p class="xtra-coupon__round-des">
        {{ couponRoundInfo.estimatedIssueTimeTip }}
      </p>
    </div>

    <div 
      v-if="couponList && !!couponList"
      class="xtra-coupon__round-body"
    >
      <!-- 单个卡片，公共组件 -->
      <SaverSmallCoupon 
        v-for="(item, index) in couponList"
        :key="index"
        :item="item"
        :language="language"
        :couponRightType="item.rightTypeCode == 'ESSENTIAL_COUPON' ? 'crown' : 'normal'"
        scene="checkout"
        class="xtra-coupon__round-body-item"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { template as _commonTemplate } from '@shein/common-function'

import SaverSmallCoupon from './SaverSmallCoupon.vue'

export default defineComponent({
  name: 'XtraCouponRound',
  components: {
    SaverSmallCoupon,
  },
  props: {
    couponRoundInfo: {
      type: Object,
      default: () => {}
    },
    language: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    couponList() {
      // 只展示常规券
      return this.couponRoundInfo.couponList
    },
  },
  methods: {
    template: _commonTemplate,
  }
})
</script>

<style lang="less" scoped>
.xtra-coupon__round {
  margin-top: 40/75rem;

  .xtra-coupon__round-title {
    .xtra-coupon__round-name {
      .font-dpr(32px);
      color: @sui_color_club_black;
    }
    .xtra-coupon__round-des {
      margin-top: 4/75rem;
      .font-dpr(28px);
      color: @sui_color_gray_dark3;
    }

    margin-bottom: -4/75rem;
  }

  .xtra-coupon__round-body {
    display: flex;
    flex-wrap: wrap;
  }
  .xtra-coupon__round-body-item {
    .margin-r(24/75rem);
    margin-top: 24/75rem;
    &:nth-child(2n) {
      .margin-r(0);
    }
  }
}
</style>
