<!--各类型支付方式聚集于此-->
<template>
  <div :class="prefixCls('group-btn')">
    <!-- 普通按钮 -->
    <NormalBtn
      v-if="isNormalPayBtn"
      :class="prefixCls('group-btn_normal')"
      @click="createOrder"
    />

    <template v-if="!isOnlyRenderNormalBtn">
      <!-- venmo 按钮 -->
      <BPaypalVenmoButton
        v-if="isShowPaypalVenmoBtn"
        :class="prefixCls('group-btn_venmo-button')"
        @click="createOrder"
      />

      <ClientOnly>
        <!-- paypal pay -->
        <PaypalBtn
          v-show="isShowPaypalBtn"
          :class="prefixCls('group-btn_paypal')"
          :handleCreateOrder="handleCreateOrder"
          :handlePayError="handlePayError"
          @handle-paypal-btn-init="handlePaypalBtnInit"
          @approved="handlePpgaApproved"
        >
          <!-- <NormalBtn /> -->
        </PaypalBtn>

        <!-- apple pay -->
        <ApplePayBtn
          v-show="isShowApplePayBtn"
          :class="prefixCls('group-btn_appleyay')"
          :support-apple-pay="isSupportApplePay"
          :propsStyle="{ width: '100%', height: '1.06rem' }"
          @handleBtnClick="createOrder"
        />
      </ClientOnly>
    </template>
  </div>
</template>

<script setup name="ButtonGroup">
import { computed, ref } from 'vue'
import { ClientOnly } from '@sheinfe/vue-client-only'

import ApplePayBtn from '@/public/src/pages/components/ApplePayBtn.vue'

import NormalBtn from './components/NormalBtn.vue'
import PaypalBtn from './components/PaypalBtn.vue'

import { BPaypalVenmoButton } from '@shein-aidc/bs-pay-toolkit-mobile'

import { prefixCls } from '@/public/src/pages/checkout_new/pages/footer/utils'
import {
  useMapGetters,
  useMapMutations
} from '@/public/src/pages/checkout_new/hooks/store'
import useCreateOrder from '@/public/src/pages/checkout_new/hooks/useCreateOrder.js'

const { handleCreateOrder, handleCreatePayment, getOrderDetailLink } =
  useCreateOrder()
const paypalBtnInit = ref(false)
const emit = defineEmits(['handleClickNormalBtn'])

const props = defineProps({
  // 仅仅展示NormalBtn，不展示其他
  isOnlyRenderNormalBtn: {
    type: Boolean,
    default: false
  },
  c2pInfo: {
    type: Object,
    default: () => ({})
  }
})

// --------- useMap_**** --------
const {
  isShowApplePayBtn,
  isShowPaypalGaButton,
  isShowPaypalBnplButton,
  isShowPaypalVenmoBtn,
  isSupportApplePay
} = useMapGetters([
  'isShowApplePayBtn',
  'isShowPaypalVenmoBtn',
  'isShowPaypalGaButton',
  'isShowPaypalBnplButton',
  'isSupportApplePay'
])

const { updatePriceDetailVisible } = useMapMutations([
  'updatePriceDetailVisible'
])

// ---------- computed ----------

const isNormalPayBtn = computed(() => {
  return !(
    isShowPaypalVenmoBtn.value ||
    isShowApplePayBtn.value ||
    isShowPaypalBtn.value
  )
})

const isShowPaypalBtn = computed(() => {
  return (
    (isShowPaypalGaButton.value || isShowPaypalBnplButton.value) &&
    paypalBtnInit.value
  )
})

// ---------- method ----------

const handlePpgaApproved = data => {
  console.log('handlePpgaApproved====', data)
  handleCreatePayment({ extraPayInfo: data })
}

const handlePaypalBtnInit = status => {
  paypalBtnInit.value = !!status
}

const handlePayError = () => {
  const link = getOrderDetailLink()
  window.location.href = link
}

// 点击成单按钮
const createOrder = () => {
  const isOnlyRenderNormalBtn = props.isOnlyRenderNormalBtn
  updatePriceDetailVisible(false)
  if (isOnlyRenderNormalBtn) {
    emit('handleClickNormalBtn')
    return
  }
  handleCreateOrder({
    c2pInfo: props.c2pInfo
  })
}
</script>

<style lang="less">
@import '../../../../variables.less';

.@{prefixCls}group-btn {
  .@{prefixCls}btn-first-layout {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
    padding: 16/75rem 0 12/75rem;

    .@{prefixCls} btn-fl {
      &__text {
        width: 100%;
        height: 28/75rem;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-weight: 700;
        font-size: 12px;
        line-height: 1.2;
      }

      &__logo {
        display: flex;
        align-items: center;
        margin-top: 4/75rem;
      }

      &__logo-img {
        height: 20/75rem;
      }

      &__sub-text {
        width: 100%;
        height: 24/75rem;
        margin-top: 4/75rem;
        font-size: 10px;
        font-weight: 700;
        line-height: normal;
        color: var(---sui_color_white, #fff);
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .@{prefixCls}btn-second-layout {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 20/75rem 0;

    .@{prefixCls} btn-sl {
      &__logo {
        width: 60/75rem;
        height: 100%;
        margin-right: 8/75rem;
        background-color: #fff;
        border-radius: 4/75rem;
      }

      &__text {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 12px;
        font-weight: 700;
        line-height: 1.2;
      }
    }
  }

  .@{prefixCls}btn-third-layout {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    padding: 20/75rem 24/75rem;

    .@{prefixCls} btn-tl {
      &__logo {
        width: 60/75rem;
        height: 40/75rem;
        margin-left: 8/75rem;
        border-radius: 4/75rem;
        background-color: #fff;
      }

      &__text {
        color: #fff;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 12px;
        font-weight: 700;
        line-height: 1.2;
      }
    }
  }
}
</style>
