export default (data = {}, cartList = []) => {
  const priceData = data?.aggregateProductBusiness?.priceData
  const originalPrice = priceData?.originalPrice
  const matchPrice = cartList?.find(item => item?.cartId === data?.id)
    ?.priceData?.unitPrice?.price

  /**
   * @description 价格信息
   * @returns {txt} 文本
   * @returns {color} 颜色
   * */
  const price = {
    txt:
      (!!matchPrice ? matchPrice : priceData?.unitPrice?.price)
        ?.amountWithSymbol || '',
    color: priceData?.unitPrice?.color || '',
  }

  /**
   * @description 划线格信息
   * @returns {txt} 文本
   * @returns {color} 颜色
   * */
  const delPrice = {
    txt: originalPrice?.price?.amountWithSymbol,
    description: originalPrice?.description || '',
    tip: {
      desc: originalPrice?.price?.tip?.desc || '',
      imgUrl: originalPrice?.price?.tip?.imgUrl || '',
      label: originalPrice?.description || '',
    },
    color: originalPrice?.color || '',
    textDecoration: originalPrice?.crossed === '1' ? 'line-through' : 'none',
  }
  return {
    price,
    delPrice,
  }
}
