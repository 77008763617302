<template>
  <!-- bff融合超省卡组件 -->
  <div 
    v-if="saveCardInfo?.products?.length > 0"
    class="checkout-saver checkout-scroll__saved"
  >
    <BffSaver
      class="checkout-saver__wrap"
      :disable-text="selectedPaymentInfo.not_support_save_card_tip"
      :language="language"
      :save-card-info="saverInfo"
      :selected-code="selectedSaverCode"
      :abt="abt"
      @autoRenewdDrawerShow="showQuickPaymentDrawer"
      @changeBagEvent="handleProductChange"
      @goCouponPage="handleCouponListOpen"
    />

    <ClientOnly>
      <QuickPaymentDrawer
        v-if="!!drawerProductCode" 
        v-model:visible="quickPaymentDrawerVisible"
        :card-type="CardType.SAVE"
        v-bind="quickPaymentDrawerData"
        :style="{
          '--drawer-background': 'linear-gradient(181deg, #FFEEF3 2.94%, #FDD2DB 98.75%)'
        }"
      />
    </ClientOnly>
  </div>
</template>
<script>
import { defineComponent } from 'vue'
export default defineComponent({
  name: 'CheckoutSaver'
})
</script>
<script setup name="CheckoutSaver">
import { ClientOnly } from '@sheinfe/vue-client-only'
import { ref, computed, defineAsyncComponent } from 'vue'
import { template } from '@shein/common-function'
import { daEventCenter } from '@shein-aidc/basis-sa-event-center'
import { useMapState, useMapGetters, useMapMutations } from 'public/src/pages/checkout_new/hooks/store'
import useQuickPayments from './hooks/useQuickPayments.js'
import useUpdateCardData from './hooks/useUpdateCardData.js'
import { CardType, DaRenewType } from './utils/constant.js'

import BffSaver from 'public/src/pages/common/product-package/saver/bff'
const QuickPaymentDrawer = defineAsyncComponent(() =>
  import(/* webpackChunkName: "checkoutv2-quick-payment-drawer" */ './components/quick_payment_drawer/Index.vue')
)

const { 
  language,
  checkoutParams,
  saveCardInfo,
  abtInfo,
  selectedSaverCode
} = useMapState([
  'language',
  'checkoutParams',
  'checkout.saveCardInfo',
  'abtInfo',
  'primeSaverState.selectedSaverCode'
])
const { selectedPaymentInfo } = useMapGetters(['selectedPaymentInfo'])
const { updateShipPopupByKey } = useMapMutations(['updateShipPopupByKey'])
const { isQuickPayment, filterBubbleQuickPayments } = useQuickPayments()
const { updateCardData } = useUpdateCardData()

const quickPaymentDrawerVisible = ref(false)
const drawerProductCode = ref('')

// 当前选中的支付方式是否支持快捷支付
const isQuickPaySupported = computed(() => isQuickPayment({ 
  cardType: CardType.SAVE, 
  payment: selectedPaymentInfo.value,
}))

const abt = computed(() => {
  return { saverOrderModule: abtInfo?.value.saverOrderModule }
})

const saverInfo = computed(() => ({
  ...saveCardInfo.value,
  products: saveCardInfo.value?.products.map(item => {
    const { isAutoRenewProduct, auto_renewal } = item?.saveCardProductInfo || {}
    const { pick_popup_show, pick_popup_payment_list } = auto_renewal || {}
    let popupVisible = pick_popup_show
    // 仅处理自动续费产品包，及需要展示气泡的产品包
    if (isAutoRenewProduct == 0 || pick_popup_payment_list?.length === 0) return item
    // 如果当前支付方式支持自动续费，不展示快捷支付方式气泡
    popupVisible = isQuickPaySupported.value ? 0 : 1
    if (popupVisible && pick_popup_payment_list?.length > 0) {
      item.saveCardProductInfo.auto_renewal.filteredPaymentList = filterBubbleQuickPayments({ 
        cardType: CardType.SAVE, 
        payments: pick_popup_payment_list
      })
    }
    if (item.saveCardProductInfo?.auto_renewal)
      item.saveCardProductInfo.auto_renewal.pick_popup_show = popupVisible
    return item
  })
}))

const quickPaymentDrawerData = computed(() => {
  const productInfo = saveCardInfo.value?.products?.find(item => item.saveCardProductInfo.saveCardProductCode === drawerProductCode.value)
  if (!productInfo) return {}
  
  const { auto_renewal = {}, specialPriceWithSymbol, priceLocalWithSymbol, style_info = {} } = productInfo.saveCardProductInfo || {}
  const { title, title_saving_price, fee_desc, current_payment_disable_desc, change_payment_title, force_remember_card_tip } = auto_renewal.save_popup || {}
  return {
    productCode: drawerProductCode.value,
    headerProps: {
      title: template(
        `<span style="color:#F82854;font-size: 32px;font-weight: 860;">${title_saving_price}</span>`,
        title
      ),
      subtitle: `${fee_desc} <span style="font-weight: 700;">${specialPriceWithSymbol}</span>`,
      originPrice: style_info.showLinePrice ? priceLocalWithSymbol : '',
    },
    disableDesc: current_payment_disable_desc,
    quickPaymentProps: {
      title: change_payment_title,
      forceRememberCardTips: force_remember_card_tip,
      supportPayments: auto_renewal.pick_popup_payment_list?.length 
        ? auto_renewal.pick_popup_payment_list.map(payment => payment?.code) 
        : undefined
    }
  }
})

const showQuickPaymentDrawer = (productCode) => {
  if (!productCode) return
  drawerProductCode.value = productCode
  quickPaymentDrawerVisible.value = true
  daEventCenter.triggerNotice({
    id: 'expose_autorenew_restriction:simple',
    data: {
      renew_type: DaRenewType[CardType.SAVE]
    }
  })
}

const handleProductChange = (productCode) => {
  const productInfo = saveCardInfo.value.products.find(item => item.saveCardProductInfo.saveCardProductCode === productCode)
  const isSame = checkoutParams.value.save_card_product_code === productCode
  if ((productCode && !productInfo) || isSame) return
  const { isAutoRenewProduct, auto_renewal } = productInfo?.saveCardProductInfo || {}
  if (!productCode || !+isAutoRenewProduct || isQuickPaySupported.value || !auto_renewal?.save_popup) {
    updateCardData({
      cardType: CardType.SAVE,
      params: {
        save_card_product_code: productCode
      }
    })
  } else {
    // 当前支付方式非快捷支付方式，需要切换
    showQuickPaymentDrawer(productCode)
  }
}

const handleCouponListOpen = () => {
  updateShipPopupByKey({
    key: 'couponDrawerVisible',
    value: true
  })
}
</script>
